import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, BaseForm, FormContainer, TableFilterSection, SectionTitle, PageContainer, SpinLoading, TableButton } from '../../styles/BasicStyles';
import { Row, Col, Avatar, Breadcrumb, DatePicker, Checkbox, Icon, Tag, Popover, Tooltip, TimePicker, Popconfirm, Modal } from 'antd';
import { GetSubcategories, GetSubcategoriesByType } from '../../infra/requests/SubcategoryRequests';
import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import NoAvatar from '../../assets/no-avatar.png';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import { Tabs } from 'antd';
import { GetEmployee, UpdateEmployee, CreateEmployee, GetHostsEmployee } from '../../infra/requests/EmployeeRequests';
import { connect } from 'react-redux';
import moment from 'moment';
import ModalPDF from './ModalPDF';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { GetSettingsByType } from '../../infra/requests/SettingRequests'
import NumberInput from '../../components/inputs/NumberInput';
import EmployeePrice from './EmployeePrice';
import { withLocalize } from 'react-localize-redux';
import { CreatePrice } from '../../infra/requests/PriceRequests';
import { CreateEmployeePrice, DeleteEmployeePrice, UpdateEmployeePrice } from '../../infra/requests/EmployeePriceRequests';
import { CreateMultipleUnavailable, CreateUnavailable, GetEmployeeRest, GetUnavailable, RemoveMultipleUnavailable, RemoveUnavailable, UpdateUnavailable } from '../../infra/requests/UnavailableRequests';
import { CreateUser, DeleteUser, GetAttendingList, GetOrganizerList, GetPermissionAttendingList, UpdateUser } from '../../infra/requests/UsersRequests';
import Alert from "../../components/alert/Alert";
import { getUserId, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import Table from '../../components/table/Table';
import EmployeeProfileWeddings from './EmployeeProfileWeddings';
import EmployeePayments from './EmployeePayments';
import styled from 'styled-components';
import { WarningColor } from '../../styles/Colours';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import { isValidPhoneNumber } from 'react-phone-number-input';
import EmployeeProfileReserves from './EmployeeProfileReserves';
import { useSelector } from 'react-redux';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { InputDiv, InputLabelDiv } from '../../components/inputs/InputStyles';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import { getEmployeeUserLabel, getUserLabel } from '../../infra/services/wedding/weddingUtils';
import EmployeeProfileSchedules from './EmployeeProfileSchedules';
import MultipleRestDayModal from '../weddings/MultipleRestDayModal';
import Alerts from '../../components/alert/Alert';
import { checkDSTChanged, getDSTOffset, isDST } from '../schedules/scheduleService/utilScheduleService';
import { checkWeekdaysBetweenDates, checkWeekendsBetweenDates } from '../weddings/utilRestDay';
import { allDayOptions } from '../weddings/RestDays';

const { confirm } = Modal;

export const AtentionBar = styled.div`
position: relative;
width: 100%;
margin: 93px 0 0 0;
z-index: 49;
background-color: #ffffff;
`;
//top: ${({ small }) => small ? '138px' : '64px'};

export const AtentionBarContent = styled.div`
background-color: ${WarningColor};
color: #ffffff;
text-transform: inherit;
font-size: 16px;
text-align: center;
width: 100%;
padding: ${({ small }) => small ? '5px' : '10px'};
`;

export const EntityValues = {
    'SOLAR': 1,
    'LAGO': 2,
    'BOTH': 3
};

export const InvoicingTypeOptions = {
    'UNIQUE_ACT': 'UA',
    'OTHER': 'O'
};

export const PaymentMethodOptions = {
    'BANK_TRANSFER': 'BT',
    'MONETARY': 'M'
};

const RemoveRestMessage = (
    <div>
        <div>Tem a certeza que quer remover esse dia? </div>
        <div>
            Toda a informação será removida da plataforma e não conseguirá ser recuperada.
        </div>
    </div>
);

const regexZipCode = /^\d{4}-\d{3}?$/;
const regexEmail = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
const regexMobilePhoneNumber = /^ $|^2\d{8}$|^9(1|2|3|6)\d{7}$/;

const validations = values => {
    let errors = {};
    let validations = {};

    if (values?.user?.employeeTrainee) {
        validations = {
            name: 'required',
            email: 'required|email',
            notificationEmail: 'required|email',
            startDate: 'required|min:4|max:4',
            invoicingType: 'required',
            entity: 'required',
            paymentMethod: 'required',
        };
        if (values?.citizenNumber && values?.citizenNumber != '') validations['citizenNumber'] = 'min:7|max:8';
        if (values?.mechanicalNumberSolar && values?.mechanicalNumberSolar != '') validations['mechanicalNumberSolar'] = 'min:1';
        if (values?.mechanicalNumberLago && values?.mechanicalNumberLago != '') validations['mechanicalNumberLago'] = 'min:1';
        if (values?.zipCode && values?.zipCode != '') validations['zipCode'] = 'zipCode';
        if (values?.mobileNumber && values?.mobileNumber != '') validations['mobileNumber'] = 'mobilePhoneNumber';
        if (values?.emergencyNumber && values?.emergencyNumber != '') validations['emergencyNumber'] = 'mobilePhoneNumber';
        if (values?.nifInvoicing && values?.nifInvoicing != '') validations['nifInvoicing'] = 'min:9|max:9';
    } else {
        validations = {
            name: 'required',
            citizenNumber: 'required|min:7|max:8',
            address: 'required',
            zipCode: 'required|zipCode',
            locality: 'required',
            email: 'required|email',
            notificationEmail: 'required|email',
            mobileNumber: 'required',
            emergencyNumber: 'required',
            startDate: 'required|min:4|max:4',
            invoicingType: 'required',
            entity: 'required',
            nameInvoicing: 'required',
            nifInvoicing: 'required|min:9|max:9',
            iban: 'required',
            paymentMethod: 'required',
        };

        if (values?.mechanicalNumberSolar && values?.mechanicalNumberSolar != '') validations['mechanicalNumberSolar'] = 'min:1';
        if (values?.mechanicalNumberLago && values?.mechanicalNumberLago != '') validations['mechanicalNumberLago'] = 'min:1';

        if (values?.user?.employeeAttending) {
            validations['attendingType'] = 'required';
            validations['attendingLessTime'] = 'required|minNumber:0';
        }
    }
    errors = FormValidator.make(validations)(values);

    if (values.mobileNumber && !isValidPhoneNumber(values.mobileNumber)) {
        errors.mobileNumber = 'Número de telemóvel inválido';
    }

    if (values.emergencyNumber && !isValidPhoneNumber(values.emergencyNumber)) {
        errors.emergencyNumber = 'Número de emergência inválido';
    }

    if (values?.mechanicalNumberSolar && values?.mechanicalNumberSolar != ''
        && values?.mechanicalNumberLago && values?.mechanicalNumberLago != ''
        && values?.mechanicalNumberSolar == values?.mechanicalNumberLago) {
        errors.mechanicalNumberSolar = 'Nº mecanográficos iguais.'
        errors.mechanicalNumberLago = 'Nº mecanográficos iguais.'
    }

    return errors;
};

// main component
let EmployeePage = (props): React$Element<React$FragmentType> => {

    const [loading, setLoading] = useState(false);

    const employeeForm = useSelector(state => {
        return state.form.employee_form?.values
    });

    const [showProfileError, setShowProfileError] = useState(false);
    const [showInvoicingError, setShowInvoicingError] = useState(false);

    const [profileErrorContent, setProfileErrorContent] = useState([]);
    const [invoicingErrorContent, setInvoicingErrorContent] = useState([]);

    const [employee, setEmployee] = useState(null);
    const [favoriteSubcategories, setFavoriteSubcategories] = useState([]);
    const [selectedFavoriteSubcategory, setSelectedFavoriteSubcategory] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    //const [startDate, setStartDate] = useState(null);
    const [fixedEmployee, setFixedEmployee] = useState(false);
    const [chief, setChief] = useState(false);
    const [humanResources, setHumanResources] = useState(false);
    const [picture, setPicture] = useState('');
    const [pictureName, setPictureName] = useState('');
    const [insurance, setInsurance] = useState(false);
    const [insuranceValidity, setInsuranceValidity] = useState(null);
    const [insuranceFile, setInsuranceFile] = useState('');
    const [insuranceFileName, setInsuranceFileName] = useState('');
    const [showPreviewPDF, setShowPreviewPDF] = useState(false);
    const [entities, setEntities] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [invoicingTypes, setInvoicingTypes] = useState([]);
    const [selectedInvoicingType, setSelectedInvoicingType] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const [attendingTypes, setAttendingTypes] = useState([]);
    const [usersPermissions, setUsersPermissions] = useState([]);
    const [relationType, setRelationType] = useState(null);
    const [hostsEmployees, setHostsEmployees] = useState([]);
    const [showHostWarning, toogleHostWarning] = useState(false);
    const [organizerUsers, setOrganizerUsers] = useState([]);
    const [showOrganizerWarning, toogleOrganizerWarning] = useState(false);

    const [addEditPrice, setAddEditPrice] = useState([]);
    const [deletePrice, setDeletePrice] = useState([]);
    const [addSubcategoryPrice, setAddSubcategoryPrice] = useState([]);
    const [chiefSubcategory, setChiefSubcategory] = useState(null);

    const [loadingRest, setLoadingRest] = useState(false);
    const [unavailable, setUnavailable] = useState([]);
    const [startDateRest, setStartDateRest] = useState(null);
    const [endDateRest, setEndDateRest] = useState(null);
    const [allDayRest, toggleAllDayRest] = useState(null);
    const [pageSizeRest, setPageSizeRest] = useState(15);
    const [currentPageRest, setCurrentPageRest] = useState(1);
    const [totalRest, setTotalRest] = useState(0);
    const [showRestDayModal, toggleRestDayModal] = useState(false);
    const [submitRestDay, toggleSubmitRestDay] = useState(false);
    const [deleteRestDay, toggleDeleteRestDay] = useState(false);
    const [selectAllRest, toggleAllRest] = useState(false);
    const columnsRestDays = [
        {
            title: 'Data Inicial',
            key: 'startDate',
            // width: '130px',
            render: data => {
                return moment.utc(data.startDate).format('DD/MM/YYYY');
            }
        },
        {
            title: 'Data Final',
            key: 'endDate',
            // width: '200px',
            render: data => {
                return moment.utc(data.endDate).format('DD/MM/YYYY');
            }
        },
        {
            title: 'Todo o Dia',
            key: 'allDay',
            // width: '100px',
            render: data => (
                <Tag color={data.allDay === true ? 'green' : 'red'} key={data.allDay} >
                    {data.allDay === true ? 'Sim' : 'Não'}
                </Tag>
            )
        },
        {
            title: 'Hora de Início',
            // key: 'startTime',
            // width: '100px',
            render: data => (
                <div>{data.startTime ? moment.utc(data.startTime, 'HH:mm').local().format('HH:mm') : null}</div>
            )
        },
        {
            title: 'Hora de Fim',
            key: 'endTime',
            // width: '100px',
            render: data => (
                <div>{data.endTime ? moment.utc(data.endTime, 'HH:mm').local().format('HH:mm') : null}</div>
            )
        }
    ];
    const columnsAvailableDays = [
        {
            title: ' ',
            key: 'select',
            width: '30px',
            render: data => {
                return <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
                    <Checkbox
                        checked={data.checked}
                        onChange={(e) => {
                            let element = data;
                            element.checked = !element.checked;
                            let elements = [...unavailable];
                            let index = elements.findIndex(x => x?._id === element?._id);
                            if (index > -1) {
                                elements[index] = element;
                                setUnavailable(elements);
                            }
                        }}>
                    </Checkbox>
                </div>;
            }
        },
        {
            title: 'Data Inicial',
            key: 'startDate',
            // width: '130px',
            render: data => {
                return moment.utc(data.startDate).format('DD/MM/YYYY');
            }
        },
        {
            title: 'Data Final',
            key: 'endDate',
            // width: '200px',
            render: data => {
                return moment.utc(data.endDate).format('DD/MM/YYYY');
            }
        },
        {
            title: 'Todo o Dia',
            key: 'allDay',
            // width: '100px',
            render: data => (
                <Tag color={data.allDay === true ? 'green' : 'red'} key={data.allDay} >
                    {data.allDay === true ? 'Sim' : 'Não'}
                </Tag>
            )
        },
        {
            title: 'Hora de Início',
            // key: 'startTime',
            // width: '100px',
            render: data => (
                <div>{data.startTime ? moment.utc(data.startTime, 'HH:mm').local().format('HH:mm') : null}</div>
            )
        },
        {
            title: 'Hora de Fim',
            key: 'endTime',
            // width: '100px',
            render: data => (
                <div>{data.endTime ? moment.utc(data.endTime, 'HH:mm').local().format('HH:mm') : null}</div>
            )
        },
        {
            title: 'Acções',
            // width: '120px',
            render: data => {
                return employee?.user?.employeeAttending && employee?.availableRegisters
                    ? <TableButton onClick={e => e.stopPropagation()}>
                        <Popconfirm
                            placement="topRight"
                            title={RemoveRestMessage}
                            okText="Remover"
                            onConfirm={() => { deleteRest(data._id) }}>
                            <Icon type="delete" />
                            {' Remover'}
                        </Popconfirm>
                    </TableButton>
                    : null;
            }
        }
    ];

    const {
        dispatch,
        handleSubmit,
        history
    } = props;

    useEffect(() => {
        getChiefSubcategory();
        getEntities();
        getInvoicingTypes();
        getPaymentMethods();
        getCurrentEmployee();
    }, [])

    useEffect(() => {
        obterSubcategoriasPreferidas();
        if (employee?.user?.employeeAttending) {
            // if(employee?.attendingHost && employee?.attendingHost !== '') setRelationType('HOST');
            // if(employee?.attendingOrganizer && employee?.attendingOrganizer !== '') setRelationType('ORGANIZER');
            getAttendingTypes();
            getUsersForAttending();
            getHosts();
            // getOrganizers();
        }
        getEmployeeRest();
        validateForm();
    }, [employee])

    useEffect(() => {
        validateForm();
    }, [props.employeeForm])

    useEffect(() => {
        getProfileErrorContent();
    }, [profileErrorContent]);

    useEffect(() => {
        getInvoicingErrorContent();
    }, [invoicingErrorContent]);

    const getProfileErrorContent = () => {
        return profileErrorContent.map(ir => <p style={{ marginBottom: 0 }}>{ir}</p>)
    }

    const getInvoicingErrorContent = () => {
        return invoicingErrorContent.map(ir => <p style={{ marginBottom: 0 }}>{ir}</p>)
    }

    const validateForm = () => {
        let errors = {};
        let validations = {};

        // Employee Trainee
        if (employee?.user?.employeeTrainee) {
            validations = {
                name: 'required',
                email: 'required|email',
                notificationEmail: 'required|email',
                startDate: 'required|min:4|max:4',
                invoicingType: 'required',
                entity: 'required',
                paymentMethod: 'required',
            };
            if (props.employeeForm?.citizenNumber && props.employeeForm?.citizenNumber != '') validations['citizenNumber'] = 'min:7|max:8';
            if (props.employeeForm?.mechanicalNumberSolar && props.employeeForm?.mechanicalNumberSolar != '') validations['mechanicalNumberSolar'] = 'min:1';
            if (props.employeeForm?.mechanicalNumberLago && props.employeeForm?.mechanicalNumberLago != '') validations['mechanicalNumberLago'] = 'min:1';
            if (props.employeeForm?.zipCode && props.employeeForm?.zipCode != '') validations['zipCode'] = 'zipCode';
            if (props.employeeForm?.mobileNumber && props.employeeForm?.mobileNumber != '') validations['mobileNumber'] = 'mobilePhoneNumber';
            if (props.employeeForm?.emergencyNumber && props.employeeForm?.emergencyNumber != '') validations['emergencyNumber'] = 'mobilePhoneNumber';
            if (props.employeeForm?.nifInvoicing && props.employeeForm?.nifInvoicing != '') validations['nifInvoicing'] = 'min:9|max:9';
            // console.warn('errors trainee', errors);
        } else {
            validations = {
                name: 'required',
                citizenNumber: 'required|min:7|max:8',
                address: 'required',
                zipCode: 'required|zipCode',
                locality: 'required',
                email: 'required|email',
                notificationEmail: 'required|email',
                mobileNumber: 'required',
                emergencyNumber: 'required',
                startDate: 'required|min:4|max:4',
                invoicingType: 'required',
                entity: 'required',
                nameInvoicing: 'required',
                nifInvoicing: 'required|min:9|max:9',
                iban: 'required',
                paymentMethod: 'required',
            };

            if (props.employeeForm?.mechanicalNumberSolar && props.employeeForm?.mechanicalNumberSolar != '') validations['mechanicalNumberSolar'] = 'min:1';
            if (props.employeeForm?.mechanicalNumberLago && props.employeeForm?.mechanicalNumberLago != '') validations['mechanicalNumberLago'] = 'min:1';

            if (employee?.user?.employeeAttending) {
                validations['attendingType'] = 'required';
                // validations['attendingLessTime'] = 'required|minNumber:0';
            }
        }
        errors = FormValidator.make(validations)(props.employeeForm);

        if (props.employeeForm.mobileNumber && !isValidPhoneNumber(props.employeeForm.mobileNumber)) {
            errors.mobileNumber = 'Número de telemóvel inválido';
        }
    
        if (props.employeeForm.emergencyNumber && !isValidPhoneNumber(props.employeeForm.emergencyNumber)) {
            errors.emergencyNumber = 'Número de emergência inválido';
        }

        // Profile Form Errors
        setContentError(0, errors?.name ? true : false, 'Nome');
        setContentError(0, errors?.citizenNumber ? true : false, 'Cartão de Cidadão');
        setContentError(0, errors?.mechanicalNumberSolar ? true : false, 'Nº mecanográfico SLE');
        setContentError(0, errors?.mechanicalNumberLago ? true : false, 'Nº mecanográfico QLC');
        setContentError(0, errors?.address ? true : false, 'Morada');
        setContentError(0, errors?.zipCode ? true : false, 'Código-postal');
        setContentError(0, errors?.locality ? true : false, 'Localidade');
        setContentError(0, errors?.email ? true : false, 'E-mail');
        setContentError(0, errors?.notificationEmail ? true : false, 'E-mail para notificações');
        setContentError(0, errors?.mobileNumber ? true : false, 'Telemóvel');
        setContentError(0, errors?.emergencyNumber ? true : false, 'Contacto de Emergência');
        setContentError(0, errors?.startDate ? true : false, 'Ano de Entrada');
        if (employee?.user?.employeeAttending) {
            setContentError(0, errors?.attendingType ? true : false, 'Tipo de atendimento');
            // setContentError(0, errors?.attendingLessTime ? true : false, 'Tempo de cálculo');
        }

        let profileError = false;
        if (employee?.user?.employeeAttending) {
            profileError = errors?.name
                || errors?.citizenNumber
                || errors?.mechanicalNumberSolar
                || errors?.mechanicalNumberLago
                || errors?.address
                || errors?.zipCode
                || errors?.locality
                || errors?.email
                || errors?.notificationEmail
                || errors?.mobileNumber
                || errors?.emergencyNumber
                || errors?.startDate
                || errors?.attendingType
                // || errors?.attendingLessTime
                ? true : false;
        } else {
            profileError = errors?.name
                || errors?.citizenNumber
                || errors?.mechanicalNumberSolar
                || errors?.mechanicalNumberLago
                || errors?.address
                || errors?.zipCode
                || errors?.locality
                || errors?.email
                || errors?.notificationEmail
                || errors?.mobileNumber
                || errors?.emergencyNumber
                || errors?.startDate
                ? true : false;

        }
        setShowProfileError(profileError);

        // Invoicing Form Errors
        setContentError(1, errors?.invoicingType ? true : false, 'Tipo de Faturação');
        setContentError(1, errors?.entity ? true : false, 'Entidade a Faturar');
        setContentError(1, errors?.nameInvoicing ? true : false, 'Nome');
        setContentError(1, errors?.nifInvoicing ? true : false, 'NIF');
        setContentError(1, errors?.paymentMethod ? true : false, 'Método de Pagamento');

        // Check if it's to validate iban, case paymentMethod is bank transfer
        let checkIban = true;
        if (!errors?.paymentMethod && props?.employeeForm?.paymentMethod && props?.employeeForm?.paymentMethod != '') {
            const selectedPaymentType = paymentMethods?.find(f => f._id === props?.employeeForm?.paymentMethod);
            checkIban = selectedPaymentType?.paymentMethodOptions == PaymentMethodOptions.BANK_TRANSFER
                ? true
                : false;
        }
        const ibanError = checkIban
            && (!errors?.iban && (props?.employeeForm?.iban?.includes('PT50') && props?.employeeForm?.iban?.length !== 25
                || !props?.employeeForm?.iban?.includes('PT50') && ('PT50' + props?.employeeForm?.iban)?.length !== 25))
            ? true : false;
        setContentError(1, ibanError, 'IBAN');

        const invoicingError = errors?.invoicingType || errors?.entity || errors?.nameInvoicing || errors?.nifInvoicing
            || ibanError || errors?.paymentMethod
            ? true : false;
        setShowInvoicingError(invoicingError);
    }

    const setContentError = (type, add, text) => {
        if (type === 0) {
            if (add) {
                const index = profileErrorContent.findIndex(f => f === text);
                if (index === -1) {
                    profileErrorContent.push(text);
                    setProfileErrorContent([...profileErrorContent]);
                }
            } else {
                const index = profileErrorContent.findIndex(f => f === text);
                if (index > -1) {
                    profileErrorContent.splice(index, 1);
                    setProfileErrorContent([...profileErrorContent]);
                }
            }
        } else if (type === 1) {
            if (add) {
                const index = invoicingErrorContent.findIndex(f => f === text);
                if (index === -1) {
                    invoicingErrorContent.push(text);
                    setInvoicingErrorContent([...invoicingErrorContent]);
                }
            } else {
                const index = invoicingErrorContent.findIndex(f => f === text);
                if (index > -1) {
                    invoicingErrorContent.splice(index, 1);
                    setInvoicingErrorContent([...invoicingErrorContent]);
                }
            }
        }
    }

    const getChiefSubcategory = async () => {
        const resSubcategoryChief = await GetSubcategoriesByType('chief');
        setChiefSubcategory(resSubcategoryChief.success && resSubcategoryChief.data ? resSubcategoryChief.data : null);
    }

    const getCurrentEmployee = async () => {
        const employeeId = props.match.params.id;

        if (employeeId) {
            setLoading(true);
            const result = await GetEmployee(employeeId);

            if (result?.success && result.data) {
                if (!result.data.startDate) result.data.startDate = parseInt(moment().format('YYYY'));
                if (!result.data.iban) result.data.iban = 'PT50';

                setEmployee(result.data);
                setPicture(result.data.picture);
                setPictureName(result.data.pictureName);
                //setStartDate(moment(new Date(result.data.startDate)));
                setFixedEmployee(result.data.fixedEmployee);
                setChief(result.data.user.chief);
                setHumanResources(result.data.user.humanResources)
                setInsurance(result.data.insurance);
                setInsuranceValidity(moment(new Date(result.data.insuranceValidity)));
                setInsuranceFile(result.data.insuranceFile);
                setInsuranceFileName(result.data.insuranceFileName);
                if (result?.data?.entity) setSelectedEntity(result.data.entity.id);
                if (result?.data?.invoicingType) setSelectedInvoicingType(result?.data?.invoicingType?.id);
                if (result?.data?.paymentMethod) setSelectedPaymentMethod(result?.data?.paymentMethod.id);
                setSelectedLanguages(result.data.languages)
                setSelectedFavoriteSubcategory(result.data.favoriteSubcategories?.length > 0 ? result.data.favoriteSubcategories?.filter(x => x?.id)?.map(x => x?.id) : [])

                if (selectedEntity === '' || selectedInvoicingType === '' || selectedPaymentMethod === '') {
                    setShowInvoicingError(true);
                }

                // if (result?.data && result?.data?.user?.employeeAttending && (!result?.data?.attendingLessTime || result?.data?.attendingLessTime == '')) {
                //     result.data.attendingLessTime = 90;
                // }
                dispatch(
                    initialize('employee_form', {
                        ...result.data
                    })
                );
            } else {
                return history.push('/employees');
            }

            setLoading(false);
        } else {
            return history.push('/employees');
            setShowProfileError(true);
            setShowInvoicingError(true);
            setLoading(false);
        }
    }

    const obterSubcategoriasPreferidas = async () => {
        const result = await GetSubcategories();
        let subcategories = result?.success && Array.isArray(result.data)
            ? result.data
            : [];
        // If employee trainee remove chief and coordinator subcategories
        if (employee?.user?.employeeTrainee) {
            subcategories = subcategories.filter(f => !f?.chief && !f?.coordinator);
        }
        setFavoriteSubcategories(subcategories)
    }

    const getUsersForAttending = async () => {
        const result = await GetAttendingList();
        let users = result?.success ? result?.data : [];
        if (employee?.user?._id) {
            users = users.filter(f => f?._id !== employee?.user?._id);
        }
        setUsersPermissions(users);
    }

    const getHosts = async () => {
        const result = await GetHostsEmployee();
        let employees = result?.success ? result?.data : [];
        let existsHost = null;
        if (employee?.attendingHost && employee?.attendingHost !== '') {
            existsHost = employees.find(f => f?._id == employee?.attendingHost);
        }
        toogleHostWarning(employee?.attendingHost && employee?.attendingHost !== '' && !existsHost ? true : false);
        setHostsEmployees(employees);
    }

    // const getOrganizers = async () => {
    //     const result = await GetOrganizerList();
    //     let users = result?.success ? result?.data : [];
    //     let existsOrganizer = null;
    //     if (employee?.attendingOrganizer && employee?.attendingOrganizer !== '') {
    //         existsOrganizer = users.find(f => f?._id == employee?.attendingOrganizer);
    //     }
    //     toogleOrganizerWarning(employee?.attendingOrganizer && employee?.attendingOrganizer !== '' && !existsOrganizer ? true : false);
    //     setOrganizerUsers(users);
    // }

    const getEntities = async () => {
        const result = await GetSettingsByType(1);

        if (result.success) setEntities(result.data);
        else console.error(result.message);
    }

    const getAttendingTypes = async () => {
        const result = await GetAttendingTypes();
        let data = result?.success && result?.data ? result.data : [];
        if (Array.isArray(data) && data.length > 0) {
            data = data.map(m => ({ ...m, label: m?.name?.pt }))
        }

        if (result?.success) setAttendingTypes(data);
        else console.error(result?.message);
    }

    const getInvoicingTypes = async () => {
        const result = await GetSettingsByType(3);

        if (result.success) setInvoicingTypes(result.data);
        else console.error(result.message);
    }

    const getPaymentMethods = async () => {
        const result = await GetSettingsByType(2);

        if (result.success) {
            setPaymentMethods(result.data);
            const employeeId = props.match.params.id
            const paymentMethod = result.data.find(f => f.name === 'Transferência' || f.name === 'Transferencia')
            if (paymentMethod && !employeeId) setSelectedPaymentMethod(paymentMethod.id);
        } else console.error(result.message);
    }

    //Upload File
    const inputFileAvatar = useRef(null);
    const inputFileInsurance = useRef(null);

    const onUploadButtonClick = (isAvatar) => {
        isAvatar ? inputFileAvatar.current.click() : inputFileInsurance.current.click();
    };

    const onChangeFile = (isAvatar, e) => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];

        var reader = new FileReader();
        reader.fileName = file.name
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (isAvatar) {
                setPicture(reader.result);
                setPictureName(reader.fileName);
            } else {
                setInsuranceFile(reader.result);
                setInsuranceFileName(reader.fileName);
            }
        };

        e.target.value = '';
    }

    //Present Insurance File
    const onPreviewPDF = () => {
        setShowPreviewPDF(true);
    }

    const closeModal = () => {
        setShowPreviewPDF(false);
    }

    //Form
    const onSubmit = async (values) => {
        // console.warn('Val', values);

        if (insurance && insuranceValidity === null) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta a validade do seguro.'
            });
        }

        if (values.nameInvoicing === '' || values.nifInvoicing === '') {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Secção Faturação com dados em falta/incorretos'
            });
        }

        let entity, invoicingType, paymentMethod;
        // console.warn('Entity', selectedEntity);
        if (selectedEntity === '' || selectedEntity === null || selectedEntity === undefined ||
            selectedInvoicingType === '' || selectedInvoicingType == null || selectedInvoicingType === undefined
            || selectedPaymentMethod === '' || selectedPaymentMethod === null || selectedPaymentMethod === undefined) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta a Entidade e/ou tipo de faturação e/ou método de pagamento'
            });
        }

        entity = entities.find(f => f.id === selectedEntity);
        invoicingType = invoicingTypes.find(f => f.id === selectedInvoicingType);
        paymentMethod = paymentMethods.find(f => f.id === selectedPaymentMethod);


        if (employee && selectedPaymentMethod && paymentMethod !== undefined && paymentMethod.name === 'Transferência' || paymentMethod.name === 'Transferencia') {
            if (values.iban === '' || values.iban === null) {
                return Alert.new({
                    type: "error",
                    title: "Erro!",
                    text: 'Falta indicar o IBAN'
                });
            }

            if (!values.iban.includes('PT50')) values.iban = 'PT50' + values.iban;
            if (values.iban !== '' && !values.iban.includes('PT50') && values.iban.length !== 25) {
                return Alert.new({
                    type: "error",
                    title: "Erro!",
                    text: 'IBAN incorreto'
                });
            }
        }

        let checkChief = false;
        // console.warn('addEditPrice', addEditPrice);
        // console.warn('deletePrice', deletePrice);
        if (addEditPrice.length > 0) {
            const price = addEditPrice.find(f => f.subcategory !== null && f.subcategory?.chief);
            checkChief = price ? true : false;
        }

        if (deletePrice.length > 0) {
            const price = deletePrice.find(f => f.subcategory !== null && f.subcategory?.chief);
            checkChief = price ? false : true;
        }

        // console.warn('CheckChief', checkChief);

        let resultUser, resultEmployee;
        //console.warn('Edit Employee');
        const user = {
            name: values.name,
            email: values.email,
            notificationEmail: values.notificationEmail,
            admin: employee.user.admin,
            employee: employee.user.employee,
            chief: checkChief,
            humanResources: humanResources,
            organizer: employee.user.organizer,
            employeeAttending: employee.user.employeeAttending,
            employeeTrainee: employee.user.employeeTrainee,
        };

        resultUser = await UpdateUser(employee.user._id, user);
        if (resultUser.success) {
            const employeeValues = {
                id: employee.id,
                favoriteSubcategories: selectedFavoriteSubcategory,
                name: values.name,
                citizenNumber: values.citizenNumber,
                mechanicalNumberSolar: values?.mechanicalNumberSolar || null,
                mechanicalNumberLago: values?.mechanicalNumberLago || null,
                address: values.address,
                zipCode: values.zipCode,
                locality: values.locality,
                email: values.email,
                notificationEmail: values.notificationEmail,
                phoneNumber: null,
                emergencyNumber: values.emergencyNumber,
                mobileNumber: values.mobileNumber,
                startDate: values.startDate,
                fixedEmployee: fixedEmployee,
                insurance: insurance,
                insuranceValidity: insuranceValidity,
                insuranceFile: insuranceFile,
                insuranceFileName: insuranceFileName,
                insuranceDetail: values.insuranceDetail ? values.insuranceDetail : null,
                iban: values.iban ? values.iban.includes('PT50') ? values.iban : ('PT50' + values.iban) : null,
                updatedIban: employee.iban !== values.iban ? true : false,
                nameInvoicing: values.nameInvoicing ? values.nameInvoicing : null,
                nifInvoicing: values.nifInvoicing ? values.nifInvoicing : null,
                invoicingNotes: values.invoicingNotes ? values.invoicingNotes : null,
                picture: picture,
                pictureName: pictureName,
                entity: entity ? entity : selectedEntity,
                invoicingType: invoicingType ? invoicingType : selectedInvoicingType,
                paymentMethod: paymentMethod ? paymentMethod : selectedPaymentMethod,
                user: resultUser.data,
                active: employee.active,
                currentUser: getUserId(),
                languages: selectedLanguages
            };

            if (employee?.user?.employeeAttending) {
                employeeValues.attendingType = values?.attendingType;
                // employeeValues.attendingLessTime = values?.attendingLessTime;
                employeeValues.availableRegisters = values?.availableRegisters;
                employeeValues.onlyWeekends = values?.onlyWeekends;
                employeeValues.attendingPermissions = values?.attendingPermissions;
                employeeValues.attendingHost = values?.attendingHost;
                // employeeValues.attendingOrganizer = values?.attendingOrganizer;
            }

            resultEmployee = await UpdateEmployee(employeeValues);
            if (!resultEmployee.success) console.error(resultEmployee.message);
        }

        //Subcategory Price Management
        // if (addSubcategoryPrice.length > 0) {
        //     //console.warn('List', addSubcategoryPrice);
        //     for (let index = 0; index < addSubcategoryPrice.length; index++) {
        //         addSubcategoryPrice[index].employee = resultEmployee.data;
        //         const result = await CreatePrice(addSubcategoryPrice[index]);
        //         if (!result.success) console.error(result.message);
        //     }
        // }

        // console.warn('addEditPrice', addEditPrice);
        if (addEditPrice.length > 0) {

            let errorRows = false;
            addEditPrice.forEach(price => {
                if (price.subcategory === null || price.setting === null || price.value === null) {
                    errorRows = true;
                }
            });

            if (errorRows) {
                return Alert.new({
                    type: "error",
                    title: "Erro!",
                    text: 'Faltam dados na secção Subcategorias'
                });
            } else {
                for (let index = 0; index < addEditPrice.length; index++) {
                    const resultEPRice = addEditPrice[index].id.includes('id#FFFFF') ? await CreateEmployeePrice(addEditPrice[index]) : await UpdateEmployeePrice(addEditPrice[index]);
                    if (!resultEPRice.success) console.error(resultEPRice.message);
                }
            }

        }

        if (deletePrice.length > 0) {
            for (let index = 0; index < deletePrice.length; index++) {
                const resultEPrice = await DeleteEmployeePrice(deletePrice[index].id);
                if (!resultEPrice.success) console.error(resultEPrice.message);
            }
        }

        if (resultUser.success && resultEmployee.success) {
            Alert.new({
                type: "success",
                title: "Info!",
                text: 'Perfil editado com sucesso!'
            });
            history.push('/employees');
        }
    }

    const { TabPane } = Tabs;

    // Employee Rest
    useEffect(() => {
        setCurrentPageRest(1);
        getEmployeeRest();
    }, [allDayRest, endDateRest, startDateRest])

    useEffect(() => {
        getEmployeeRest();
    }, [currentPageRest, pageSizeRest]);

    const getEmployeeRest = async () => {
        setLoadingRest(true)

        if (employee != null && employee?.user?._id) {
            const filter = {};
            if (startDateRest) filter['startDate'] = moment.utc(startDateRest).toISOString();
            if (endDateRest) filter['endDate'] = moment.utc(endDateRest).toISOString();
            if (allDayRest !== null && allDayRest !== '') filter['allDay'] = allDayRest;
            filter['employee'] = employee?.user?._id;

            const result = await GetUnavailable(currentPageRest, pageSizeRest, JSON.stringify(filter));
            let rowsData = result.data && result.data.items && result.data.items.length > 0 ? result.data.items : [];

            for (let index = 0; index < rowsData.length; index++) {
                rowsData[index].checked = false;
                if (rowsData[index].startTime) rowsData[index].startTime = moment(rowsData[index].startTime, 'HH:mm');
                if (rowsData[index].endTime) rowsData[index].endTime = moment(rowsData[index].endTime, 'HH:mm');
            }

            setUnavailable(rowsData);
            setTotalRest(result.data && result.data.total ? result.data.total : 0);
        }

        setLoadingRest(false)
    }

    const handleChangePage = cp => {
        setCurrentPageRest(cp);
    }

    const handleChangeRowsPerPage = (currentSize, pageSize) => {
        setCurrentPageRest(1);
        setPageSizeRest(pageSize);
    }

    const deleteRest = async id => {
        if (!(employee?.user?.employeeAttending && employee?.availableRegisters)) {
            return;
        }

        const result = await RemoveUnavailable(id);
        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Data eliminada!'
            });
            getEmployeeRest();
        }
    }

    const saveMultipleRestDays = async (values) => {
        if (!(employee?.user?.employeeAttending && employee?.availableRegisters)) {
            return;
        }

        toggleSubmitRestDay(true);
        setLoadingRest(true);

        const data = {
            ...values,
            employee: employee?._id
        };
        const startDate = moment.utc(data.startDate).startOf('day');
        const endDate = moment.utc(data.endDate).endOf('day');
        // const diffDays = endDate.diff(startDate, 'days');

        // if (diffDays <= 1) {
        //     toggleSubmitRestDay(false);
        //     setLoadingRest(false);
        //     return Alerts.new({
        //         type: 'warning',
        //         title: 'Atenção',
        //         text: 'Deve selecionar um intervalo de datas!'
        //     });
        // }

        if (employee?.availableRegisters && employee?.onlyWeekends) {
            const selectedWeekends = checkWeekendsBetweenDates(startDate, endDate);
            if (!selectedWeekends) {
                toggleSubmitRestDay(false);
                setLoadingRest(false);
                return Alert.new({
                    type: "warning",
                    title: "Atenção!",
                    text: 'Deve selecionar um intervalo que inclua fins-de-semana.'
                });
            }
        }

        // Correct time if not allDay
        if (!data?.allDay) {
            const isDSTDifferentForStartTime = checkDSTChanged(startDate, endDate, data?.startTime);
            if (isDSTDifferentForStartTime) {
                if (isDST(moment.utc(data.startTime)) && moment.utc(data.startTime).isValid()) {
                    const standardOffset = getDSTOffset() * 60; // <- Este é o número mágico de minutos numa hora, porque todos os dias, uma hora tem 60 minutos
                    data.startTime = moment.utc(data.startTime).add(standardOffset, 'minutes');
                }
            }

            const isDSTDifferentForEndTime = checkDSTChanged(startDate, endDate, data?.endTime);
            if (isDSTDifferentForEndTime) {
                if (isDST(moment.utc(data.endTime)) && moment.utc(data.endTime).isValid()) {
                    const standardOffset = getDSTOffset() * 60; // <- Este é o número mágico de minutos numa hora, porque todos os dias, uma hora tem 60 minutos
                    data.endTime = moment.utc(data.endTime).add(standardOffset, 'minutes');
                }
            }
        }

        data.startDate = startDate.toISOString();
        data.endDate = endDate.endOf('day').toISOString();
        if (moment.utc(data.startTime).isValid()) data.startTime = moment.utc(data.startTime).format('HH:mm');
        if (moment.utc(data.endTime).isValid()) data.endTime = moment.utc(data.endTime).format('HH:mm');

        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        data['timezone'] = timezoneName;

        const result = await CreateMultipleUnavailable(data);

        if (result?.success) {
            const typeSuccess = employee?.availableRegisters ? 'Disponibilidade(s)' : 'Indisponibilidade(s)';
            const message = result?.data?.errors && result?.data?.errors?.length > 0
                ? `${result?.data?.errors?.length} data(s) não foram possíveis criar.`
                : `${typeSuccess} criada(s) com sucesso.`;

            Alerts.new({
                type: result?.data?.errors && result?.data?.errors?.length > 0 ? 'warning' : 'success',
                title: result?.data?.errors && result?.data?.errors?.length > 0 ? 'Atenção' : 'Sucesso',
                text: message
            });

            toggleSubmitRestDay(false);
            toggleRestDayModal(false);
            getEmployeeRest();
        } else {
            toggleSubmitRestDay(false);
            setLoadingRest(false);
        }
    }

    const confirmRemoveMultipleRestDays = () => {
        if (!(employee?.user?.employeeAttending && employee?.availableRegisters)) {
            return;
        }

        confirm({
            title: 'Têm a certeza que pretende eliminar a(s) data(s) selecionada(s)?',
            content: 'A informação será eliminada e não poderá ser recuperada.',
            onOk: () => { deleteMultipleRestDays() },
            onCancel() { return; },
        });
    }

    const deleteMultipleRestDays = async () => {
        if (!(employee?.user?.employeeAttending && employee?.availableRegisters)) {
            return;
        }

        const selectedRest = unavailable.filter(f => f?.checked);
        if (selectedRest?.length == 0) {
            return Alert.new({
                type: "warning",
                title: "Atenção",
                text: "Selecione uma data."
            });
        }

        toggleDeleteRestDay(true);
        setLoadingRest(true);

        const result = await RemoveMultipleUnavailable(employee?._id, { rows: selectedRest });

        if (result?.success) {
            const typeSuccess = employee?.availableRegisters ? 'Disponibilidade(s)' : 'Indisponibilidade(s)';
            const message = result?.data?.errors && result?.data?.errors?.length > 0
                ? `${result?.data?.errors?.length} data(s) não foram possíveis eliminar.`
                : `${typeSuccess} eliminada(s).`;

            Alerts.new({
                type: result?.data?.errors && result?.data?.errors?.length > 0 ? 'warning' : 'success',
                title: result?.data?.errors && result?.data?.errors?.length > 0 ? 'Atenção' : 'Sucesso',
                text: message
            });

            toggleDeleteRestDay(false);
            toggleAllRest(false);
            getEmployeeRest();
        } else {
            toggleDeleteRestDay(false);
            setLoadingRest(false);
        }
    }

    const activateEmployee = async () => {
        //console.warn('Employee', employee);
        if (employee && (!employee.startDate || employee.startDate === '' || employee.startDate === null)) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta o Ano de Entrada na secção Perfil'
            });
        }

        // if (employee?.user?.employeeAttending && (!props.employeeForm?.attendingType || props.employeeForm?.attendingType === ''
        //     || props.employeeForm?.attendingType == null || !props.employeeForm?.attendingLessTime || props.employeeForm?.attendingLessTime == ''
        //     || props.employeeForm?.attendingLessTime == null || props.employeeForm?.attendingLessTime < 0)) {
        if (employee?.user?.employeeAttending && (!props.employeeForm?.attendingType || props.employeeForm?.attendingType === ''
            || props.employeeForm?.attendingType == null)) {
            const missingAttendingType = !props.employeeForm?.attendingType || props.employeeForm?.attendingType === ''
                || props.employeeForm?.attendingType == null ? true : false;
            // const missingAttendingLessTime = !props.employeeForm?.attendingLessTime || props.employeeForm?.attendingLessTime == ''
            //     || props.employeeForm?.attendingLessTime == null || props.employeeForm?.attendingLessTime < 0 ? true : false;

            let fields = 'campos de atendimento';
            // if (missingAttendingType && missingAttendingLessTime) fields = 'tipo de atendimento e tempo de cálculo';
            if (missingAttendingType) fields = 'tipo de atendimento';
            // if (missingAttendingLessTime) fields = 'tempo de cálculo';
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: `Falta ${fields} na secção Perfil`
            });
        }

        if (employee && selectedEntity === null) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta a Entidade na secção Faturação'
            });
        }

        if (insurance && insuranceValidity === null) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta a validade do seguro.'
            });
        }

        if (props.employeeForm.nameInvoicing === '' || props.employeeForm.nifInvoicing === '') {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Secção Faturação com dados em falta/incorretos'
            });
        }

        let entity, invoicingType, paymentMethod;
        if (selectedEntity === '' || selectedEntity === null || selectedEntity === undefined ||
            selectedInvoicingType === '' || selectedInvoicingType == null || selectedInvoicingType === undefined
            || selectedPaymentMethod === '' || selectedPaymentMethod === null || selectedPaymentMethod === undefined) {
            return Alert.new({
                type: "error",
                title: "Erro!",
                text: 'Falta a Entidade e/ou tipo de faturação e/ou método de pagamento'
            });
        }

        entity = entities.find(f => f.id === selectedEntity);
        invoicingType = invoicingTypes.find(f => f.id === selectedInvoicingType);
        paymentMethod = paymentMethods.find(f => f.id === selectedPaymentMethod);


        // if (employee && selectedPaymentMethod && paymentMethod !== undefined && paymentMethod.name === 'Transferência' || paymentMethod.name === 'Transferencia') {
        if (employee && selectedPaymentMethod && paymentMethod !== undefined
            && paymentMethod?.paymentMethodOptions == PaymentMethodOptions.BANK_TRANSFER) {
            if (props.employeeForm.iban === '' || props.employeeForm.iban === null) {
                return Alert.new({
                    type: "error",
                    title: "Erro!",
                    text: 'Falta indicar o IBAN'
                });
            }

            if (props.employeeForm.iban !== '' && !props.employeeForm.iban.includes('PT') && props.employeeForm.iban.length !== 25) {
                return Alert.new({
                    type: "error",
                    title: "Erro!",
                    text: 'IBAN incorreto'
                });
            }
        }

        let checkChief = false;
        if (addEditPrice.length > 0) {
            const price = addEditPrice.find(f => f.subcategory !== null && f.subcategory?.chief);
            checkChief = price ? true : false;
        }

        if (deletePrice.length > 0) {
            const price = deletePrice.find(f => f.subcategory !== null && f.subcategory?.chief);
            checkChief = price ? false : true;
        }

        //console.warn('CheckChief', checkChief);

        let resultUser, resultEmployee;
        //console.warn('Edit Employee');
        const user = {
            name: props.employeeForm.name,
            email: props.employeeForm.email,
            notificationEmail: props.employeeForm.notificationEmail,
            admin: employee.user.admin,
            employee: employee.user.employee,
            chief: checkChief,
            humanResources: humanResources,
            organizer: employee.user.organizer
        };

        resultUser = await UpdateUser(employee.user._id, user);
        if (!resultUser.success) console.error(resultUser.message);
        else {
            const employeeValues = {
                id: employee.id,
                favoriteSubcategories: selectedFavoriteSubcategory,
                name: props.employeeForm.name,
                citizenNumber: props.employeeForm.citizenNumber,
                address: props.employeeForm.address,
                zipCode: props.employeeForm.zipCode,
                locality: props.employeeForm.locality,
                email: props.employeeForm.email,
                notificationEmail: props.employeeForm.notificationEmail,
                phoneNumber: null,
                emergencyNumber: props.employeeForm.emergencyNumber,
                mobileNumber: props.employeeForm.mobileNumber,
                startDate: props.employeeForm.startDate,
                fixedEmployee: fixedEmployee,
                insurance: insurance,
                insuranceValidity: insuranceValidity,
                insuranceFile: insuranceFile,
                insuranceFileName: insuranceFileName,
                insuranceDetail: props.employeeForm.insuranceDetail ? props.employeeForm.insuranceDetail : null,
                iban: props.employeeForm.iban ? props.employeeForm.iban.includes('PT50') ? props.employeeForm.iban : ('PT50' + props.employeeForm.iban) : null,
                updatedIban: employee.iban !== props.employeeForm.iban ? true : false,
                nameInvoicing: props.employeeForm.nameInvoicing ? props.employeeForm.nameInvoicing : null,
                nifInvoicing: props.employeeForm.nifInvoicing ? props.employeeForm.nifInvoicing : null,
                invoicingNotes: props.employeeForm.invoicingNotes ? props.employeeForm.invoicingNotes : null,
                picture: picture,
                pictureName: pictureName,
                entity: entity ? entity : selectedEntity,
                invoicingType: invoicingType ? invoicingType : selectedInvoicingType,
                paymentMethod: paymentMethod ? paymentMethod : selectedPaymentMethod,
                user: resultUser.data,
                active: true,
                currentUser: getUserId(),
                languages: selectedLanguages
            };

            resultEmployee = await UpdateEmployee(employeeValues);
            if (resultEmployee.success) {
                Alert.new({
                    type: "success",
                    title: "Info!",
                    text: 'Funcionário Ativo'
                });
                //setEmployee(employee);
                //console.warn('Emp', employee);
                //getCurrentEmployee();
                history.push('/employees');
            } else console.error(resultEmployee.message);
        }
    }

    useEffect(() => {
        getEmployeeRest();
    }, [endDateRest, startDateRest])

    const tabKey = props.match.params.tabKey ? props.match.params.tabKey : 1;
    const activeTabKey = tabKey.toString(); let employeeId = props.match.params.id;

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={3}>
                    <PageTitle>Perfil Funcionário</PageTitle>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a href="/employees">Funcionários</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Perfil
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderTitle>
                <HeaderButtonsContainer buttons={3}>
                    {employeeId && <BaseButton
                        type="primary"
                        icon="check"
                        text={employee && employee?.active ? 'Ativo' : 'Ativar'}
                        disabled={employee && employee?.active || loading}
                        onClick={() => activateEmployee()}
                    />}
                    <BaseButton
                        type="primary"
                        icon="save"
                        text="Gravar"
                        disabled={loading}
                        onClick={handleSubmit((values) => onSubmit(values))}
                    />
                    <BaseButton
                        type="default"
                        icon="close"
                        text="Cancelar"
                        onClick={() => history.push('/employees')}
                    />
                </HeaderButtonsContainer>
            </HeaderContainer>

            <AtentionBar small={false} className='employee-atention-bar'>
                <AtentionBarContent small={false}>
                    ATENÇÃO: Deve preencher os campos obrigatórios de todos os separadores
                </AtentionBarContent>
            </AtentionBar>

            {loading ? <SpinLoading />
                : <FormContainer style={{ marginTop: '1rem' }}>
                    <BaseForm onSubmit={handleSubmit(onSubmit)}>
                        <Tabs style={{ textAlign: 'start' }} defaultActiveKey={activeTabKey}>
                            <TabPane tab="Funcionário" key="1" style={{ position: 'relative' }}>
                                <Tabs tabPosition="left">
                                    <TabPane tab={
                                        showProfileError ?
                                            <Popover content={getProfileErrorContent()} title={<b style={{ color: 'red' }}>Erro</b>}>
                                                <span style={{ color: 'red' }}>
                                                    <Icon type="user" />
                                                    <span className='tab-name'>Perfil</span>
                                                </span>
                                            </Popover> :
                                            <span>
                                                <Icon type="user" />
                                                <span className='tab-name'>Perfil</span>
                                            </span>
                                    } key="11">
                                        <div style={{ width: '100%', margin: 'auto' }}>
                                            <SectionTitle>Perfil</SectionTitle>
                                            <Row>
                                                <Col style={{ textAlign: 'center' }}>
                                                    <Avatar size={96} onClick={e => onUploadButtonClick(true)} name="picture" alt="" style={{ cursor: 'pointer' }} src={picture == '' ? NoAvatar : picture} />
                                                    {picture !== '' ? <p onClick={() => { setPicture('') }} style={{ userSelect: 'none', cursor: 'pointer', marginTop: 5, marginBottom: -10 }}>Remover Imagem de Perfil</p> : null}
                                                    <input type='file' id='file' ref={inputFileAvatar} style={{ display: 'none' }} accept="image/*" onChange={e => onChangeFile(true, e)} />
                                                </Col>
                                            </Row>
                                            <Row gutter={12}>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="name"
                                                        label="Nome Completo *"
                                                        placeholder="Insira o nome"
                                                        type="text"
                                                        requiredError={profileErrorContent.find(f => f === 'Nome')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="citizenNumber"
                                                        label={`Cartão de Cidadão ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="ex: 11111111"
                                                        type="number"
                                                        maxLength={8}
                                                        requiredError={profileErrorContent.find(f => f === 'Cartão de Cidadão')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="mechanicalNumberSolar"
                                                        label={`Nº mecanográfico SLE`}
                                                        type="number"
                                                    // requiredError={profileErrorContent.find(f => f === 'Nº mecanográfico SLE')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="mechanicalNumberLago"
                                                        label={`Nº mecanográfico QLC`}
                                                        type="number"
                                                    // requiredError={profileErrorContent.find(f => f === 'Nº mecanográfico QLC')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="address"
                                                        label={`Morada Completa ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="Insira a morada"
                                                        type="text"
                                                        requiredError={profileErrorContent.find(f => f === 'Morada')}
                                                    />
                                                </Col>
                                                <Col sm={6} md={5}>
                                                    <Field
                                                        component={TextInput}
                                                        name="zipCode"
                                                        label={`Código-Postal ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="ex: 0000-000"
                                                        type="text"
                                                        maxLength={8}
                                                        requiredError={profileErrorContent.find(f => f === 'Código-postal')}
                                                    />
                                                </Col>
                                                <Col sm={6} md={7}>
                                                    <Field
                                                        component={TextInput}
                                                        name="locality"
                                                        label={`Localidade ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="Insira a localidada"
                                                        type="text"
                                                        requiredError={profileErrorContent.find(f => f === 'Localidade')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="email"
                                                        inputMode="email"
                                                        label="E-mail *"
                                                        placeholder="Insira o e-mail"
                                                        type="email"
                                                        requiredError={profileErrorContent.find(f => f === 'Email')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="notificationEmail"
                                                        inputMode="email"
                                                        label="E-mail para notificações *"
                                                        placeholder="Insira o e-mail"
                                                        type="email"
                                                        requiredError={profileErrorContent.find(f => f === 'E-mail para notificações')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="mobileNumber"
                                                        label={`Telemóvel ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="ex: 911111111"
                                                        type="tel"
                                                        requiredError={profileErrorContent.find(f => f === 'Telemóvel')}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={TextInput}
                                                        name="emergencyNumber"
                                                        label={`Contacto de Emergência ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="ex: 911111111"
                                                        type="tel"
                                                        requiredError={profileErrorContent.find(f => f === 'Contacto de Emergência')}
                                                    />
                                                </Col>
                                                {!employee?.user?.employeeAttending && <Col sm={12} md={12}>
                                                    <Field
                                                        component={SelectInput}
                                                        mode="multiple"
                                                        allowClear
                                                        name="favoriteSubcategories"
                                                        data={favoriteSubcategories}
                                                        required
                                                        input={{
                                                            value: selectedFavoriteSubcategory, onChange: event => setSelectedFavoriteSubcategory(event)
                                                        }}
                                                        label="Posições de Preferência (seleção múltipla)"
                                                        placeholder="Seleciona subcategorias"
                                                    />
                                                </Col>}
                                                <Col sm={12} md={12}>
                                                    <Field
                                                        component={SelectInput}
                                                        mode="multiple"
                                                        allowClear
                                                        name="languages"
                                                        data={[
                                                            { name: 'Português', _id: 'portuguese' },
                                                            { name: 'Espanhol', _id: 'spanish' },
                                                            { name: 'Inglês', _id: 'english' },
                                                            { name: 'Francês', _id: 'french' },
                                                            { name: 'Alemão', _id: 'deutch' }
                                                        ]}
                                                        required
                                                        input={{
                                                            value: selectedLanguages, onChange: event => setSelectedLanguages(event)
                                                        }}
                                                        label="Idioma (seleção múltipla)"
                                                        placeholder="Selecione os idiomas"
                                                    />
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <div className="sc-eHgmQL kduSWC">
                                                        <Field
                                                            component={TextInput}
                                                            name="startDate"
                                                            label="Ano de Entrada *"
                                                            placeholder="Insira o ano de entrada"
                                                            type="number"
                                                            maxLength={4}
                                                            requiredError={profileErrorContent.find(f => f === 'Ano de Entrada')}
                                                        />
                                                    </div>
                                                </Col>
                                                {employee?.user?.employeeAttending && <Col sm={12} md={12}>
                                                    <Field
                                                        component={SelectInput}
                                                        allowClear
                                                        mode="multiple"
                                                        name="attendingType"
                                                        data={attendingTypes}
                                                        dataLabel={'label'}
                                                        label="Tipo de atendimento *"
                                                        placeholder="Seleciona o tipo"
                                                    />
                                                </Col>}
                                                {/* {employee?.user?.employeeAttending && <Col sm={12} md={12}>
                                                    <Field
                                                        component={NumberInput}
                                                        allowClear
                                                        name="attendingLessTime"
                                                        label="Tempo de cálculo (minutos) *"
                                                        placeholder="Indique a duração"
                                                    />
                                                </Col>} */}
                                                {employee?.user?.employeeAttending && <Col sm={12} md={12}>
                                                    <Field
                                                        component={SelectInput}
                                                        mode="multiple"
                                                        allowClear
                                                        name="attendingPermissions"
                                                        data={usersPermissions}
                                                        label="Aceder aos atendimentos de:"
                                                        placeholder="Seleciona os CA"
                                                    />
                                                </Col>}
                                                {/* {employee?.user?.employeeAttending && <Col sm={12} md={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <SelectInput
                                                        label="Relação com:"
                                                        allowClear
                                                        placeholder="Escolher opção"
                                                        data={[
                                                            {_id: 'HOST', name: 'Hospedeiro/a'},
                                                            {_id: 'ORGANIZER', name: 'Organizador(a)'},
                                                        ]}
                                                        input={{
                                                            value: relationType,
                                                            onChange: value => setRelationType(value)
                                                        }}
                                                        meta={{ valid: true }}
                                                    />
                                                </Col>} */}
                                                {/* {employee?.user?.employeeAttending && relationType == 'HOST' && <Col sm={12} md={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
                                                {employee?.user?.employeeAttending && <Col sm={12} md={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Field
                                                        component={SelectInput}
                                                        allowClear
                                                        name="attendingHost"
                                                        data={hostsEmployees}
                                                        label="Hospedeira/o"
                                                        placeholder="Seleciona o colaborador"
                                                    />
                                                    {showHostWarning && <Tooltip title="Deixou de ser hospedeira/o">
                                                        <Icon type="warning" style={{ margin: 'auto 0 5px 5px', fontSize: 20, color: '#f5222d' }} />
                                                    </Tooltip>}
                                                </Col>}
                                                {/* {employee?.user?.employeeAttending && relationType == 'ORGANIZER' && <Col sm={12} md={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Field
                                                        component={SelectInput}
                                                        allowClear
                                                        name="attendingOrganizer"
                                                        data={organizerUsers}
                                                        label="Organizador(a)"
                                                        placeholder="Seleciona o organizador"
                                                    />
                                                    {showOrganizerWarning && <Tooltip title="Deixou de ser organizador(a)">
                                                        <Icon type="warning" style={{ margin: 'auto 0 5px 5px', fontSize: 20, color: '#f5222d' }} />
                                                    </Tooltip>}
                                                </Col>} */}
                                                {employee?.user?.employeeAttending && <Col sm={6} md={4}>
                                                    <Field
                                                        label="Registo de disponibilidades"
                                                        positiveLabel='Sim'
                                                        component={CheckboxInput}
                                                        name={'availableRegisters'}
                                                    />
                                                </Col>}
                                                {employee?.user?.employeeAttending && employeeForm?.availableRegisters && <Col sm={6} md={4}>
                                                    <Field
                                                        label="Bloquear dias da semana"
                                                        positiveLabel='Sim'
                                                        component={CheckboxInput}
                                                        name={'onlyWeekends'}
                                                    />
                                                </Col>}
                                                {!employee?.user?.employeeTrainee && <Col sm={6} md={4}>
                                                    <InputDiv>
                                                        <InputLabelDiv>Funcionário Fixo</InputLabelDiv>
                                                        <Checkbox
                                                            style={{ marginTop: 5 }}
                                                            checked={fixedEmployee}
                                                            onChange={(e) => { setFixedEmployee(e.target.checked) }}>
                                                            Sim
                                                        </Checkbox>
                                                    </InputDiv>
                                                </Col>}
                                                {!employee?.user?.employeeTrainee && !employee?.user?.employeeAttending
                                                    && <Col sm={6} md={6}>
                                                        <InputDiv>
                                                            <InputLabelDiv>Recursos Humanos</InputLabelDiv>
                                                            <Checkbox
                                                                style={{ marginTop: 5 }}
                                                                checked={humanResources}
                                                                onChange={(e) => { setChief(false); setHumanResources(e.target.checked) }}>
                                                                Sim
                                                            </Checkbox>
                                                        </InputDiv>
                                                    </Col>}
                                            </Row>
                                        </div>
                                    </TabPane>

                                    <TabPane tab={
                                        <span>
                                            <Icon type="safety" />
                                            <span className='tab-name'>Seguro</span>
                                        </span>
                                    } key="12">
                                        <div style={{ width: '100%', margin: 'auto' }}>
                                            <SectionTitle>Seguro</SectionTitle>
                                            <Row style={{ display: 'inline-flex', width: '100%' }}>
                                                <div className="sc-hMqMXs liDmfg" style={{ width: '160px' }}>Funcionário tem seguro?</div>
                                                <Checkbox style={{ marginTop: 5 }} checked={insurance} onChange={(e) => { setInsurance(e.target.checked) }}>Sim</Checkbox>
                                            </Row>
                                            <Row gutter={12}>
                                                <Col sm={12} md={12}>
                                                    <div className="sc-eHgmQL kduSWC">
                                                        <div className="sc-hMqMXs liDmfg">Data de Validade</div>
                                                        <DatePicker value={insuranceValidity} placeholder='Selecione Data' disabled={!insurance} onChange={(e) => { setInsuranceValidity(e) }} style={{ width: '100%' }} />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <input type='file' id='fileInsurance' ref={inputFileInsurance} accept="application/pdf" style={{ display: 'none' }} onChange={e => onChangeFile(false, e)} />

                                                    <div className="sc-eHgmQL kduSWC">
                                                        <div className="sc-hMqMXs liDmfg">Documento do Seguro</div>
                                                        <div style={{ height: 32, padding: '4px 11px', color: 'rgba(0,0,0, 0.65)', backgroundColor: '#fff', border: '1px solid #d9d9d9', borderRadius: '4px', transition: 'all 0.3s', position: 'relative' }}>
                                                            {insuranceFile === '' ? <Icon onClick={e => onUploadButtonClick(false)} style={{ position: 'absolute', right: 11, top: 8 }} type={'upload'} /> :
                                                                (<div style={{ position: 'relative', paddingRight: 14 }}>
                                                                    <span style={{ display: 'block', overflowWrap: 'break-word' }} onClick={e => onPreviewPDF()}>{insuranceFileName}</span>
                                                                    <Icon type={'close-circle'} style={{ position: 'absolute', right: 0, top: 4 }} onClick={e => setInsuranceFile('')} />
                                                                </div>)}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={24} md={24}>
                                                    <Field
                                                        component={TextAreaInput}
                                                        name="insuranceDetail"
                                                        label={'Detalhes do Seguro'}
                                                        disabled={!insurance}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </TabPane>

                                    <TabPane tab={
                                        showInvoicingError ?
                                            <Popover content={getInvoicingErrorContent()} title={<b style={{ color: 'red' }}>Erro</b>}>
                                                <span style={{ color: 'red' }}>
                                                    <Icon type="user" />
                                                    <span className='tab-name'>Faturação</span>
                                                </span>
                                            </Popover> :
                                            <span>
                                                <Icon type="euro" />
                                                <span className='tab-name'>Faturação</span>
                                            </span>
                                    } key="13">
                                        <div style={{ width: '100%', margin: 'auto' }}>
                                            <SectionTitle>Faturação</SectionTitle>
                                            <Row gutter={12}>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={SelectInput}
                                                        name="invoicingType"
                                                        //allowClear
                                                        label="Tipo de Faturação *"
                                                        placeholder="Selecione o tipo"
                                                        data={invoicingTypes}
                                                        input={{
                                                            value: selectedInvoicingType,
                                                            onChange: event => {
                                                                if (event && event !== '') {
                                                                    const selectedInvType = invoicingTypes.find(f => f._id === event);
                                                                    if (selectedInvType?.invoicingTypeOption == InvoicingTypeOptions.UNIQUE_ACT) {
                                                                        setEntities(entities.filter(f => f?.entityValue !== EntityValues.BOTH));
                                                                    }
                                                                }
                                                                setSelectedInvoicingType(event);
                                                                props.change('invoicingType', event);
                                                            }
                                                        }}
                                                        requiredError={invoicingErrorContent.find(f => f === 'Tipo de Faturação')}
                                                    />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={SelectInput}
                                                        name="entity"
                                                        allowClear
                                                        label="Entidade a Faturar *"
                                                        placeholder="Selecione a entidade"
                                                        data={entities}
                                                        input={{
                                                            value: selectedEntity,
                                                            onChange: event => {
                                                                setSelectedEntity(event);
                                                                props.change('entity', event);
                                                            }
                                                        }}
                                                        requiredError={invoicingErrorContent.find(f => f === 'Entidade a Faturar')}
                                                    />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={TextInput}
                                                        name="nameInvoicing"
                                                        label={`Nome Completo ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="Insira o nome"
                                                        type="text"
                                                        requiredError={invoicingErrorContent.find(f => f === 'Nome')}
                                                    />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={TextInput}
                                                        name="nifInvoicing"
                                                        label={`NIF ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="ex: 111111111"
                                                        type="number"
                                                        maxLength={9}
                                                        requiredError={invoicingErrorContent.find(f => f === 'NIF')}
                                                    />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={SelectInput}
                                                        name="paymentMethod"
                                                        allowClear
                                                        label="Método de Pagamento *"
                                                        placeholder="Selecione o método"
                                                        data={paymentMethods}
                                                        input={{
                                                            value: selectedPaymentMethod,
                                                            onChange: event => {
                                                                setSelectedPaymentMethod(event)
                                                                props.change('paymentMethod', event);
                                                            }
                                                        }}
                                                        requiredError={invoicingErrorContent.find(f => f === 'Método de Pagamento')}
                                                    />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={TextInput}
                                                        name="iban"
                                                        label={`IBAN ${employee?.user?.employeeTrainee ? '' : '*'}`}
                                                        placeholder="ex: PT50111111111111111111111"
                                                        type="text"
                                                        requiredError={invoicingErrorContent.find(f => f === 'IBAN')}
                                                        maxLength={25}
                                                    // {...ibanMask}
                                                    />
                                                    {/* <Field
                                                    component={NumberInput}
                                                    min={0}
                                                    onInput="validity.valid || (value = '')"
                                                    name="paymentValue"
                                                    label="Valor a Receber"
                                                    placeholder="Insira o valor"
                                                    type="number"
                                                    span="0.01"
                                                /> */}
                                                </Col>
                                                <Col lg={24} md={24} sm={24} xs={24}>
                                                    <Field
                                                        component={TextAreaInput}
                                                        name="invoicingNotes"
                                                        label={'Detalhes da Faturação'}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </TabPane>

                                    {/* If employee attending don't show subcategories tab */}
                                    {!employee?.user?.employeeAttending &&
                                        <TabPane tab={
                                            <span>
                                                <Icon type="tags" />
                                                <span className='tab-name'>Subcategorias</span>
                                            </span>
                                        } key="14">
                                            <EmployeePrice
                                                employee={employee}
                                                hasInsurance={insurance}
                                                favoriteSubcategory={selectedFavoriteSubcategory}
                                                addEditPrice={e => setAddEditPrice(e)}
                                                deletePrice={e => setDeletePrice(e)}
                                                addSubcategoryPrice={e => setAddSubcategoryPrice(e)}
                                            />
                                        </TabPane>
                                    }
                                </Tabs>
                            </TabPane>
                            <TabPane tab={employee?.user?.employeeAttending ? 'Atendimentos' : 'Casamentos'} key="2">
                                {employee != null
                                    ? employee?.user?.employeeAttending
                                        ? <EmployeeProfileSchedules employee={employee} />
                                        : <EmployeeProfileWeddings employee={employee} />
                                    : null
                                }
                            </TabPane>
                            {!employee?.user?.employeeTrainee
                                && <TabPane tab={employee?.user?.employeeAttending && employee?.availableRegisters ? 'Dias Disponíveis' : 'Dias Indisponíveis'} key="3">
                                    <TableFilterSection>
                                        <Row gutter={12}>
                                            <Col xs={24} sm={12} md={6} lg={4}>
                                                <label>Data Inicial</label>
                                                <DatePicker value={startDateRest} format={'DD/MM/YYYY'} placeholder='Selecione Data' onChange={(e) => { setStartDateRest(e) }} style={{ width: '100%', marginTop: 5 }} />
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={4}>
                                                <label>Data Final</label>
                                                <DatePicker value={endDateRest} format={'DD/MM/YYYY'} placeholder='Selecione Data' onChange={(e) => { setEndDateRest(e) }} style={{ width: '100%', marginTop: 5 }} />
                                            </Col>
                                            <Col xs={24} sm={4} md={3} lg={2}>
                                                <SelectInput
                                                    label='Todo o dia'
                                                    allowClear={true}
                                                    first={true}
                                                    // placeholder="Escolher opção"
                                                    data={allDayOptions}
                                                    input={{
                                                        value: allDayRest,
                                                        onChange: value => toggleAllDayRest(value)
                                                    }}
                                                    meta={{}}
                                                />
                                            </Col>
                                            {employee?.user?.employeeAttending && employee?.availableRegisters &&
                                                <Col xs={24} sm={12} md={6} lg={4}>
                                                    <BaseButton
                                                        type="primary"
                                                        icon="plus"
                                                        text={"Adicionar"}
                                                        disabled={loadingRest}
                                                        loading={submitRestDay}
                                                        style={{ marginTop: 26 }}
                                                        onClick={() => toggleRestDayModal(true)}
                                                    />
                                                    <BaseButton
                                                        type="danger"
                                                        icon="delete"
                                                        text={"Remover"}
                                                        disabled={loadingRest}
                                                        loading={deleteRestDay}
                                                        style={{ marginTop: 26, marginLeft: 8 }}
                                                        onClick={() => confirmRemoveMultipleRestDays()}
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                    </TableFilterSection>

                                    {employee?.user?.employeeAttending && employee?.availableRegisters
                                        && <div className='payment-table-container'>
                                            <div className="sc-dxgOiQ fWHHkk" style={{ position: 'absolute', top: 6, left: 8, zIndex: 49 }}>
                                                <Checkbox
                                                    checked={selectAllRest}
                                                    onChange={(e) => {
                                                        unavailable.forEach(r => { r.checked = e.target.checked; });
                                                        toggleAllRest(e.target.checked);
                                                        setUnavailable(unavailable);
                                                    }}>
                                                </Checkbox>
                                            </div>
                                        </div>}
                                    <Table
                                        columns={employee?.user?.employeeAttending && employee?.availableRegisters ? columnsAvailableDays : columnsRestDays}
                                        currentPage={currentPageRest}
                                        pageSize={pageSizeRest}
                                        loading={loadingRest}
                                        rows={unavailable}
                                        showHeader={true}
                                        emptyIcon="calendar"
                                        emptyText={employee?.user?.employeeAttending && employee?.availableRegisters ? 'Não existem disponibilidades' : 'Não existem indisponibilidades'}
                                        total={totalRest}
                                        rowKey={'_id'}
                                        hasPagination={true}
                                        hasPageSize={true}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TabPane>}
                            <TabPane tab="Pagamentos" key="4">
                                <EmployeePayments />
                            </TabPane>
                        </Tabs>
                    </BaseForm>
                </FormContainer>}

            {showPreviewPDF
                && <ModalPDF
                    openModal={showPreviewPDF}
                    closeModal={closeModal}
                    title='Documento do Seguro'
                    file={insuranceFile}
                />}

            {showRestDayModal
                && <MultipleRestDayModal
                    isOpen={showRestDayModal}
                    availableRegisters={employee?.user?.employeeAttending && employee?.availableRegisters ? true : false}
                    onClose={() => toggleRestDayModal(false)}
                    onSubmit={saveMultipleRestDays} />}

        </React.Fragment >
    );
};

const selector = formValueSelector('employee_form');

EmployeePage = reduxForm({
    form: 'employee_form',
    validate: validations,
})(EmployeePage);

const mapStateToProps = state => ({
    employeeForm: {
        name: selector(state, 'name'),
        citizenNumber: selector(state, 'citizenNumber'),
        address: selector(state, 'address'),
        zipCode: selector(state, 'zipCode'),
        locality: selector(state, 'locality'),
        email: selector(state, 'email'),
        notificationEmail: selector(state, 'notificationEmail'),
        mobileNumber: selector(state, 'mobileNumber'),
        favoriteSubcategories: selector(state, 'favoriteSubcategories'),
        emergencyNumber: selector(state, 'emergencyNumber'),
        languages: selector(state, 'languages'),
        startDate: selector(state, 'startDate'),
        insuranceDetail: selector(state, 'insuranceDetail'),
        invoicingType: selector(state, 'invoicingType'),
        nameInvoicing: selector(state, 'nameInvoicing'),
        entity: selector(state, 'entity'),
        nifInvoicing: selector(state, 'nifInvoicing'),
        iban: selector(state, 'iban'),
        paymentMethod: selector(state, 'paymentMethod'),
        paymentValue: selector(state, 'paymentValue'),
        invoicingNotes: selector(state, 'invoicingNotes'),
        attendingType: selector(state, 'attendingType'),
        // attendingLessTime: selector(state, 'attendingLessTime'),
        attendingPermissions: selector(state, 'attendingPermissions'),
        availableRegisters: selector(state, 'availableRegisters'),
        onlyWeekends: selector(state, 'onlyWeekends'),
    }
});

export default withLocalize(connect(mapStateToProps)(EmployeePage));