import styled, { createGlobalStyle } from 'styled-components';
import { Spin, Form as AntForm } from 'antd';
import { StyledButton } from '../components/buttons/ButtonsStyles';

export const BasicStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Abel&display=swap');
  
  @import url('https://www.unpkg.com/@fortawesome/fontawesome-free@5.14.0/css/all.min.css');
  
  @import 'react-big-calendar/lib/css/react-big-calendar.css';

  @import 'cropperjs/dist/cropper.css';

  body {
    font-family: 'Abel',  'sans-serif' !important;
  }

  .ant-layout {
    background: #fff !important;
  }

  html {
    scroll-behavior: smooth;
  }

  .anticon.ant-notification-notice-icon-success {
    color: green !important;
  }

  .infoAlert {
    &&& {
      background-color: #e6f7ff;
      border: 1px solid #91d5ff;
    }
  }

  .warningAlert {
    &&& {
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
    }
  }

  .errorTimeAlert {
    &&& {
      background-color: #f8bbd0;
      border: 1px solid #ce7191;
    }
  }

  .errorAlert {
    &&& {
      background-color: #fff1f0;
      border: 1px solid #ffa39e;
    }
  }

  .successAlert {
    &&& {
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
    }
  }

  .ant-menu-submenu-popup {
    z-index: 99999999;
  }

  .ant-menu-submenu i.anticon.anticon-team {
    opacity: 60%;
  }

  .ant-menu-submenu i.anticon.anticon-calendar {
    opacity: 60%;
  }

  .pfpImage {
    transition: .2s;
  }

  .pfpImage:hover {
    transform: scale(1.6);
  }

  .fc-daygrid-day-number {
    color: #3f3f3f !important;
  }

  .fc-col-header-cell-cushion {
    color: #646664;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${({ theme }) => theme.secondaryColor} !important;
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: ${({ theme }) => theme.secondaryColor} !important;
  }

  .fc .fc-highlight {
    background-color: ${({ theme }) => theme.secondaryColor} !important;
  }

  .fc-button {
    background-color: ${({ theme }) => theme.primaryColor} !important;
    border: 1px solid ${({ theme }) => theme.primaryColor} !important;
  }

  .fc-toolbar-title {
    color: ${({ theme }) => theme.primaryColor} !important;
  }

  .hideTime > * > * > .fc-event-time {
    display : none;
  }

  .adminWeddings {
    width: 100%;
  }

  .adminWeddings .header {
    padding: 8px 8px;
    color: #000;
    background-color: #DFE0DF;
    border-bottom: 1px solid #e8e8e8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-width: 768px;
    width: auto;
  }

  .adminWeddings .header .ant-col {
    border-right: 1px solid #e8e8e8;
  }

  .adminWeddings .body {
    min-width: 768px;
    width: auto;
   }

  .adminWeddings .body .ant-col {
    text-align: left;
    border-right: 1px solid #e8e8e8;
  }

  .adminWeddings .body ul {
    list-style: none;
    text-align: left;
    margin-block-start: 5px;
    margin-block-end: 5px;
    padding-inline-start: 0;
  }

  .adminWeddings .body ul + .adminWeddings .body ul {
    border-left: 1px solid #e8e8e8;
  }

  .adminWeddings .body ul li {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    padding: 0 8px;
  }

  .adminWeddings .body ul li + .adminWeddings .body ul li {
    border-top: 1px solid #e8e8e8;
  }

  .btn-payment-container {
    position: absolute;
    top: 33px;
    right: 0;
    z-index: 49;
    display: inline-flex;
  }

  .checkbox-simple-payment {
    position: absolute;
    top: 10px;
    left: 8px;
    z-index: 49;
  }

  .checkbox-payment {
    position: absolute;
    top: 20px;
    left: 8px;
    z-index: 49;
  }

  .checkbox-payment-detail {
    position: absolute;
    top: 12px;
    left: 8px;
    z-index: 49;
  }

  .kduSWC .kduSWC {
    margin-top: 5px !important;
  }

  .jbburb {
    display: inline-block;
    font-size: 16px;
    width: 100%;
    margin-top: 0;
  }

  .ant-layout.ant-layout-has-sider {
    height: 100vh;
    overflow: inherit;
  }

  .employee-home-btn-container {
    width: 100%;
    height: auto;
    min-height: 32px;
    margin-bottom: 15px;
    position: relative;
  }

  .ant-row-col, .ant-row-margin {
    width: calc(100% + 10px)!important;
    margin-right: -5px !important;
    margin-left: -5px !important;
  }

  .ant-row-margin .ant-btn {
    padding: 0 !important;
  }

  .ant-row-margin .jbburb {
    white-space: nowrap;
  }

  .ant-table-body {
    min-width: 900px;
    width: auto;
  }

  .table-responsive {
    overflow: auto;
  }

  .table-responsive .ant-table .ant-table-content .ant-table-body {
    min-width: unset !important;
    width: auto;
  }

  .payment-table-container .ant-table {
    min-width: 900px;
    width: auto;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .reserve-price-month {
    padding: 10px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 4px;
    margin-bottom: 3px;
    color: white;
  }

  .reserve-edit {
    border: 1px solid ${p => p?.theme?.primaryColor};
    border-radius: 4px;
    padding: 0px 10px 10px 10px;
  }

  .editor {
    border: 1px solid ${p => p?.theme?.primaryColor};
    border-radius: 4px;
    padding: 0 10px;
  }

  .editor-error .tox-tinymce {
    border: 1px solid red;
  }

  .ant-collapse-extra {
    display: flex;
  }

  .ant-collapse-extra span {
    margin: 0 5px;
  }

  .tooltipMenu .ant-tooltip-content .ant-tooltip-inner {
    padding: 2px;
  }

  .ant-tooltip-inner {
    background-color: rgb(0 0 0 / 80%);
  }

  .ant-popover {
    z-index: 99999999999999 !important;
  }

  .warningTooltip {
    z-index: 9;
  }

  .warningTooltip .ant-tooltip-inner {
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    color: black;
  }
  
  .warningTooltip .ant-tooltip-arrow::before {
    background-color: #ffa39e;
  }

  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 30px !important;
    line-height: 30px !important;
  }

  .fc-v-event {
    border-width: 3px !important;
    padding: 2px;
  }

  .fc-timegrid-event-harness {
    margin: 1px;
  }

  .ant-radio-button-wrapper-checked {
    color: #fff !important;
    background: ${p => p?.theme?.primaryColor} !important;
  }
  
  .upgradeConfirm .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
    font-weight: 600 !important;
  }

  .printedConfirm {
    .ant-modal-content {
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
    }
    
    .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
      font-weight: 600 !important;
    }
  }

  .checkbox-waiting-policy {
    display: flex !important;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }

  .policy-modal .ant-modal-body {
    padding: 0 !important;
  }

  .ant-back-top {
    right: 20px !important;
    bottom: 20px !important;
  }

  .ant-back-top-content {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  .editor-modal-wrap {
    z-index: 1005 !important;
  }

  .svg-input {
    display: inline-flex;
    align-items: center;
  }

  .svg-icon, .svg-icon div {
    display: flex;
    align-items: center;
    width: min-content;
  }

  .change-guests-modal .ant-modal-confirm-content {
    margin-left: 0 !important;
  }

  .service-modal {
    margin-top: 10px;
  }

  .service-modal .ant-collapse-header {
    padding: 6px 10px !important;
    padding-left: 40px !important;
  }

  .service-modal .ant-collapse-content-box {
    padding: 5px !important;
  }

  * {
    scrollbar-width: thin;
    scrollbar-height: thin;
    scrollbar-color: #8f8e8e #e5e5e5;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e5e5e5; 
  }

  ::-webkit-scrollbar-thumb {
    background: #8f8e8e; 
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #8f8e8e; 
  }
`;
// .ant-popover-inner-content {
//   padding: 0px !important;
// }
//left: 85px;

// .ant-select-dropdown, .ant-calendar-picker-container {
//   z-index: 1005 !important;
// }

// .ant-layout.ant-layout-has-sider {
//   height: 100vh;
//   overflow: auto;
// }


export const HeaderContainer = styled.div`
  width: calc(100% - 80px);
  display: flex;
  position: fixed;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  z-index: 100;
  border-bottom: 2px solid ${({ theme }) => theme.secondaryBackgroundColor};
  align-items: center;

  @media screen and (max-width: 768px) {
    width: calc(100% - 40px);
    display: grid;
  }
`;

export const HeaderTitle = styled.div`
  width: calc(
    100% - ${({ buttons }) => (buttons ? buttons + ' * 130px' : '0px')}
  );
  display: inline-block;
  text-align: left;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const HeaderButtonsContainer = styled.div`
  width: calc(${({ buttons }) => (buttons ? buttons + ' * 170px' : '0px')});
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-content: center;

  ${StyledButton} {
    margin-left: 10px;
    float: right;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    
    ${StyledButton} {
      margin: 3px;
      float: right;
    }
  }
  
  @media screen and (max-width: 375px) {
    .reserve-header-icon {
      display: none;
    }
  }

`;

export const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 0px;
`;

export const PageSubtite = styled.h2`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 10px;
`;

export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.sectionSize};
  color: ${({ theme }) => theme.thirdColor};
  margin-bottom: 10px;
  text-align: left;
  margin-top: ${({ subsection }) => (subsection ? '50px' : 0)};

  ${p => p?.withButton && `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;

export const PageContainer = styled.div`
  width: 100%;
  margin-top: ${({ subpage, printed }) => printed ? 125 : subpage ? 0 : 86}px;
  position: relative;
  display: inline-block;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};

  .reserve-column {
    position: fixed;
    overflow: auto;
    overflow-x: hidden;
    right: 0;
  }
    
  @media (max-width: 991px) {
    .reserve-column {
      position: relative;
      right: unset;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: ${({ buttons }) => (buttons > 0 ? '125px' : '86px')};
  }
`;

export const TableButton = styled.div`
  display: inline-block;
  z-index: 9;
  padding: 5px 3px;
  margin: 0 3px;

  svg {
    width: ${p => p?.theme.iconSvgSize};
    height:  ${p => p?.theme.iconSvgSize};
  }

  &:hover {
    color: ${({ theme, primary, error }) => error ? theme.inputErrorColor : theme.primaryColor};

    i {
      color: ${({ theme, primary, error }) => error ? theme.inputErrorColor : theme.primaryColor};
    }

    svg, svg path {
      color: ${({ theme, primary, error }) => error ? theme.inputErrorColor : theme.primaryColor};
      fill: ${({ theme, primary, error }) => error ? theme.inputErrorColor : theme.primaryColor};
      fill-opacity: 1;
    }
  }
`;

export const ErrorText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.inputErrorColor};
`;

export const SpinLoading = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const SpinLoadingFile = styled(Spin)`
  display: flex;
  width: 100%;
  height: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const SpinLoadingCalendar = styled(Spin)`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 70%;
  left: 50%;
  z-index: 999 !important;
`;

export const FormContainer = styled.div`
  width: calc(100% - 20px);
  max-width: ${({ singleColumn }) => (singleColumn ? '700px' : '100%')};
  margin-top: 105px;
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${p => p.theme.primaryBackgroundColor};

  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
`;

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const TableFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

export const TableImage = styled.div`
  display: inline-block;
  width: 200px;
`;

export const DefaultLanguageTab = styled.div`
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 12px 16px;
  margin-top: -2px;
  margin-bottom: 16px;
`;

export const DefaultSectionOption = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.inputFocusColor};
  border-radius: 4px;
  font-size: 28px;
  color: ${({ theme }) => theme.inputFocusColor};
  font-weight: bold;
  padding: 50px 15px;
  flex-direction: column;
  opacity: ${p => p?.disabled ? 0.5 : 1};
  cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};

  svg {
    height: 50px;
    path {
      fill: ${({ theme }) => theme.inputFocusColor};
    }
  }

  img {
    height: 50px;
  }

  span {
    display: block;
    margin-top: 10px;
    color: ${({ theme }) => theme.inputFocusColor};
    font-size: 18px;
    height: 36px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    box-shadow: 2px 2px 6px #ccc;
  }
`;

export const DashboardContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 20px;
  margin-top: 105px;
`;

export const TableNote = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  width: 100%;
  text-align: left;

  & span {
    font-weight: bold;
  }
`;

export const Separator = styled.hr`
  margin: 20px 0px;
`;

export const DeleteModalText = styled.div`
  font-size: 16px;
  padding: 5px 0px;
`;