import React, { Component, Fragment } from 'react';
import { Icon, Popconfirm, Tag } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton
} from '../../styles/BasicStyles';
import { GetWeddingUsers, ResendEmail } from '../../infra/requests/UsersRequests';
import UsersFilters from './UsersFilters';
import Alerts from '../../components/alert/Alert';
import { PrimaryColour } from '../../styles/Colours';

class WeddingUsersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Tipo de Utilizador',
        key: 'userType',
        render: data => {
          const tagColor = data?.reserveUser
            ? '#2db7f5'
            : data?.alternativeUser
              ? '#87d068'
              : PrimaryColour;
          const tagText = data?.reserveUser
            ? 'Reserva'
            : data?.alternativeUser
              ? 'Convidado'
              : 'Confirmado';
          return <Tag color={tagColor}>{tagText}</Tag>;
        }
      },
      {
        title: 'Definiu Password',
        dataIndex: 'accepted',
        render: value => (value ? 'Sim' : 'Não')
      },
      {
        title: 'Acções',
        render: data => (
          <Fragment>
            {!data.accepted && (
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title="Quer reenviar o email de acesso a este utilizador?"
                  onConfirm={() => this.resendEmail(data)}>
                  <Icon type="inbox" />
                  {' Reenviar email'}
                </Popconfirm>
              </TableButton>
            )}
          </Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetWeddingUsers(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  resendEmail = async data => {
    const result = await ResendEmail({ email: data.email });
    if (result.success) {
      Alerts.new({
          type: "success",
          title: "Sucesso",
          text: 'Email re-enviado!'
      });
      await this.updateDataTable();
  } else {
      return Alerts.new({
          type: "error",
          title: "Erro!",
          text: 'Falha no envio do email!'
      });
  }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Utilizadores</PageTitle>
            Lista de Utilizadores
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <UsersFilters loading={loading} queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="team"
            emptyText={'There are not users'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default WeddingUsersPage;
