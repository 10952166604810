import SelectInput from '../../components/inputs/SelectInput';
import TextInput from '../../components/inputs/TextInput';
import PhoneInput from 'react-phone-number-input/input';
import { getCountryOptions, getCountryTranslation } from '../../pages/translations/Countries';

export const brideFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome *',
    placeholder: 'Nome completo',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "fullName",
    type: "text",
    label: "Nome completo",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput,
    disabled: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "personalEmail",
    type: "text",
    label: "Endereço de email pessoal",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone * (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "personalPhone",
    type: "text",
    label: "Contacto de telefone pessoal",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'address',
    type: 'text',
    label: 'Morada',
    placeholder: 'Morada completa',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'profession',
    type: 'text',
    label: 'Profissão',
    placeholder: 'Profissão',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    contract: true,
    name: "identification",
    type: "text",
    label: "Número do cartão do cidadão",
    component: TextInput,
    disabled: false,
    asterisc: false,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "identificationNumber",
    type: "text",
    label: "Número de identificação fiscal - NIF",
    component: TextInput,
    disabled: false,
    asterisc: false,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "streetDoorNumber",
    type: "text",
    label: "Rua e número da porta - de residência",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 12
  },
  {
    name: "zipCode",
    type: "text",
    label: "Código Postal - de residência",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 6,
    xl: 6
  },
  {
    name: "street",
    type: "text",
    label: "Freguesia e Concelho - de residência",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 6,
    xl: 6
  },
  {
    name: "nationality",
    type: "text",
    label: "Nacionalidade",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "country",
    type: "text",
    label: "País de residência",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  }
];

export const groomFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome *',
    placeholder: 'Nome completo',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "fullName",
    type: "text",
    label: "Nome completo",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput,
    disabled: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "personalEmail",
    type: "text",
    label: "Endereço de email pessoal",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone * (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "personalPhone",
    type: "text",
    label: "Contacto de telefone pessoal",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'address',
    type: 'text',
    label: 'Morada',
    placeholder: 'Morada completa',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: 'profession',
    type: 'text',
    label: 'Profissão',
    placeholder: 'Profissão',
    component: TextInput,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    contract: true,
    name: "identification",
    type: "text",
    label: "Número do cartão do cidadão",
    component: TextInput,
    disabled: false,
    asterisc: false,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "identificationNumber",
    type: "text",
    label: "Número de identificação fiscal - NIF",
    component: TextInput,
    disabled: false,
    asterisc: false,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "streetDoorNumber",
    type: "text",
    label: "Rua e número da porta - de residência",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 12
  },
  {
    name: "zipCode",
    type: "text",
    label: "Código Postal - de residência",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 6,
    xl: 6
  },
  {
    name: "street",
    type: "text",
    label: "Freguesia e Concelho - de residência",
    component: TextInput,
    disabled: false,
    asterisc: true,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 6,
    xl: 6
  },
  {
    name: "nationality",
    type: "text",
    label: "Nacionalidade",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  },
  {
    name: "country",
    type: "text",
    label: "País de residência",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
  }
];

export const contactFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome',
    placeholder: 'Nome completo',
    component: TextInput,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput,
    disabled: false,
  },
  {
    name: 'address',
    type: 'text',
    label: 'Morada',
    placeholder: 'Morada completa',
    component: TextInput
  }
];

export const guestFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome',
    placeholder: 'Nome completo',
    component: TextInput,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Contacto de email',
    component: TextInput,
    disabled: true
  },
  {
    name: 'contact',
    type: 'tel',
    label: 'Contacto de telefone (ex: +351911111111)',
    placeholder: '+351911111111',
    component: TextInput,
    disabled: false,
  },
];