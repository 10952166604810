export const PrimaryColour = '#9AA89A';
export const LightPrimary = '#9AA89A';
export const WarmGrey = '#8d8d8d';
export const LightGrey = '#bbb';

export const WarningColor = '#FFDEB8';
export const SuccessColor = '#50bd3d';
export const ErrorColor = '#db7b80';
export const InfoColor = '#92a0a7';

export const GoldColor = '#BC9222';

export const GraphColors = {
    paid: PrimaryColour,
    missing: '#ECE8E2',
    base: PrimaryColour,
    roomPlan: '#C3CEC0',
    ceremony: '#9AC1C6',
    staff: '#DCCDA8',
    food: '#DADADA',
    drink: '#BCA9A9',
    decor: '#D2B2D7',
    party: '#FACCCC',
    upgrade: '#ADAFD2',
    expense: '#D99B9B',
}