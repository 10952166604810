import React, { Component } from 'react';
import Logo from '../../assets/icons/logo_white.svg';
import { bindActionCreators } from 'redux';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { Layout, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  StyledMenu,
  StyledMenuItem,
  StyledLogo,
  StyledNavLink,
  StyledSider,
  StyledContent,
  TransitionLayout,
  MenuSVG,
  LogoutLink,
  StyledSliderHeader
} from '../../components/layout/LayoutStyles';
import HeaderComponent from './HeaderComponent';
import ProcessItems from './ProcessItems';
import { SpinLoading } from '../../styles/BasicStyles';
import { getWeddingInfo } from '../../infra/requests/WeddingProcessRequests';
import { AddWedding, ClearWedding } from '../../redux/wedding/wedding.actions';
import BackIcon from '../../assets/menu/ic_goback.svg';
import { WeddingStatus } from '../../weddingday/reserves/ReserveAgenda';

class ProcessLayout extends Component {
  state = {
    collapsed: true,
    mounted: false
  };

  componentDidMount = () => {
    const {
      wedding,
      match: { params }
    } = this.props;
    if (!wedding?.wedding || wedding?.wedding?._id !== params.wedding) {
      this.getWedding();
    } else {
      this.setState({ mounted: true });
    }
  };

  getWedding = async () => {
    const {
      match: { params },
      history,
      AddWedding
    } = this.props;

    const { data, success } = await getWeddingInfo(params.wedding);
    if (success) {
      AddWedding(data);
      this.setState({ mounted: true });
    } else history.push('/weddings');
  };

  handleSider = () => this.setState({ collapsed: !this.state.collapsed });
  handleSiderClose = () => this.setState({ collapsed: true });

  goBack = () => {
    const { dispatch, ClearWedding } = this.props;
    const { history, location, match: { params }, wedding } = this.props;
    // const weddingId = params.wedding;
    const fromReserveAgenda = new URLSearchParams(location.search).get('page') === 'RA' ? true : false;
    const fromReservePriceAgenda = new URLSearchParams(location.search).get('page') === 'RP' ? true : false;
    const fromReserve = new URLSearchParams(location.search).get('page') === 'R' ? true : false;
    const fromWaitingDateList = new URLSearchParams(location.search).get('page') === 'WD' ? true : false;
    const fromInterestDateList = new URLSearchParams(location.search).get('page') === 'ID' ? true : false;
    const fromVisitSchedules = new URLSearchParams(location.search).get('page') === 'VS' ? true : false;
    const fromMeetingSchedules = new URLSearchParams(location.search).get('page') === 'MS' ? true : false;
    const fromWeddingsPayments = new URLSearchParams(location.search).get('page') === 'WPAY' ? true : false;

    ClearWedding();

    if (fromReservePriceAgenda) return history.push('/reserves-prices');
    else if (fromReserveAgenda || fromReserve) return history.push('/reserves');
    else if (fromWaitingDateList) return history.push('/waiting-dates');
    else if (fromInterestDateList) return history.push('/interest-dates');
    else if (fromVisitSchedules) return history.push('/visits');
    else if (fromMeetingSchedules) return history.push('/meetings');
    else if (fromWeddingsPayments) return history.push('/weddings-payments');
    else return history.push('/weddings');
  };

  navigateTo = (navigateTo) => {
    if (navigateTo == '/unanswered') {
      const elem = document.getElementById('unanswered');
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      this.props.history.push(navigateTo);
    }
  };

  renderMenuList() {
    const {
      match: { params },
      location
    } = this.props;
    const hasReturnPage = new URLSearchParams(location.search).get('page') !== undefined
      && new URLSearchParams(location.search).get('page') !== null ? true : false;
    const returnPage = new URLSearchParams(location.search).get('page');

    return (
      <StyledMenu>
        {ProcessItems(params.wedding).map((item, index) => (
          <StyledMenuItem key={index} disabled={item.disabled}>
            <StyledNavLink
              to={hasReturnPage ? `${item.to}?page=${returnPage}` : item.to}
              exact={item.exact}
              disabled={item.disabled}
              activeClassName="activated">
              {item.external_icon ? (
                <MenuSVG className="anticon" size={item.size}>
                  <ReactSVG src={item.icon} />
                </MenuSVG>
              ) : (
                <Icon type={item.icon} />
              )}
              <span>{item.name}</span>
            </StyledNavLink>
          </StyledMenuItem>
        ))}
        <StyledMenuItem>
          <LogoutLink onClick={this.goBack}>
            <MenuSVG className="anticon" size={20}>
              <ReactSVG src={BackIcon} />
            </MenuSVG>
            <span>Voltar</span>
          </LogoutLink>
        </StyledMenuItem>
      </StyledMenu>
    );
  }

  render() {
    const { collapsed, mounted } = this.state;
    if (!mounted) return <SpinLoading />;
    return (
      <Layout>
        <StyledSider
          collapsible
          collapsed={collapsed}
          onCollapse={this.handleSider}>
          <StyledSliderHeader collapsed={collapsed}>
            <StyledLogo src={Logo} onClick={this.handleSider} />
          </StyledSliderHeader>
          {this.renderMenuList()}
        </StyledSider>
        <TransitionLayout collapsed={collapsed}>
          <HeaderComponent onNavigateToSection={this.navigateTo} location={this.props.location} />
          <StyledContent>{this.props.children}</StyledContent>
        </TransitionLayout>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ user: state.user, wedding: state.wedding });

const mapActionToProps = dispatch =>
  bindActionCreators({ AddWedding, ClearWedding }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(ProcessLayout)
);
