import React from 'react'
import moment from 'moment'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { Col, Dropdown, Menu, Row, Tooltip } from 'antd'
import CurrencyComponent from '../currency/CurrencyComponent'
import AgendaActions from '../../infra/enum/agendaAction'
import { ReserveStatus } from '../../weddingday/reserves/ReserveAgenda'
import CurrencyInput from '../inputs/CurrencyInput'
import { PrimaryColour } from '../../styles/Colours'

function createCalendar(currentDate) {
    if (!currentDate) {
        currentDate = moment.utc()
    } else {
        currentDate = moment.utc(currentDate)
    }

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    const weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - first.day())
            date.calendar = calendar
            week.push(date)
        }
    }

    return calendar
}

function getTitle(title) {
    return title === 'OCCUPIED_DATE' ? 'Data Ocupada' : title === 'AVAILABLE_DATE' ? 'Data Disponível'
        : title === 'UNAVAILABLE_DATE' ? 'Data Indisponível' : title === 'PRICE_TO_DEFINE' ? 'Preços a definir' : '';
}

function getHolidayName(holiday) {
    return holiday.localName;
}

function CalendarDate(props) {
    // console.warn('Props Date', props);
    const { dateToRender, dateOfMonth, events, selectedDates } = props;
    const today = dateToRender.format('YYYY-MM-DD') === moment.utc().format('YYYY-MM-DD') ? 'today' : '';
    const date = moment.utc(dateToRender.toISOString());
    const tmpId = moment.utc(date).format('DDDYYYY');
    const event = events.find(f => f.id === tmpId);
    // console.warn('Evt Year', event);

    const occupied = event && event.resource.isDateOccupied ? 'occupied' : '';

    const selected = event && event.resource.selected ? 'selected' : '';

    const missingPrice = event && !event.resource.isCompanyRestDay && !event.resource.price ? 'noPrice' : '';

    const style = event && event.resource.holiday || event && event.resource.localHoliday
        ? { color: PrimaryColour }
        : event && event.resource.reserves.length > 0
            ? {
                backgroundColor: event ? event.resource.color : '',
                color: event ? event.resource.color === PrimaryColour ? 'white' : 'grey' : ''
            }
            : null;

    const borderBottom = event && !event.resource.isCompanyRestDay &&
        !event.resource.isDateOccupied && event.resource.price
        && event.resource.price.minPax > 0 && event.resource.price.minPrice > 0 ?
        '1px solid rgb(255 255 255 / 90%)' : 'none';

    const marginBottom = event && !event.resource.isCompanyRestDay &&
        !event.resource.isDateOccupied && event.resource.price
        && event.resource.price.minPax > 0 && event.resource.price.minPrice > 0 ?
        5 : 0;

    if (dateToRender.month() < dateOfMonth.month() || dateToRender.month() > dateOfMonth.month()) {
        return (<button disabled={true} className="price prev-month next-month"></button>)
    }

    // if (dateToRender.month() < dateOfMonth.month()) {
    //     return (
    //         <button disabled={true} className="date prev-month">
    //             {dateToRender.date()}
    //         </button>
    //     )
    // }

    // if (dateToRender.month() > dateOfMonth.month()) {
    //     return (
    //         <button disabled={true} className="date next-month">
    //             {dateToRender.date()}
    //         </button>
    //     )
    // }

    const tooltip = <React.Fragment>
        <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 14, color: '#dbdbdb' }}>{date.format('DD-MM-YYYY')}</p>

        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday)}
            </p>
        }

        <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            {event && event.resource.reserves.length > 0 && !event.resource.isDateOccupied ?
                `Data com ${event.resource.reserves.length} reserva(s) sem compromisso`
                : event ? getTitle(event.title) : ''}
        </p>

        {event && !event.resource.isCompanyRestDay && !event.resource.isDateOccupied && event.resource.price
            && event.resource.price.minPax > 0 && event.resource.price.minPrice > 0 &&
            <React.Fragment>
                <p style={{ marginBottom: 0 }}>
                    <span><CurrencyComponent value={event.resource.price.minPrice} /></span>
                    &nbsp; por PAX
                </p>
                <p style={{ marginBottom: 0 }}>
                    Mínimo: &nbsp;
                    <span>{event.resource.price.minPax}</span> PAX
                </p>
                <p style={{ marginBottom: 0 }}>
                    Preço Global Mínimo: &nbsp;
                    <span><CurrencyComponent value={parseFloat((event.resource.price.minPax * event.resource.price.minPrice).toFixed(2))} /></span>
                </p>
            </React.Fragment>
        }
    </React.Fragment>

    const menu = event && !event.resource.isCompanyRestDay && !event.resource.isDateOccupied && !event.resource.isDateArchived ?
        <Menu
            theme='light'
            onClick={(e) => props.onClick({
                action: e.key,
                date,
                event: event
            })}>
            <Menu.Item key={AgendaActions.SELECT}>Selecionar Data</Menu.Item>
            {event && !event.resource.price || event.resource.price && !event.resource.isDateOccupied ?
                <Menu.Item key={AgendaActions.MNG_PRICE}>Gerir Preço</Menu.Item> : null}
            {event && event.resource.reserves.length === 0 ?
                <Menu.Item key={AgendaActions.Create_UNAVAILABLE}>Marcar Indisponibilidade</Menu.Item> : null}
        </Menu>
        : event && !event.resource.isCompanyRestDay && event.resource.isDateOccupied && event.resource.isDateArchived ?
            <Menu
                theme='light'
                onClick={(e) => props.onClick({
                    action: e.key,
                    date,
                    weddingId: event.resource.reserves.find(f => f.status === ReserveStatus.ARCHIVED)?.wedding,
                    event: event
                })}>
                {/* <Menu.Item key={AgendaActions.RESERVE_WEDDING}>Gerir Reserva</Menu.Item> */}
                <Menu.Item key={AgendaActions.ARCHIVED_WEDDING}>Aceder Arquivo</Menu.Item>
            </Menu>
            : event && !event.resource.isCompanyRestDay && event.resource.isDateOccupied && !event.resource.isDateArchived ?
                <Menu
                    theme='light'
                    onClick={(e) => props.onClick({
                        action: e.key,
                        date,
                        weddingId: event.resource.reserves.find(f => f.status === ReserveStatus.OCCUPIED)?.wedding,
                        event: event
                    })}>
                    {/* <Menu.Item key={AgendaActions.RESERVE_WEDDING}>Gerir Reserva</Menu.Item> */}
                    <Menu.Item key={AgendaActions.EDIT_WEDDING}>Editar Casamento</Menu.Item>
                    <Menu.Item key={AgendaActions.INFO_WEDDING}>Aceder Casamento</Menu.Item>
                </Menu>
                : null;

    return event && event.resource.isCompanyRestDay
        ? (<Tooltip title={tooltip} trigger='hover'>
            <button
                className={`price in-month ${occupied} ${today} ${selected} ${missingPrice}`}
                style={style}
            // onClick={e => e.preventDefault()}
            >
                I
            </button>
        </Tooltip >)
        : (<Dropdown overlay={menu} trigger={['click']}>
            <Tooltip title={tooltip} trigger='hover'>
                <button
                    className={`price in-month ${occupied} ${today} ${selected} ${missingPrice}`}
                    style={style}
                    onClick={e => e.preventDefault()}
                // onClick={(e) => props.onClick({
                //     date,
                //     resource: event.resource
                // })}
                >
                    {event && event.resource.isDateOccupied && event.resource.price
                        ? <CurrencyComponent value={event.resource.price.minPrice} />
                        : event && !event.resource.isDateOccupied && event.resource.price
                            ? (<b><CurrencyComponent value={event.resource.price.minPrice} /></b>)
                            : 'ND'}
                </button>
            </Tooltip >
        </Dropdown>);

}
class PriceView extends React.Component {
    state = {
        date: moment(),
        events: [],
        months: [],
        activeLanguage: {},
        selectedDates: [],
    }

    componentDidMount() {
        // console.warn('Props Year', this.props);
        this.setState({
            date: this.props.date,
            events: this.props.events,
        }, () => {
            // console.warn('Evts Year', this.state.events);
            this.setMonthsCalendar();
        });
    }

    componentDidUpdate(prevProps) {
        // console.warn('Props Year Update', this.props);
        if (this.props.date !== prevProps.date) {
            this.setState({ date: this.props.date })
        }

        if (this.props.loading !== prevProps.loading) {
            this.setState({ events: this.props.events }, () => this.setMonthsCalendar())
        }

        if (this.props.selectedDates !== prevProps.selectedDates) {
            this.setState({ selectedDates: this.props.selectedDates })
        }
    }

    setMonthsCalendar = () => {
        const { date, events } = this.state;
        const months = [];
        moment().locale('pt')

        for (let i = 0; i < 12; i++) {
            const startDate = moment.utc(date).set('month', i).startOf('month');

            const monthInfo = {
                month: i,
                calendar: createCalendar(startDate),
                events: events.filter(f => moment.utc(f.start).month() === i)
            };

            months.push(monthInfo);
        }

        this.setState({ months });
    }

    getMonthName = (date) => {
        return moment.utc(date).locale('pt').format('MMMM').toUpperCase();
    }

    getWeekdays = () => {
        moment.locale('pt');
        return moment.weekdaysShort();
    }

    render() {
        let { months, selectedDates } = this.state;

        return <Row gutter={[12, 12]} className="year">
            {months.map((month, monthIndex) => (
                <Col key={monthIndex} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className="month">
                    <div className="month-name">
                        {this.getMonthName(month)}
                    </div>
                    <div className='weekdays'>
                        {this.getWeekdays().map((day, index) => (
                            <span key={index} className="priceWeekDay">
                                {day}
                            </span>
                        ))}
                    </div>
                    {month.calendar.map((week, index) => (
                        <div key={index} className='month-days'>
                            {week.map(date => {
                                // console.warn('Date', date)
                                return <CalendarDate
                                    key={date.date()}
                                    dateToRender={date}
                                    events={month.events}
                                    selectedDates={selectedDates}
                                    dateOfMonth={month.calendar.currentDate}
                                    onClick={date => this.props.onDayClick(date)}
                                />
                            })}
                        </div>
                    ))}
                </Col>
            ))}
        </Row>
    }
}

// Day.propTypes = {
//     date: PropTypes.instanceOf(Date).isRequired,
// }

PriceView.range = date => {
    //     return [dates.startOf(date, 'year')]
}

PriceView.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return moment.utc(date).add(1, 'year') //dates.add(date, -1, 'year')
        case navigate.NEXT:
            return moment.utc(date).subtract(1, 'year')
        default:
            return date
    }
}

PriceView.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default PriceView
