import styled from 'styled-components';

export const BillingBox = styled.div`
  display: block;
  width: 100%;
  max-width: 500px;
  background: #f5f5f5;
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 30px 15px;
  position: relative;
`;

export const BillingItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: left;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
  }
`;

export const EmptySection = styled.div`
  display: inline-block;
  margin: 50px 0;
  text-align: center;
  width: 100%;
  i {
    font-size: 30px;
    margin-right: 15px;
  }
`;

export const SectionIcon = styled.img`
  display: inline-block;
  width: 30px;
  margin-right: 15px;
`;

export const OptionButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: -5px;
  right: ${({number}) => -5 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const DocumentSection = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
`;

export const FileDropSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  color: ${({theme}) => theme.inputBorderColor};
  border: 1px solid ${({theme}) => theme.inputBorderColor};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  margin: ${({ withLabel }) => withLabel ? '0px 0px 10px' : '20px 0'};
  cursor: pointer;
  font-size: 16px;

  i {
    font-size: 30px;
  }
`;
