import React from 'react';
import { scroller } from 'react-scroll';
import { Tree, Icon } from 'antd';
import { SectionMenuStyle } from './SectionStyles';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { InfoColor, PrimaryColour, SuccessColor } from '../../styles/Colours';

const { TreeNode } = Tree;

const defineIcon = question => {
  if (question.type === 9) {
    return <Icon style={{ color: InfoColor }} type="info-circle" />;
  }
  if (question?.answer?.response) {
    return <Icon style={{ color: SuccessColor }} type="check-circle" />;
  }
  return <Icon style={{ color: PrimaryColour }} type="close-circle" />;
};

const SectionMenu = ({ printed, section = { chapters: [] } }) => {
  const scrollTo = selected => {
    if (selected && selected[0]) {
      return scroller.scrollTo(selected[0], {
        duration: 500,
        smooth: true,
        containerId: 'section-container'
      });
    }
  };

  return (
    <SectionMenuStyle printed={printed}>
      <Tree
        showLine={false}
        defaultExpandAll
        blockNode
        showIcon
        autoExpandParent
        onSelect={scrollTo}>
        {section.chapters.map((chapter, cIndex) => (
          <TreeNode
            title={TranslateValue(chapter.name)}
            key={`anchor-${cIndex}`}
            icon={item => (
              <Icon type={item.expanded ? 'folder-open' : 'folder'} />
            )}>
            {chapter.questions.map((question, qIndex) => (
              <TreeNode
                icon={defineIcon(question)}
                title={TranslateValue(question.title)}
                key={`anchor-${cIndex}-${qIndex}`}
              />
            ))}
          </TreeNode>
        ))}
      </Tree>
    </SectionMenuStyle>
  );
};

export default SectionMenu;
