import styled from 'styled-components';
import { Button } from 'antd';
import ReactSVG from 'react-svg';

export const StyledButton = styled(Button)`
  float: ${p => (p.align ? p.align : 'left')};

  svg {
    width: ${p => p?.theme.iconSvgSize};
    height:  ${p => p?.theme.iconSvgSize};
  }

  ${({ notes, theme }) => notes === 1 &&
    `background-color: ${theme.notesColor} !important;
    border-color: ${theme.notesColor} !important;
    
    & i, & span {
      color: #ffffff !important;
    }
    
    &:hover {
      background-color: ${theme.notesColor} !important;
      border-color: ${theme.notesColor} !important;
      color: white !important;

      svg, svg path, i {
        color: white !important;
        fill: white !important;
        fill-opacity: 1 !important;
      }
    }`
  }
  
  &.ant-btn-danger {
    background-color: ${p => p?.filled ? p.theme.inputErrorColor : p.theme.primaryBackgroundColor};
    color: ${p => p?.filled ? 'white' : p.theme.inputErrorColor};
    svg, svg path {
      color: ${p => p?.filled ? 'white' : p.theme.inputErrorColor};
      fill: ${p => p?.filled ? 'white' : p.theme.inputErrorColor};
      fill-opacity: 1;
    }

    &:hover {
      background-color: ${p => p.theme.inputErrorColor};
      border-color: ${p => p.theme.inputErrorColor};
      color: white;

      svg, svg path {
        color: white;
        fill: white;
        fill-opacity: 1;
      }
    }
  }

  &.ant-btn-success {
    background-color: ${p => p?.filled ? p.theme.inputSuccessColor : p.theme.primaryBackgroundColor};
    color: ${p => p?.filled ? 'white' : p.theme.inputSuccessColor};
    svg, svg path {
      color:${p => p?.filled ? 'white' : p.theme.inputSuccessColor};
      fill: ${p => p?.filled ? 'white' : p.theme.inputSuccessColor};
      fill-opacity: 1;
    }

    &:hover {
      background-color: ${p => p.theme.inputSuccessColor};
      border-color: ${p => p.theme.inputSuccessColor};
      color: white;
      
      svg, svg path {
        color: ${p => p.theme.primaryColor};
        fill: ${p => p.theme.primaryColor};
        fill-opacity: 1;
      }
    }
  }

  &.ant-btn-default {
    &:hover {      
      svg, svg path {
        color: ${p => p.theme.primaryColor};
        fill: ${p => p.theme.primaryColor};
        fill-opacity: 1;
      }
    }
  }
`;

export const FloatButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: ${({ position }) =>
    position
      ? position === 1
        ? '70px'
        : `${(position - 1) * 55 + 70}px`
      : '15px'};

  &.ant-btn-danger {
    background-color: ${p => p.theme.primaryBackgroundColor};
    color: ${p => p.theme.inputErrorColor};

    &:hover {
      background-color: ${p => p.theme.inputErrorColor};
      color: white;
    }
  }
`;
