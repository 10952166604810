import { ErrorColor, GoldColor, InfoColor, LightPrimary, PrimaryColour, SuccessColor, WarningColor } from "./Colours";

export default {
  //colors 
  primaryColor: PrimaryColour,
  secondaryColor: '#DFE0DF',
  thirdColor: '#a2a2a2',
  notesColor: GoldColor,
  notesLightColor: '#DCCDA8',
  
  goldColor: GoldColor,
  staffColor: '#718271',
  coupleTableColor: '#FBF6EA',
  staffTableColor: '#D3DFDE',
  disabledTableColor: '#fafafa', 
  tableColor: '#F5F5F5',

  inputBorderColor: '#DFE0DF',
  inputFocusColor: '#9AA89A',
  inputErrorColor: '#f5222d',
  inputSuccessColor: '#80B99E',

  primaryBackgroundColor: '#ffffff',
  secondaryBackgroundColor: '#f3f3f3',

  tableSecondaryBg: '#f7f6f6',
  tableSecondaryPadding: '2px 5px',
  tableSecondaryLineHeight: '14px',
  tableSecondarySize: '13px',
  tableBorder: '#cecece',

  primaryLighter: LightPrimary,

  successColor: SuccessColor,
  warmingColor: WarningColor,
  errorColor: ErrorColor,
  infoColor: InfoColor,

  iconSvgSize: '15px',

  //text size
  titleSize: '30px',
  sectionSize: '24px',
  textSize: '14px',
  textColor: '#718271'

};
