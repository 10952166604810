import React from 'react';
import { Modal, Button } from 'antd';
import { Form as AntForm } from 'antd';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import FileInput from '../orders/components/FileInput';

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const SaveDeliveryButton = styled(Button)`
    background-color: green !important;
    border-color: green !important;
`;

const validations = (values) => {
    let errors = {};

    return errors;
};

class UploadContractModal extends React.Component {
    state = {
        initialValues: {},
        wedding: {},
        coupleIdFiles: [],
        activeLanguage: { code: 'pt' }
    }

    componentDidMount() {

    }

    save = (sendEmail) => {
        const {handleSubmit, onSubmit} = this.props;
        const {contractForm} = this.props;

        return handleSubmit(onSubmit({...contractForm, sendEmail}));
    }

    render() {
        const { openModal, loading } = this.props;
        const { handleSubmit, closeModal, onSubmit } = this.props;
        const { contractForm, initialValues } = this.props;

        return (
            <Modal
                visible={openModal}
                title={'Upload do contrato de casamento'}
                maskClosable={false}
                onCancel={() => {
                    closeModal();
                }}
                footer={[
                    <Button
                        key='cancel'
                        type='default'
                        onClick={closeModal}>
                        {'Cancelar'}
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        loading={loading}
                        onClick={() => this.save(false)}
                        disabled={!contractForm?.contractFile ? true : false}>
                        {'Guardar'}
                    </Button>,
                    <SaveDeliveryButton
                        key='submitWithEmail'
                        type='danger'
                        loading={loading}
                        onClick={() => this.save(true)}
                        disabled={!contractForm?.contractFile ? true : false}>
                        {'Guardar e Notificar'}
                    </SaveDeliveryButton>
                ]}
            >

                <BaseForm onSubmit={handleSubmit(onSubmit)}>
                    <Field
                        component={FileInput}
                        name={'contractFile'}
                        label={'Upload do documento'}
                        accept="application/pdf"
                    />
                </BaseForm>
            </Modal>
        )
    }
};

const selector = formValueSelector('upload_contract_form');

UploadContractModal = reduxForm({
    form: 'upload_contract_form',
    validate: validations
})(UploadContractModal);

const mapStateToProps = state => ({
    wedding: state.wedding.wedding,
    contractForm: selector(state, '_id', 'contractFile')
});

export default withLocalize(connect(mapStateToProps)(UploadContractModal))