import React from 'react';
import OptionInformation from './components/OptionInformation';
import ColorOption from './components/ColorOption';
import FileOption from './components/FileOption';
import FoodOption from './components/FoodOption';
import ImageOption from './components/ImageOption';
import InfoIcon from '../../assets/icons/ic_info.svg';
import EmptyIcon from '../../assets/icons/ic_empty_answer.svg';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import {
  ResponseSection,
  ResponsePill,
  OptionResponse,
  Observations,
  EmptySection,
  SectionIcon,
  QuestionIcon
} from './SectionStyles';
import { TableThemeLabel, TableThemeTypes } from './questionOptions/TableThemeCard';

const hasObservations = observations =>
  observations && (
    <Observations>
      <div>Observações:</div>
      {observations}
    </Observations>
  );

const hasNotes = (question, type, notes, goTo) => {
  const title = type == 'RPN'
    ? 'Notas do plano de sala'
    : type == 'KN'
      ? 'Notas da cozinha'
      : 'Notas';

  return question?._id == '5e1e58a76c30747c0329e56d' && notes && <Observations>
    <div>
      {title}
      {<QuestionIcon
        type="edit"
        title="Editar resposta"
        onClick={() => goTo()}
      />}
    </div>
    {notes}
  </Observations>
}

const renderConfirmation = response => {
  if (response) {
    return response.id === 'true'
      ? 'Sim'
      : response.id === 'false'
        ? 'Não'
        : 'Resposta inválida';
  }
  return 'Resposta inválida';
};

const foodType = type =>
  type === 12 ||
  type === 13 ||
  type === 14 ||
  type === 15 ||
  type === 16 ||
  type === 17;

const SectionResponse = ({ question, weddingPlace, upgrade, handleFileDelete, printed, navigateTo }) => {
  const response = question?.answer?.response;

  if (response) {
    if (question.type === 1 && question?.isTableTheme) {
      const resp = response?.id && response?.id != TableThemeTypes.OTHER
        ? TableThemeLabel[response.id]
        : response?.id == TableThemeTypes.OTHER
          ? response.value || ''
          : response;
      return (
        <ResponseSection>
          <ResponsePill single={true}>{resp}</ResponsePill>
          {hasObservations(question?.answer?.observations)}
        </ResponseSection>
      );
    }

    if (question.type === 1 || question.type === 2 || question.type === 3) {
      return (
        <ResponseSection>
          <ResponsePill single={true}>{response}</ResponsePill>
          {hasObservations(question?.answer?.observations)}
        </ResponseSection>
      );
    }

    if (question.type === 4 || question.type === 5 || question.type === 6) {
      return (
        <ResponseSection>
          {response?.length > 0 && response.map((option, index) => (
            <OptionResponse key={index}>
              <OptionInformation selected={option} question={question} options={question.options} weddingPlace={weddingPlace} upgrade={upgrade} />
            </OptionResponse>
          ))}
          {hasObservations(question?.answer?.observations)}
          {hasNotes(question, 'KN', question?.answer?.notesKitchen, navigateTo)}
          {hasNotes(question, 'RPN', question?.answer?.notes, navigateTo)}
        </ResponseSection>
      );
    }

    if (question.type === 7) {
      return (
        <ResponseSection>
          <FileOption file={response} question={question._id} handleDelete={handleFileDelete} printed={printed} />
          {hasObservations(question?.answer?.observations)}
        </ResponseSection>
      );
    }

    if (question.type === 8) {
      return (
        <ResponseSection>
          <ImageOption image={response} question={question._id} handleDelete={handleFileDelete} printed={printed} />
          {hasObservations(question?.answer?.observations)}
        </ResponseSection>
      );
    }

    if (question.type === 11) {
      return (
        <ResponseSection style={{ display: 'flex', flexDirection: 'row' }}>
          {response.map((option, index) => (
            <OptionResponse key={index}>
              <ColorOption color={option} />
            </OptionResponse>
          ))}
          {hasObservations(question?.answer?.observations)}
        </ResponseSection>
      );
    }

    if (question.type === 10) {
      return (
        <ResponseSection>
          <EmptySection>{TranslateValue(question.description)}</EmptySection>
          {Array.isArray(response) && (
            <ResponsePill single>
              {renderConfirmation(response[0])}
            </ResponsePill>
          )}
          {hasObservations(question?.answer?.observations)}
        </ResponseSection>
      );
    }

    if (foodType(question.type)) {
      return (
        <ResponseSection>
          <FoodOption question={question} response={response} upgrade={upgrade} />
          {hasObservations(question?.answer?.observations)}
        </ResponseSection>
      );
    }

    return (
      <ResponseSection>
        <ResponsePill>EXISTE RESPOSTA</ResponsePill>
        {hasObservations(question?.answer?.observations)}
      </ResponseSection>
    );
  }

  if (question.type === 9) {
    return (
      <ResponseSection>
        {question.description ? (
          <EmptySection>{TranslateValue(question.description)}</EmptySection>
        ) : (
          <EmptySection>
            <SectionIcon src={InfoIcon} alt="info" />
            Informação
          </EmptySection>
        )}
        {hasObservations(question?.answer?.observations)}
      </ResponseSection>
    );
  }

  return (
    <ResponseSection>
      <EmptySection>
        <SectionIcon src={EmptyIcon} alt="empty" />
        Sem resposta
      </EmptySection>
      {hasObservations(question?.answer?.observations)}
      {hasNotes(question, 'KN', question?.answer?.notesKitchen, navigateTo)}
      {hasNotes(question, 'RPN', question?.answer?.notes, navigateTo)}
    </ResponseSection>
  );
};

export default SectionResponse;
