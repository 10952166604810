import {Anchor} from 'antd';
import styled from 'styled-components';

export const ReserveContent = styled.div`
  border-left: 2px solid #f3f3f3;
  padding: 20px 0 20px 6px;
  height: 100%;

  @media (max-width: 991px) {
    border-left: unset;
  }
`;

export const ReserveTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

export const ReserveSubtitle = styled.div`
  color: ${p => p?.theme?.primaryColor};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-bottom: 5px;
`;

export const ReserveList = styled.ul`
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  list-style-type: none;
  padding-inline-start: 0;
`;

export const ReserveItem = styled.li`
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  background-color: ${({edit, theme}) =>
    edit ? theme.primaryColor : '#f3f3f3'};
  padding: 5px;
  margin: 5px 0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  .anticon {
    font-size: 16px;
    padding: 0 2px;
  }
`;

export const ReserveAnchor = styled(Anchor)`
  .ant-anchor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .ant-anchor-ink {
    display: none;
  }

  .ant-anchor-link {
    padding: 0;
    padding-inline-end: 10px;
    // padding-block-end: 10px;
  }

  .ant-anchor-link-title-active {
    text-decoration: underline;
  }
`;

export const ReserveText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 17px;
`;

export const ReserveInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
`;
