import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Modal } from 'antd';
import {
  Field,
  reduxForm,
  initialize,
  change as ChangeField,
  formValueSelector
} from 'redux-form';
import BaseButton from '../../components/buttons/BaseButton';
import FormValidator from '../../infra/services/validations/FormValidator';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectLanguageInput from '../../components/inputs/SelectLanguageInput';
import SelectInput from '../../components/inputs/SelectInput';
import { ModalButton, Form } from './ExportationStyles';
import { Costumizations, CostumeDefinitions, DefaultCustomizations, CustomizationsForm } from './Costumizations';
import { auth_token_key } from '../../infra/config/LocalStorageKeys';
import Alert from '../../components/alert/Alert';
import ButtonGroup from 'antd/lib/button/button-group';
import { AuthGoogleDrive, UploadMultipleToDrive, UploadToDrive } from '../../infra/requests/ReportsRequests';
import Alerts from '../../components/alert/Alert';

const validations = (values) => {
  let errors = {};
  errors = FormValidator.make({
    language: 'required',
    // definition: 'required'
  })(values);

  // if (Array.isArray(values.definition) && values.definition.length === 0) {
  //   errors['definition'] = 'true';
  // }

  return errors;
};

const REACT_APP_API = process.env.REACT_APP_API;

let ExportationModal = ({ weddingID, handleSubmit, submit, dispatch, language, definition }) => {
  const [openModal, setModal] = useState(false);
  const [download, setDownload] = useState(false);
  const [disableSections, toggleDisableSections] = useState(false);
  const [upload, setUpload] = useState(false);

  useEffect(() => {
    const formValue = localStorage.getItem('exportForm');
    if (formValue) {
      dispatch(
        initialize('manage_exportations_form', JSON.parse(formValue))
      );
      localStorage.removeItem('exportForm');
      setModal(true);
    }
  }, []);

  const resetAndOpen = () => {
    dispatch(
      initialize('manage_exportations_form', {
        // ...Costumizations['original'],
        definition: [],
        language
      })
    );
    setModal(true);
  };

  const onSubmit = values => {
    setDownload(true);

    const authToken = localStorage.getItem(auth_token_key);
    if (Array.isArray(values.definition) && values.definition.length > 1) {
      exportMultipleFiles(values, authToken);
    } else exportFile(values, authToken);
  };

  const exportFile = (values, authToken) => {
    let filename = weddingID;

    if (values?.definition && Array.isArray(values?.definition) && values?.definition.length == 0) {
      const fieldsToCheck = CustomizationsForm;

      if (fieldsToCheck.every((field) => !values[field])) {
        setDownload(false);
        return Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Selecione uma opção',
        });
      }
    }

    const data = {
      ...values,
      definition: values.definition[0]
    };
    // console.warn('data', data);

    fetch(
      `${REACT_APP_API}/reports/process/${weddingID}?filter=${JSON.stringify(
        data
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace('.pdf', '').replace(/#|_/g, '');

            name.concat('.pdf');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDownload(false);
            setModal(false);
          });
        } else {
          setDownload(false);
          resp.json().then(obj => {
            return Alert.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => console.error(e));
  }

  const exportMultipleFiles = (values, authToken) => {
    let filename = weddingID;

    const data = [];
    if (values.definition && Array.isArray(values.definition) && values.definition.length > 0) {
      values.definition.forEach(definition => {
        data.push({
          ...Costumizations[definition],
          definition,
          language
        });
      })
    }

    fetch(
      `${REACT_APP_API}/reports/process/${weddingID}/multiple?filter=${JSON.stringify(
        { categories: data, language }
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          // console.warn('resp', resp);
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : 'Test';
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace('.pdf', '').replace(/#|_/g, '');

            name.concat('.pdf');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDownload(false);
            setModal(false);
          });
        } else {
          setDownload(false);
          resp.json().then(obj => {
            return Alert.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => console.error(e));
  }

  const disableUpload = () => {
    if (definition && Array.isArray(definition)) {
      const definitionsSelected = definition.map((m: any) => CostumeDefinitions.find(f => f?._id && f._id === m));
      // console.warn('definitionsSelected', definitionsSelected);

      if (definitionsSelected && Array.isArray(definitionsSelected) && definitionsSelected.find(f => f?.drive && f?.drive === true)) {
        return false;
      } else return true;
    } else return true;
  }

  const isToDownloadAndUpload = () => {
    if (definition && Array.isArray(definition)) {
      const definitionsSelected = definition.map((m: any) => CostumeDefinitions.find(f => f?._id && f._id === m));
      // console.warn('definitionsSelected', definitionsSelected);

      const isToDownload = definitionsSelected && Array.isArray(definitionsSelected)
        && definitionsSelected.find(f => f?.drive !== undefined && f?.drive === false);

      const isToUploadToDrive = definitionsSelected && Array.isArray(definitionsSelected)
        && definitionsSelected.find(f => f?.drive !== undefined && f?.drive === true);

      return isToDownload && isToUploadToDrive ? true : false;
    } else return false;
  }

  const onUploadToDrive = async (values) => {
    // console.warn('drive val', values);
    setUpload(true);

    const result = await AuthGoogleDrive();
    // console.warn('result', result);

    if (!result?.data?.active) {
      localStorage.setItem('googleReturnUrl', window.location.pathname);
      localStorage.setItem('exportForm', JSON.stringify(values));
      const newWindow = window.open(result?.data.authUrl, '_self', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null;
      setUpload(false);
      return;
    }

    if (values?.definition && Array.isArray(values.definition) && values.definition.length > 1) {
      uploadMultipleFiles(values);
    } else uploadFile(values);
  };

  const uploadFile = async (values) => {
    const data = {
      ...values,
      definition: values.definition[0]
    };
    // console.warn('data', data);

    const result = await UploadToDrive(weddingID, JSON.stringify(data));
    // console.warn('res', result);

    setUpload(false);
    if (result?.success) {
      setModal(false);
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Upload com sucesso para o Google Drive!'
      });
    }
  }

  const uploadMultipleFiles = async (values) => {
    const data = [];
    if (values.definition && Array.isArray(values.definition) && values.definition.length > 0) {
      values.definition.forEach(definition => {
        data.push({
          ...Costumizations[definition],
          definition,
          language
        });
      })
    }

    const downloadAndUpload = isToDownloadAndUpload();
    // console.warn('downloadAndUpload', downloadAndUpload);

    if (downloadAndUpload) {
      downloadAndUploadToDrive(data);
    } else {
      const result = await UploadMultipleToDrive(weddingID, JSON.stringify({ categories: data }));
      // console.warn('res', result);

      setUpload(false);
      if (result?.success) {
        setModal(false);
        Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Upload com sucesso para o Google Drive!'
        });
      }
    }
  }

  const downloadAndUploadToDrive = (data) => {
    const authToken = localStorage.getItem(auth_token_key);
    let filename = weddingID;

    fetch(
      `${REACT_APP_API}/reports/process/${weddingID}/uploadDownloadMultiple?filter=${JSON.stringify(
        { categories: data, language }
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          // console.warn('resp', resp);
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : 'Test';
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace('.pdf', '').replace(/#|_/g, '');

            name.concat('.pdf');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setUpload(false);
            setModal(false);
          });
        } else {
          setUpload(false);
          resp.json().then(obj => {
            return Alert.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => console.error(e));
  }

  const handleCheckChange = () => {
    if (definition && Array.isArray(definition) && definition.length === 1) {
      dispatch(ChangeField('manage_exportations_form', 'definition', ''));
    }
  };

  const handleCostumeChange = (option) => {
    // console.warn('option', option);
    let values = { ...DefaultCustomizations };
    // console.warn('val original', { ...values });

    if (option && Array.isArray(option) && option.length > 1) {
      toggleDisableSections(true);
    } else toggleDisableSections(false);


    if (option && Array.isArray(option) && option.length > 0) {
      option.forEach(opt => {
        const data = Costumizations[opt];

        values['timeline'] = values?.timeline || data?.timeline ? true : false;
        values['food'] = values?.food || data?.food ? true : false;
        values['drinks'] = values?.drinks || data?.drinks ? true : false;
        values['staff'] = values?.staff || data?.staff ? true : false;
        values['decoration'] = values?.decoration || data?.decoration ? true : false;
        values['style'] = values?.style || data?.style ? true : false;
        values['ceremony'] = values?.ceremony || data?.ceremony ? true : false;
        values['party'] = values?.party || data?.party ? true : false;
        values['upgrade'] = values?.upgrade || data?.upgrade ? true : false;
        values['budget'] = values?.budget || data?.budget ? true : false;
        values['pending_and_budget'] = values?.pending_and_budget || data?.pending_and_budget ? true : false;
        values['unanswered'] = values?.unanswered || data?.unanswered ? true : false;
        values['photo'] = values?.photo || data?.photo ? true : false;
        values['public_notes'] = values?.public_notes || data?.public_notes ? true : false;
        values['private_notes'] = values?.private_notes || data?.private_notes ? true : false;
        values['guests'] = values?.guests || data?.guests ? true : false;
        values['contacts'] = values?.contacts || data?.contacts ? true : false;
        values['about_us'] = values?.about_us || data?.about_us ? true : false;
      });
    }

    // console.warn('val end', values);
    dispatch(
      initialize('manage_exportations_form', {
        ...values,
        definition: option,
        language
      })
    );
  };

  return (
    <Fragment>
      <ModalButton>
        <BaseButton
          type="default"
          icon="file-protect"
          text="Exportações"
          onClick={resetAndOpen}
        />
      </ModalButton>
      <Modal
        visible={openModal}
        title="Exportações"
        maskClosable
        onCancel={() => setModal(false)}
        footer={[
          <Button
            key='cancel'
            type='default'
            disabled={download || upload}
            onClick={() => setModal(false)}>
            Cancelar
          </Button>,
          <ButtonGroup key='downloadUpload' style={{ marginLeft: 5 }}>
            <Button
              key='submit'
              type='primary'
              loading={download}
              disabled={download || upload}
              onClick={handleSubmit(onSubmit)}>
              Exportar
            </Button>
            <Button
              key='drive'
              type='primary'
              loading={upload}
              disabled={(download || upload) || disableUpload()}
              onClick={handleSubmit(onUploadToDrive)}>
              Drive
              <Icon type="cloud-download" />
            </Button>
          </ButtonGroup>
        ]}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'definition'}
            label={'Pré-definidas'}
            placeholder="Customizado"
            data={CostumeDefinitions}
            onChange={handleCostumeChange}
            mode={'multiple'}
            allowClear={true}
          />
          <Field
            component={SelectLanguageInput}
            name={'language'}
            label={'Idioma'}
          />
          <Field
            component={CheckboxInput}
            name="contacts"
            label="Secções"
            onChange={handleCheckChange}
            positiveLabel="Contactos Noivos & Convidado & Alternativos"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="pending_and_budget"
            onChange={handleCheckChange}
            positiveLabel="Pendentes e Orçamento"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="about_us"
            onChange={handleCheckChange}
            positiveLabel="Sobre Nós (Notas)"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="timeline"
            onChange={handleCheckChange}
            positiveLabel="O nosso dia: Timeline completa"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="guests"
            positiveLabel="Convidados"
            onChange={handleCheckChange}
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="food"
            positiveLabel="Food Selection"
            onChange={handleCheckChange}
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="drinks"
            positiveLabel="Drinks Selection"
            onChange={handleCheckChange}
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="staff"
            positiveLabel="Staff"
            onChange={handleCheckChange}
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="decoration"
            onChange={handleCheckChange}
            positiveLabel="Decoração"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="style"
            onChange={handleCheckChange}
            positiveLabel="O nosso estilo"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="ceremony"
            onChange={handleCheckChange}
            positiveLabel="Cerimónia"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="party"
            onChange={handleCheckChange}
            positiveLabel="Party Selection"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="upgrade"
            onChange={handleCheckChange}
            positiveLabel="Detalhes do Upgrade"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="budget"
            onChange={handleCheckChange}
            positiveLabel="Orçamento"
            disabled={disableSections}
          />
          <Field
            component={CheckboxInput}
            name="unanswered"
            onChange={handleCheckChange}
            positiveLabel="Perguntas sem resposta"
            disabled={disableSections}
          />

          <Field
            component={CheckboxInput}
            name="photo"
            label="Opções"
            onChange={handleCheckChange}
            positiveLabel="Incluir photo do casal no cabeçalho"
          />
          <Field
            component={CheckboxInput}
            name="public_notes"
            onChange={handleCheckChange}
            positiveLabel="Mostrar notas publicas"
          />
          <Field
            component={CheckboxInput}
            name="private_notes"
            onChange={handleCheckChange}
            positiveLabel="Mostrar notas privadas"
          />
        </Form>
      </Modal>
    </Fragment>
  );
};

ExportationModal = reduxForm({
  form: 'manage_exportations_form',
  validate: validations
})(ExportationModal);

const selector = formValueSelector('manage_exportations_form');

const mapStateToProps = state => ({
  language: selector(state, 'language'),
  definition: selector(state, 'definition'),
  initialValues: { language: 'pt' }
});

export default connect(mapStateToProps)(ExportationModal);
