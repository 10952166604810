import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import TextInput from "../../../components/inputs/TextInput";
import SelectInput from "../../../components/inputs/SelectInput";

export const TableThemeLabel = {
  NUMBER_FULL: 'Números por extenso',
  NUMBER: 'Números (algarismo)',
  OTHER: 'Outro',
}

export const TableThemeTypes = {
  NUMBER_FULL: 'NUMBER_FULL',
  NUMBER: 'NUMBER',
  OTHER: 'OTHER',
}

const ThemeOptions = [
  { _id: TableThemeTypes.NUMBER_FULL, name: TableThemeLabel[TableThemeTypes.NUMBER_FULL] },
  { _id: TableThemeTypes.NUMBER, name: TableThemeLabel[TableThemeTypes.NUMBER] },
  { _id: TableThemeTypes.OTHER, name: TableThemeLabel[TableThemeTypes.OTHER] },
];

const TableThemeCard = ({ input }) => {
  const [optionId, setOptionId] = useState(null);
  const [other, setOther] = useState('');

  useEffect(() => {
    setOptionId(input?.value?.id || null);
    setOther(input?.value?.value || '');
  }, [])

  const onChangeTheme = (value) => {
    setOptionId(value);
    const otherText = value != TableThemeTypes.OTHER
      ? TableThemeLabel[value]
      : '';
    setOther(otherText);
    input.onChange({ id: value, value: otherText });
  }

  const onChangeOther = (value) => {
    setOther(value);
    input.onChange({ id: optionId, value: value });
  }

  return (
    <React.Fragment>
      <SelectInput
        placeholder="Selecione uma opção"
        data={ThemeOptions}
        meta={{}}
        input={{
          value: optionId,
          onChange: onChangeTheme
        }}
      />

      {optionId == TableThemeTypes.OTHER && <TextInput
        label="Outro tema"
        placeholder="Escreva o tema"
        meta={{}}
        type="text"
        input={{
          value: other,
          onChange: e => onChangeOther(e.target.value)
        }}
      />}
    </React.Fragment>
  );
};

export default TableThemeCard;