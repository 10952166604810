import styled from 'styled-components';
import { Icon } from 'antd';

export const ChoicesPageContainer = styled.div`
  width: 100%;
  margin-top: ${({ printed }) => printed ? '125px' : '76px'};
  position: relative;
  display: inline-block;
`;

export const OurDayPageContainer = styled.div`
  width: 100%;
  margin-top: 76px;
  position: relative;
  display: inline-block;
  padding-top: ${({ printed }) => printed ? '50px' : '0px'};
  height: calc(100vh - 146px);
`;

export const SectionMenuStyle = styled.div`
  display: inline-block;
  float: left;
  width: 300px;
  height: ${({ printed }) => printed ? 'calc(100vh - 195px)' : 'calc(100vh - 146px)'};
  padding: 15px 10px;
  border-right: 1px solid ${({ theme }) => theme.inputBorderColor};
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;

  .ant-tree li.hide-guest {
    display: none;
  }
`;

export const SectionContentStyle = styled.div`
  display: inline-block;
  width: ${({ notesOpen }) => notesOpen ? 'calc(100% - 600px)' : 'calc(100% - 300px)'};
  height: ${({ printed }) => printed ? 'calc(100vh - 195px)' : 'calc(100vh - 146px)'};
  padding: 0 15px;
  text-align: left;
  float: left;
  overflow-y: auto;
`;

export const SectionTitle = styled.div`
  position: relative;
  font-size: 28px;
  color: white;
  background: ${({ theme }) => theme.primaryColor};
  text-align: left;
  margin: 0 -15px 20px -15px;
  padding: 10px 15px;
  width: calc(100% + 30px);
`;

export const QuestionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

export const QuestionTitle = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.thirdColor};
  text-align: left;
  margin-bottom: 0;
`;

export const QuestionTitleModal = styled.h3`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  margin-bottom: 0;
`;

export const QuestionIcon = styled(Icon)`
  cursor: pointer;
  font-size: 18px;
  transition: opacity 0.5s linear;
  padding-left: 10px;

  &:hover {
    opacity: 0.6;
  }
`;

// display: inline-block;
export const ResponseSection = styled.div`
  width: 100%;
  display: flex;  
  flex-direction: ${({ isColor }) => isColor ? 'row' : 'column'};
  align-items: flex-start;
  margin-bottom: 30px;
  font-size: 16px;
  ${({ isColor }) => isColor ? 'flex-wrap: wrap;' : ''}
`;

// display: inline-block;
// margin: 0 30px 30px 0;
export const OptionResponse = styled.div`
  display: flex;
  margin: 0 15px 15px 0;
`;

// display: inline-block;
export const ResponsePill = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
  text-align: left;
  padding: 5px 10px;
  background: ${({ transparent }) => (transparent ? 'transparent' : '#E0E0E0')};
  border-radius: 4px;
  border: 2px solid #E0E0E0;
  border-top-right-radius: ${({ single }) => (single ? 4 : 0)}px;
  border-bottom-right-radius: ${({ single }) => (single ? 4 : 0)}px;
  white-space: pre-line;
  position: relative;
  font-weight: bold;
`;

// display: inline-block;
// align-content: center;
export const ExtraPill = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #E0E0E0;
  background: white;
  text-align: center;
  padding: 5px 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-bottom-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-right: ${({ last }) => (last ? 2 : 0)}px solid #E0E0E0;
  cursor: ${({ link }) => (link ? 'pointer' : 'default')};
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: -10px;
  right: -10px;
  box-shadow: 1px 1px 3px #ccc;
`;

export const Counting = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 22px;
`;

export const Observations = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  white-space: pre-line;

  div {
    font-weight: bold;
  }
`;

export const ColorBox = styled.div`
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: ${({ color }) => color || '#FFF'};
`;

export const ImageBox = styled.img`
  width: 100px;
  height: 100px;
  display: inline-block;
`;

export const ColorName = styled.div`
  font-size: 14px;
`;

export const EmptySection = styled.div`
  display: inline-block;
  width: 100%;

  i {
    font-size: 30px;
    margin-right: 15px;
  }
`;

export const SectionIcon = styled.img`
  display: inline-block;
  width: 30px;
  margin-right: 15px;
`;

export const ImagePill = styled.img`
  display: inline-block;
  width: 300px;
`;

/************************************ ANSWERS ************************************/

export const ResponseContainer = styled.div`
  margin-top: 20px;
  max-height: 450px;
  overflow-y: auto;
`;

export const ColorSelection = styled.div`
  opacity: ${p => (p.disabled ? '0.5' : '1')};
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  border: 2px solid ${p => (p.selected ? p?.theme?.primaryColor : '#E0E0E0')};
  border-radius: 4px;
  background: ${p => p.selected ? 'rgba(222, 203, 179, 0.3)' : 'transparent'};
  width: min-content;
`;

export const OptionSelection = styled.div`
  opacity: ${p => (p.disabled ? '0.5' : '1')};
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  border: 2px solid ${p => (p.selected ? p?.theme?.primaryColor : '#E0E0E0')};
  border-radius: 4px;
  background: ${p => (p.transparent ? 'transparent' : p.selected ? p?.theme?.primaryColor : '#E0E0E0')};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
}
`;

export const OptionContainer = styled.div`
  display: inline-block;
  padding: 0 20px 20px 0;
`;

export const OptionFileContainer = styled.div`
  display: inline-block;
  padding: 0 0 20px 0;
  width: 100%;
`;

export const OptionsSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const OptionContent = styled.div`
  display: inline-block;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 5px 10px;
  white-space: pre-line;
  cursor: pointer;
  background: ${p => (p.transparent ? 'transparent' : '#E0E0E0')};
`;

// display: inline-block;
export const OptionContentExtra = styled.div`
  border: 1px solid #E0E0E0;
  background: white;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 5px 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-bottom-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-right: ${({ last }) => (last ? 1 : 0)}px solid #E0E0E0;
  cursor: pointer;
  
  .anticon {
    margin-right: 5px;
    font-size: 20px;
  }
`;

export const QuestionInfo = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
`;

/************************************ ABOUT US ************************************/

export const AddBillingContainer = styled.div`
  text-align: right;
  margin-bottom: 20px;
`;

export const SaveAboutUsContainer = styled.div`
  text-align: left;
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 20px 0px;
`;

export const SaveGuestContainer = styled.div`
  text-align: left;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 0px;
`;

export const AboutUsTitle = styled.h2`
  font-size: ${({ theme, subsubsection }) => subsubsection ? '20px' : theme.sectionSize};
  color: ${({ theme }) => theme.thirdColor};
  margin-bottom: ${({ subsubsection }) => subsubsection ? '0px' : '10px'};
  text-align: left;
  margin-top: ${({ subsection, subsubsection }) => subsubsection ? '15px' : (subsection ? '50px' : 0)};
`;

export const FormContainer = styled.div`
  width: calc(100% - 20px);
  max-width: ${({ singleColumn }) => (singleColumn ? '600px' : '100%')};
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${p => p.theme.primaryBackgroundColor};
  text-align: left;
`;

export const CodeP = styled.p`
  font-size: 16px; 
  line-height: 17px;
  text-align: start;
  color: #a2a2a2;
  margin-bottom: 5px !important;
`;

export const CodeInput = styled.div`
  display: flex;
  margin-bottom: 20px;

  .ant-input {
    border-radius: 4px 0 0 4px;
  }

  .ant-btn {
    border-left: none;
  }

  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    border: 1px solid #c9a947;
  }
`;