import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import {
  InputLabelDiv,
  InputDiv,
  FileSection,
  FileDropSection
} from './InputStyles';
import {Icon, Popconfirm} from 'antd';
import {AsyncForEach} from './../../infra/services/async/AsyncFunctions';

const FilesInput = ({input, handleAdd, handleDelete, maxSize, label, maxFiles, accept, meta: { invalid, submitFailed, error }}) => {
  const [loading, setLoading] = useState(false);

  const handleDrop = async accepted => {
    const saved = [];
    setLoading(true);
    await AsyncForEach(accepted, async file => {
      const {data, success} = await handleAdd(file);
      if (success) saved.push(data);
    });
    input.onChange([...input.value, ...saved]);
    setLoading(false);
  };

  const removeFromList = id => {
    if (handleDelete) handleDelete(id);
    input.onChange(input.value.filter(x => x._id !== id));
  };

  const showError = invalid && submitFailed;
  return (
    <InputDiv>
      <InputLabelDiv>{label}</InputLabelDiv>
      <Dropzone
        style={{width: '100%', height: '100%', display: 'inline-block'}}
        onDrop={handleDrop}
        maxSize={maxSize}
        accept={accept || ''}
        disabled={input.value.length >= maxFiles}>
        {({getRootProps, getInputProps}) => (
          <FileDropSection error={showError} {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon type={loading ? 'loading' : 'plus'} />
            <div>
              {loading ? 'loading...' : 'Click here or drag your files'}
            </div>
          </FileDropSection>
        )}
      </Dropzone>
      {Array.isArray(input.value)
        ? input.value.map((file, index) => (
            <FileSection key={index}>
              {file.name || file.filename || ''}
              <Popconfirm
                placement={'topRight'}
                title="Are you sure you want to delete this file?"
                onConfirm={() => removeFromList(file._id)}>
                <Icon type="delete" />
              </Popconfirm>
            </FileSection>
          ))
        : ''}
    </InputDiv>
  );
};

export default FilesInput;
