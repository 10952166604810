import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Modal } from 'antd';
import Dropzone from 'react-dropzone';
import { DocumentSection, FileDropSection } from './Styles';
import FileOption from '../../ChoicesPage/components/FileOption';
import { AsyncForEach } from './../../../infra/services/async/AsyncFunctions';
import {
  AddCoupleIdDocument,
  DeleteCoupleIdDocument,
} from '../../../redux/wedding/wedding.actions';
import { constrainPoint } from '@fullcalendar/common';
import { getFilePrintedConfirmation } from '../../../infra/services/wedding/printedUtils';
import { AddCoupleIdFiles, RemoveCoupleIdFiles } from '../../../infra/requests/WeddingProcessRequests';
import { InputDiv, InputLabelDiv } from '../../../components/inputs/InputStyles';
import { ResponsePill } from '../../ChoicesPage/SectionStyles';

const { confirm } = Modal;

const CoupleIdsDocuments = ({ wedding, maxFiles, dispatch, label, type }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const disableUpload = wedding?.coupleIdFiles?.length >= maxFiles ? true : false;

    setDisabled(disableUpload);
  }, [wedding, maxFiles])

  const handleDrop = async (accepted) => {
    if (wedding?.printed) {
      confirm({
        ...getFilePrintedConfirmation(),
        onOk: () => { saveOnDrop(accepted); },
        onCancel: () => { },
      });
    } else saveOnDrop(accepted);
  }

  const saveOnDrop = async (accepted) => {
    setLoading(true);
    await AsyncForEach(accepted, async file => {
      const payload = new FormData();
      payload.append('file', file);
      const { data, success } = await AddCoupleIdFiles(wedding._id, payload);
      if (success) {
        dispatch(AddCoupleIdDocument(data));
      }
    });
    setLoading(false);
  };

  const handleDelete = async file => {
    setLoading(true);
    const { success } = await RemoveCoupleIdFiles(wedding._id, file._id);
    if (success) {
      dispatch(DeleteCoupleIdDocument(file._id));
    }
    setLoading(false);
  };

  return (
    <InputDiv>
      <InputLabelDiv horizontal={false}>{label}</InputLabelDiv>
      {type === 'coupleIdFiles' && <Dropzone onDrop={handleDrop} disabled={disabled} accept={'application/pdf'}>
        {({ getRootProps, getInputProps }) => (
          <FileDropSection withLabel {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon type={loading ? 'loading' : 'plus'} />
            <div>
              {loading
                ? 'loading...'
                : 'Click ou arraste os ficheiros para aqui'}
            </div>
          </FileDropSection>
        )}
      </Dropzone>}

      {type === 'coupleIdFiles' && wedding.coupleIdFiles.map(document => (
        <DocumentSection key={document._id}>
          <FileOption file={document} handleDelete={handleDelete} fileType='document' wedding={wedding} printed={wedding.printed} />
        </DocumentSection>
      ))}

      {type === 'contractFile' && <DocumentSection key={wedding?.contractFile?._id}>
        {wedding?.contractFile 
          ? <FileOption file={wedding?.contractFile} fileType='document' wedding={wedding} printed={wedding.printed} /> 
          : <ResponsePill>Contrato em falta</ResponsePill>
        }
        
      </DocumentSection>}
    </InputDiv>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ AddCoupleIdDocument, DeleteCoupleIdDocument, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(CoupleIdsDocuments);
