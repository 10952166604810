import React, {Fragment} from 'react';
import {notification, Icon} from 'antd';
import Dropzone from 'react-dropzone';
import {ResponsePill, ExtraPill, FileSection, DivMarginTop} from '../../weddingday/orders/components/Styles';

const FileInput = ({input, meta, accept, execAfterChange}) => {

  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const handleDrop = async (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 12Mb'
      });
    }

    // Displaying selected files on the UI
    const uploaded = accepted.map(file => ({preview: URL.createObjectURL(file), size: file.size, type: file.type, blob: file}));
    input.onChange(uploaded);

    if(typeof execAfterChange === "function") {
      await execAfterChange(uploaded);
    }
  };

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={true}
        onDrop={handleDrop}
        accept={accept || ''}
        maxSize={120000000}>
        {({getRootProps, getInputProps}) => {
          return (
            <FileSection {...getRootProps()} error={showError ? 1 : 0} >
              <input {...getInputProps()} />
              <Icon type="file" />
              Clique ou arraste um ficheiro
            </FileSection>
          );
        }}
      </Dropzone>
    );
  };
  
  if(input?.value && Object.keys(input?.value)?.length > 0) {
    return (
      <Fragment>
        {input.value.map(file => (
          <DivMarginTop value="5px">
            <ResponsePill single={false}>{(file.blob && file.blob.name) || file.filename || ''}</ResponsePill>
            <ExtraPill last={true}>{ file?.size 
              ? (file?.size /(1024 * 1024)).toFixed(2)
              : (file?.length /(1024 * 1024)).toFixed(2)}MB
              </ExtraPill>
          </DivMarginTop>
        ))}
        {renderDropZone()}
      </Fragment>
    );
  }
  return renderDropZone();
};

export default FileInput;