import React, { Component, Fragment } from 'react';
import { initialize } from 'redux-form';
import { Popconfirm, Icon, Row, Col } from 'antd';
import { connect } from 'react-redux';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading,
  SectionTitle
} from '../../styles/BasicStyles';
import { GetQuestion } from '../../infra/requests/QuestionsRequests';
import {
  CreateOption,
  UpdateOption,
  DeleteOption,
  UpdateOptionOrder,
  SoftDeleteOption,
  ActivateOption
} from '../../infra/requests/OptionsRequests';
import { GetAllSupplierCategories } from '../../infra/requests/SupplierRequests';
import BaseButton from '../../components/buttons/BaseButton';
import SectionNames from '../../infra/services/sections/SectionNames';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import ManageOptionModal from './ManageOptionModal';
import ManageItemModal from './ManageItemModal';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import {
  OptionBox,
  OptionTitle,
  OptionCost,
  OptionDescription,
  FloatButton,
  ItemsList,
  RadioItem,
  ItemOption
} from './OptionsStyles';
import ImageComponent from '../../components/images/ImageComponent';
import OrderOptions from './OrderOptions';
import DeleteConfirmModal from './DeleteConfirmModal';
import { GetPriceTag } from '../../infra/services/options/Options';
import { PrimaryColour } from '../../styles/Colours';

class QuestionOptionsPage extends Component {
  state = {
    section: '',
    chapter: '',
    question: {},
    rows: [],
    loading: false,
    ready: false,
    openModal: false,
    orderModal: false,
    supplierCategories: [],
    openDeleteConfirmModal: false,
    deleteOptionId: ''
  };

  componentDidMount() {
    this.getQuestion();
  }

  getQuestion = async () => {
    const {
      match: { params }
    } = this.props;
    this.setState({ loading: true });
    try {
      const { data } = await GetQuestion(params.id);
      const supplierCategories = await GetAllSupplierCategories();
      this.setState({
        question: data,
        chapter: data?.chapter,
        section: data?.chapter?.section?.tag,
        rows: data?.options || [],
        ready: true,
        loading: false,
        supplierCategories: supplierCategories?.data || []
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  openModal = (data = {}) => {
    const { dispatch } = this.props;
    dispatch(initialize('manage_option_form', { ...data, tableForRoomPlan: false }));
    this.setState({ openModal: true, edit: data._id });
  };

  editOption = (option) => {
    const { history } = this.props;
    history.push(`/option/${option?._id}`);
  }

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(initialize('manage_option_form', {}));
    this.setState({ openModal: false, edit: false, loading: false });
  };

  onModalSubmit = async values => {
    const {
      match: { params }
    } = this.props;
    this.setState({ loading: true });
    if (values.image && !values.image.blob) {
      delete values.image;
    }
    const payload = FlattenToFormData({ ...values, question: params.id });

    values._id
      ? await UpdateOption(values._id, payload)
      : await CreateOption(payload);
    this.closeModal();
    await this.getQuestion();
  };

  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = options => {
    const { dispatch } = this.props;
    dispatch(initialize('manage_orderoptions_form', { options: [...options] }, false));
    this.setState({ orderModal: true });
  };

  onSubmitOrder = async values => {
    try {
      this.setState({ loading: true });

      if (values.options.length > 0) {
        let orderedList = [];
        Object.keys(values.options).forEach(function (key) {
          let obj = { _id: values.options[key]._id, order: key };

          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateOptionOrder({ order: orderedList });

          if (res.success) {
            this.getQuestion();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  removeOption = async (id, deleted = true) => {
    await SoftDeleteOption(id, deleted);
    await this.getQuestion();
  };

  activateOption = async (option) => {
    option.active = !option.active
    await ActivateOption(option._id, option.active);
    await this.getQuestion();
  };

  closeDeleteConfirmModal = () => {
    this.setState({
      openDeleteConfirmModal: false,
      deleteOptionId: ''
    });
  };

  onSubmitDeleteConfirm = async () => {
    const { deleteOptionId } = this.state;
    await SoftDeleteOption(deleteOptionId, true);
    await this.getQuestion();
    this.setState({
      openDeleteConfirmModal: false,
      deleteOptionId: ''
    });
  }

  renderOptions = () => {
    const { rows } = this.state;
    return (
      <Fragment>
        <SectionTitle subsection>Opções</SectionTitle>
        {rows.map(option => (
          <Col key={option._id} xs={12} sm={8} md={6}>
            <OptionBox>
              <ImageComponent
                color={option?.image?.color || '#ccc'}
                url={option?.image?.url}
                ratio={0.6}
              />
              <OptionTitle>{TranslateValue(option.title)}</OptionTitle>
              <OptionCost>
                {option?.isPremiumOption && <Icon type={'sketch'} />}
                {option.cost && (
                  <span>
                    {option.cost_price}€ {GetPriceTag(option.cost_type)}
                  </span>
                )}
              </OptionCost>
              <OptionDescription>
                {TranslateValue(option.description)}
              </OptionDescription>
              {option?.canBeDeleted && <FloatButton number={0}>
                {
                  (option?.supplier_category && Array.isArray(option?.supplier_category) && option?.supplier_category.length > 0)
                    ? <Icon type="delete" onClick={() => this.setState({ openDeleteConfirmModal: true, deleteOptionId: option._id })} />
                    : <Popconfirm
                      placement="topRight"
                      title="De certeza que quer apagar esta opção?"
                      onConfirm={() => this.removeOption(option._id)}>
                      <Icon type="delete" />
                    </Popconfirm>
                }
              </FloatButton>}
              <FloatButton number={option?.canBeDeleted ? 1 : 0} onClick={() => this.editOption(option)}>
                <Icon type="edit" />
              </FloatButton>
            </OptionBox>
          </Col>
        ))}
      </Fragment>
    );
  };

  renderList = () => {
    const { rows, question } = this.state;
    return (
      <ItemsList>
        {rows.map(option => (
          <Col key={option._id} xs={16} offset={4}>
            <RadioItem showIcon={question?._id == '5e73610729196b814fb9887f' ? true : false} inactive={!option.active}>
              {TranslateValue(option.title)}
              {(option.cost) && (
                <span>
                  {' - '}
                  {option?.isPremiumOption && <Icon type={'sketch'} style={{ marginRight: 5, color: PrimaryColour }} />}
                  {option.cost_price}€ {GetPriceTag(option.cost_type)}
                </span>
              )}
              {question?._id == '5e73610729196b814fb9887f' && <ItemOption number={2}>
                <Popconfirm
                  placement="topRight"
                  title={`De certeza que quer ${option.active ? 'desativar' : 'ativar'} esta opção?`}
                  onConfirm={() => this.activateOption(option)}>
                  <Icon type={option.active ? 'close-circle' : 'check-circle'} />
                </Popconfirm>
              </ItemOption>}
              {option.active &&
                <ItemOption number={1} onClick={() => this.openModal(option)}>
                  <Icon type="edit" />
                </ItemOption>}
              {option.active && <ItemOption number={0}>
                {
                  option.supplier_category ?
                    <Icon type="delete" onClick={() => this.setState({ openDeleteConfirmModal: true, deleteOptionId: option._id })} />
                    :
                    <Popconfirm
                      placement="topRight"
                      title="De certeza que quer apagar esta opção?"
                      onConfirm={() => this.removeOption(option._id, true)}>
                      <Icon type="delete" />
                    </Popconfirm>
                }
              </ItemOption>}
            </RadioItem>
          </Col>
        ))}
      </ItemsList>
    );
  };

  render() {
    const { history } = this.props;
    const {
      chapter,
      section,
      question,
      openModal,
      edit,
      ready,
      loading,
      rows,
      orderModal,
      supplierCategories,
      openDeleteConfirmModal
    } = this.state;
    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>{SectionNames[section || undefined]}</PageTitle>
            {TranslateValue(chapter?.name)} > {TranslateValue(question?.title)}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={3}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Nova opção'}
              onClick={() => this.openModal()}
            />
            <BaseButton
              type="default"
              icon="retweet"
              text="Editar Ordem"
              onClick={() => this.openOrderModal(rows)}
            />
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              onClick={() => history.push(`/chapter/${chapter._id}`)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={3}>
          <Row gutter={[12, 12]} type="flex" justify="center">
            <Col xs={16} style={{ textAlign: 'center' }}>
              {TranslateValue(question?.description)}
            </Col>
          </Row>
          <Row gutter={[12, 12]} type="flex" justify="center">
            {question?.images &&
              question.images.map(image => (
                <Col xs={4} key={image._id}>
                  <ImageComponent
                    color={image.color || '#ccc'}
                    url={image.url}
                    ratio={0.6}
                  />
                </Col>
              ))}
          </Row>
          <Row gutter={[24, 24]}>
            {question.type === 6 ? this.renderList() : this.renderOptions()}
          </Row>
        </PageContainer>
        {question.type === 6 ? (
          <ManageItemModal
            open={openModal}
            questionType={question.type}
            edit={edit}
            loading={loading}
            onSubmit={this.onModalSubmit}
            closeModal={this.closeModal}
            supplierCategories={supplierCategories}
          />
        ) : (
          <React.Fragment>
            <ManageOptionModal
              open={openModal}
              question={question}
              questionType={question.type}
              edit={edit}
              loading={loading}
              onSubmit={this.onModalSubmit}
              closeModal={this.closeModal}
              supplierCategories={supplierCategories}
            />
          </React.Fragment>
        )}
        <OrderOptions
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
        <DeleteConfirmModal
          open={openDeleteConfirmModal}
          loading={loading}
          onSubmit={this.onSubmitDeleteConfirm}
          closeModal={this.closeDeleteConfirmModal}
        />
      </React.Fragment>
    );
  }
}

export default connect()(QuestionOptionsPage);