import styled from 'styled-components';

export const FileSection = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  border: 1px solid ${({theme}) => theme.inputBorderColor};
  justify-content: center;
  align-items: center;
  padding: 15px;
  ${p =>
    p.error &&
    `border: 1px solid ${p.theme.inputErrorColor} !important;
  `}
`;

export const ResponsePill = styled.div`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  background: ${({transparent}) => (transparent ? 'transparent' : '#E0E0E0')};
  border-radius: 4px;
  border: 2px solid #E0E0E0;
  border-top-right-radius: ${({single}) => (single ? 4 : 0)}px;
  border-bottom-right-radius: ${({single}) => (single ? 4 : 0)}px;
  white-space: pre-line;
  position: relative;
  font-weight: bold;
`;

export const ExtraPill = styled.div`
  border: 2px solid #E0E0E0;
  background: white;
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${({last}) => (last ? 4 : 0)}px;
  border-bottom-right-radius: ${({last}) => (last ? 4 : 0)}px;
  border-right: ${({last}) => (last ? 2 : 0)}px solid #E0E0E0;
  cursor: ${({link}) => (link ? 'pointer' : 'default')};
`;

export const OptionsContainer = styled.div`
  width: 100%;
`;

export const OptionContent = styled.div`
  width: 100%;
`;

export const OptionLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const OptionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 11px;
  width: 100%;
  max-width: ${p => p.maxWidth ? `${p.maxWidth}px`: '100px'};
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  ${p => p.noBorderRadius && `border-radius: 0;`}
  ${p => p.noBorderTopRight && `border-top-right-radius: 0;`}
  ${p => p.noBorderTopLeft && `border-top-left-radius: 0;`}
  ${p => p.noBorderBottomRight && `border-bottom-right-radius: 0;`}
  ${p => p.noBorderBottomLeft && `border-bottom-left-radius: 0;`}
  cursor: default;
`;

export const OptionColumn = styled.div`
  width: 100%;
  max-width: ${p => p.maxWidth ? `${p.maxWidth}px`: '100px'};
  height: 32px;

  & .ant-input {
    ${p => p.noBorderRadius && `border-radius: 0;`}
    ${p => p.noBorderTopRight && `border-top-right-radius: 0;`}
    ${p => p.noBorderTopLeft && `border-top-left-radius: 0;`}
    ${p => p.noBorderBottomRight && `border-bottom-right-radius: 0;`}
    ${p => p.noBorderBottomLeft && `border-bottom-left-radius: 0;`}
  }

  & .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
    cursor: default;
  }
`;

export const OptionObs = styled.div`
  width: 100%;
  margin-bottom: 15px;

  & span {
    font-weight: bold;
  }
`;

export const OptionQuantity = styled.div`
  width: 100%;
  max-width: 180px;

  & > div {
    margin-top: 0;
  }

  & .ant-input-number, & .ant-input {
    border-radius: 0;
  }

  & .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
    cursor: default;
    font-weight: bold;
  }
`;

export const OptionType = styled.div`
  width: 35px;
  flex-shrink: 0;

  & .ant-input[disabled] {
    text-align: center;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 15px;
    cursor: default;
  }
`;

export const EmptyResults = styled.div`

`;

export const NotesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const NotesColumn = styled.div`
  flex-basis: 50%;
  padding: 5px;
`;

export const NotesContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #E8E8E8;
  padding: 7px;
`;

export const NotesTitle = styled.div`
  font-weight: 700;
  margin-bottom: 10px;

  & span {
    color: #89241F;
  }
`;

export const NotesText = styled.div`

`;

export const ResponseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${({ first }) => first ? '0px' : '40px'};
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const Response = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;

export const ImageLink = styled.a`

`;

export const Image = styled.img`
  width: 100%;
  max-width: 200px;
`;

export const NoResponse = styled.div`

`;

export const Observations = styled.div`
  margin-bottom: 5px;

  & span {
    font-weight: bold;
  }
`;

export const DivMarginTop = styled.div`
  margin-bottom: 0px;
  margin-top: ${({ value }) => value ? value : '0px'};
`;
