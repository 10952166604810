import React, { Fragment, useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { notification, Icon, Modal } from 'antd';
import Dropzone from 'react-dropzone';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { ResponsePill, ExtraPill, ResponseSection } from '../SectionStyles';
import { blobToFile, buildImageObject, resizeImage } from '../../../infra/services/images/ImageUtils';
import Cropper from 'react-cropper';
import { SpinLoading } from '../../../styles/BasicStyles';

const { REACT_APP_IMAGES_URL } = process.env;

export const FileSection = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;

  &:focus-within, &:hover {
    border: 1px solid ${p => p?.theme?.primaryColor};
  }
`;

export const ResponseFileSection = styled.div`
  width: 100%;
  display: flex;  
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 20px;
  font-size: 16px;
`;

export const CropperBox = styled.div`
  position: relative;

  .backdrop {
    z-index: 9;
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    border-radius: 5px;
  }
`;


const ImageCard = ({ input, name, noCrop }) => {
  const openFile = id => window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');

  const [openModal, toggleModal] = useState(false);
  const [uploaded, setUploaded] = useState(null);
  const [loading, toggleLoading] = useState(false);
  const [saving, toggleSaving] = useState(false);
  const cropper = useRef(null);

  const handleDrop = (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 6Mb'
      });
    }

    const uploaded = buildImageObject(accepted[0]);

    if (noCrop) {
      input.onChange(uploaded);
    } else {
      setUploaded(uploaded);
      toggleModal(true);
      toggleLoading(true);
    }
  };

  const onCropComplete = () => {
    if (cropper) {
      toggleSaving(true);
      const currCanvas = cropper.current.getCroppedCanvas();

      // const canvas = resizeImage(currCanvas);

      currCanvas.toBlob((blob) => {
        const fileImage = blobToFile(blob, uploaded.blob.name);
        const image = buildImageObject(fileImage);
        input.onChange(image);
        closeCropper();
      });
    }
  };

  const onCropCancel = () => {
    input.onChange(uploaded);
    closeCropper();
  }

  const closeCropper = () => {
    toggleModal(false);
    setUploaded(null);
    toggleSaving(false);
  };

  const renderDropZone = () => {
    return (
      <React.Fragment>
        <Dropzone
          multiple={false}
          onDrop={handleDrop}
          accept=""
          maxSize={60000000}>
          {({ getRootProps, getInputProps }) => {
            return (
              <FileSection {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon type="file" />
                &nbsp; Clique ou arraste um ficheiro
              </FileSection>
            );
          }}
        </Dropzone>
        {openModal && <Modal
          maskClosable={false}
          closable={false}
          title="Recorte a imagem se pretender"
          visible={openModal}
          confirmLoading={saving}
          onOk={onCropComplete}
          onCancel={onCropCancel}
          bodyStyle={{ padding: 0 }}
          width="600px">
          <CropperBox>
            {loading && <div className='backdrop'>
              <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
            </div>}
            <Cropper
              ref={cropper}
              viewMode={2}
              autoCropArea={1}
              aspectRatio={1}
              style={{ height: 400, width: '100%' }}
              guides={true}
              src={uploaded ? uploaded.preview : ''}
              ready={(evt) => {
                if (evt?.type === 'ready') toggleLoading(false);
              }}
            />
          </CropperBox>
        </Modal>}
      </React.Fragment>
    );
  };

  if (input.value) {
    const saved = input.value._id;
    const filename = TranslateValue(
      saved ? input.value.filename : input.value.blob.name
    );
    const size = (
      (saved ? input.value.length : input.value.size) /
      (1024 * 1024)
    ).toFixed(2);

    return (
      <React.Fragment>
        <ResponseFileSection>
          <ResponsePill single={false}>{filename}</ResponsePill>
          <ExtraPill>{size}MB</ExtraPill>
          <ExtraPill last={!saved}>
            {moment(input.value.createdAt).format('DD/MM/YYYY')}
          </ExtraPill>
          {saved && (
            <ExtraPill last link onClick={() => openFile(input.value._id)}>
              Abrir ficheiro
            </ExtraPill>
          )}
        </ResponseFileSection>
        {renderDropZone()}
      </React.Fragment>
    );
  }

  return renderDropZone();
};

export default ImageCard;
