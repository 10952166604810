import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Input, Popconfirm, Tooltip } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GenerateAccessCode, GetWedding, MngGuestAccess, NotifyWeddingGuest, UpdateCreateWeddingGuest } from '../../infra/requests/WeddingRequests';
import Alerts from '../../components/alert/Alert';
import { CodeInput, CodeP } from '../../process/ChoicesPage/SectionStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { bindActionCreators } from 'redux';
import { getWeddingFullName, getWeddingName } from '../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { RelationToCouple } from './CreateWeddingPage';
const { confirm } = Modal;

const GenerateCode = (
    <div>
        <div>Têm a certeza que pretende criar um novo código de acesso?</div>
        <div>Ao criar um novo código de acesso, o convidado deixará de ter acesso ao casamento e
            à informação do casal a partir do código anterior.</div>
    </div>
);

const NotifyGuest = (
    <div>
        <div>Têm a certeza que pretende notificar o convidado?</div>
        <div>Irá ser enviado o código de acesso ao casamento e/ou à informação do casal ao convidado.</div>
    </div>
);

const BlockAccess = (
    <div>
        <div>Têm a certeza que pretende bloquear o acesso do convidado?</div>
        <div>O utilizador convidado deixará de ter acesso ao casamento e/ou a toda a informação relacionada com o casal.</div>
    </div>
);

const UnblockAccess = (
    <div>
        <div>Têm a certeza que pretende desbloquear o acesso do convidado?</div>
        <div>O utilizador convidado poderá aceder ao casamento e/ou informação do casal, a partir do código de acesso que será criado.</div>
    </div>
);

export const WeddingName = styledComponents.p`
    font-size: 17px;
    margin-bottom: 0;
    
    span {
        color: ${p => p?.theme?.primaryColor};
    }
`;

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        guestname: 'required',
        guestemail: 'required|email',
        guestrelationcouple: 'required'
    })(values);

    return errors;
};

class AccessGuestModal extends React.Component {
    state = {
        loadingGuests: false,
        guests: [],
        loading: false,
        wedding: null,
        saving: false,
        mngAccess: false,
        blockGuestAccess: false,
        generatingCode: false,
        weddingAccessCode: '',
        sendingEmail: false
    }

    componentDidMount() {
        const { weddingId } = this.props;

        this.setState({
            loading: true,
            weddingId: weddingId
        }, () => this.getWedding());
    }

    getWedding = async () => {
        const { weddingId } = this.state;

        this.setState({ loading: true });
        const result = await GetWedding(weddingId);
        this.setState({
            wedding: result.data || null,
            blockGuestAccess: result?.data?.blockGuestAccess || false,
            weddingAccessCode: result?.data?.code || '',
        }, () => this.getWeddingGuests());
    }

    getWeddingGuests = async () => {
        const { dispatch } = this.props;
        const { wedding } = this.state;

        this.setState({ loadingGuests: true });
        const result = await GetWeddingGuests();
        let data = result?.success && result?.data ? result.data : [];
        if (Array.isArray(data) && data.length > 0) {
            data = data.map(m => ({ ...m, label: `${m?.name} (${m?.email})` }))
        }

        const selectedGuest = data.find(f => f?.email == wedding?.alternativeGuest?.email);
        dispatch(
            initialize('guest_form', {
                guest: selectedGuest?._id || null,
                guestexisted: selectedGuest ? true : false,
                guestname: wedding?.alternativeGuest?.name || '',
                guestemail: wedding?.alternativeGuest?.email || '',
                guestrelationcouple: wedding?.alternativeGuest?.relationToCouple || null,
                accessToConfirmedWedding: wedding?.accessToConfirmedWedding || false
            })
        );

        this.setState({
            loadingGuests: false,
            guests: data,
            loading: false
        });

    }

    onSubmit = async (values) => {
        const { dispatch } = this.props;
        const { guest, guestexisted, guestname, guestemail } = this.props;
        const { weddingId, guests, wedding } = this.state;
        // console.warn('values', values);

        this.setState({ saving: true });

        const dataInfo = {
            sendEmail: true,
            guest: values?.guest,
            guestExisted: values?.guestexisted,
            name: values?.guestname,
            email: values?.guestemail,
            relationToCouple: values?.guestrelationcouple,
            accessToConfirmedWedding: values?.accessToConfirmedWedding
        }

        const { success, data } = await UpdateCreateWeddingGuest(weddingId, dataInfo);

        if (success && data) {
            // Update only alternativeGuest on form
            const selectedGuest = guests.find(f => f?.email == data?.alternativeGuest?.email);
            dispatch(
                initialize('guest_form', {
                    guest: selectedGuest?._id || null,
                    guestexisted: selectedGuest ? true : false,
                    guestname: data?.alternativeGuest?.name || '',
                    guestemail: data?.alternativeGuest?.email || '',
                    guestrelationcouple: data?.alternativeGuest?.relationToCouple || null,
                    accessToConfirmedWedding: data?.accessToConfirmedWedding || false,
                })
            );
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Informação atualizada com sucesso!'
            });

            this.setState({
                saving: false,
                wedding: data || null,
                blockGuestAccess: data?.blockGuestAccess || false,
                weddingAccessCode: data?.code || '',
            });
        } else {
            this.setState({ saving: false });
        }
    }

    // Clear the fields when submit is successful
    clearFields = () => {
        const { dispatch, onClose } = this.props;
        dispatch(initialize('guest_form', {}, false));
        onClose();
    }

    confirmMngGuestAccess = () => {
        const { blockGuestAccess } = this.state;
        if (blockGuestAccess) {
            confirm({
                title: 'Tem a certeza que pretende enviar email ao convidado com o código de acesso?',
                content: 'Ao permitir o acesso do convidado à sua informação, este terá permissão para gerir o vosso casamento, as vossas reservas e datas em lista de espera.',
                okText: 'Desbloquear e Enviar',
                cancelText: 'Desbloquear',
                onOk: () => {
                    this.mngGuestAccess(true);
                },
                onCancel: () => {
                    this.mngGuestAccess();
                },
            });
        } else this.mngGuestAccess();
    }

    mngGuestAccess = async (sendEmail = false) => {
        const { weddingId } = this.state;
        this.setState({ mngAccess: true });
        let { data, success } = await MngGuestAccess(weddingId, { sendEmail });

        if (success && data) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: `Convidado ${data?.blockGuestAccess ? 'bloqueado' : 'desbloqueado'}!`
            });
            this.setState({
                mngAccess: false,
                blockGuestAccess: data?.blockGuestAccess || false,
                weddingAccessCode: data?.code || ''
            });
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: 'Erro ao gerir o acesso do convidado. Tente novamente!'
            });
            this.setState({ mngAccess: false });
        }
    }

    copyCode = () => {
        const { weddingAccessCode } = this.state;

        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(weddingAccessCode);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement('textarea');
            textArea.value = weddingAccessCode;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            document.execCommand('copy');
        }
        message.success('Texto copiado!');
    }

    notifyGuestCode = async () => {
        const { weddingId } = this.state;
        this.setState({ sendingEmail: true });
        let { data, success } = await NotifyWeddingGuest(weddingId);

        if (success && data) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Convidado notificado com sucesso!'
            });
            this.setState({ sendingEmail: false });
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: data || 'Erro genérico. Tente novamente!'
            });
            this.setState({ sendingEmail: false });
        }
    }

    confirmGenerateCode = () => {
        confirm({
            title: 'Tem a certeza que pretende enviar email ao convidado com o novo código de acesso?',
            content: 'Ao permitir o acesso do convidado à sua informação, este terá permissão para gerir o vosso casamento, as vossas reservas e datas em lista de espera.',
            okText: 'Criar e Enviar',
            cancelText: 'Criar',
            onOk: () => {
                this.generateCode(true);
            },
            onCancel: () => {
                this.generateCode();
            },
        });
    }

    generateCode = async (sendEmail = false) => {
        const { weddingId } = this.state;
        this.setState({ generatingCode: true });
        let { data, success } = await GenerateAccessCode(weddingId, { sendEmail });

        if (success && data) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: `Criado novo código de acesso. ${sendEmail ? 'Convidado notificado com sucesso!' : ''}`
            });
            this.setState({
                generatingCode: false,
                weddingAccessCode: data?.code || ''
            });
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: 'Erro ao criar novo código de acesso. Tente novamente!'
            });
            this.setState({ generatingCode: false });
        }
    }

    render() {
        const { isOpen, guest } = this.props;
        const { handleSubmit, onClose, invalid, change, submitFailed } = this.props;
        const { loadingGuests, loading } = this.state;
        const { wedding, guests, blockGuestAccess, weddingAccessCode, generatingCode, sendingEmail, mngAccess } = this.state;

        return (
            <Modal
                visible={isOpen}
                title='Alterar ou Criar Utilizador Convidado'
                maskClosable
                onCancel={onClose}
                footer={
                    [
                        <Button
                            key='cancel'
                            type='default'
                            onClick={onClose}>
                            Fechar
                        </Button>
                    ]} >

                {
                    loadingGuests || loading
                        ? <SpinLoading />
                        : <BaseForm onSubmit={handleSubmit(this.onSubmit)} >
                            <WeddingName>
                                <b>Noivos: </b>
                                <span>{getWeddingFullName(wedding)}</span>
                            </WeddingName>
                            <Field
                                component={SelectInput}
                                name={'guest'}
                                label={'Utilizador Convidado'}
                                placeholder={'Selecione o convidado'}
                                allowClear={true}
                                type='select'
                                data={guests || []}
                                dataLabel='label'
                                onChange={(e) => {
                                    change('guest', e);
                                    change('guestexisted', e && e !== '' ? true : false);
                                    change('guestrelationcouple', null);
                                    if (e && e !== '') {
                                        const guestSelected = guests.find(f => f?._id == e);
                                        change('guestname', guestSelected?.name || '');
                                        change('guestemail', guestSelected?.email || '');
                                    } else {
                                        change('guestname', '');
                                        change('guestemail', '');
                                    }
                                }}
                            />

                            {/* <Field
                        component={CheckboxInput}
                        name={'guestexisted'}
                        label={'Utilizador já existe'}
                    /> */}

                            <Field
                                component={TextInput}
                                name={'guestname'}
                                type="text"
                                label={'Nome do Convidado'}
                                placeholder={'Insira o nome convidado'}
                            />
                            <Field
                                component={TextInput}
                                name={'guestemail'}
                                type="email"
                                label={'Email do Convidado'}
                                placeholder={'Email de acesso convidado'}
                                disabled={guest && guest !== '' ? true : false}
                            />
                            <Field
                                component={SelectInput}
                                name={'guestrelationcouple'}
                                label={'Relação do convidado com o casal'}
                                dataKey={'value'}
                                dataLabel={'name'}
                                data={[
                                    { value: RelationToCouple.THIRD_USER, name: '3º email do casal' },
                                    { value: RelationToCouple.FAMILIAR_FRIEND, name: 'Familiar e/ou amigo' },
                                    { value: RelationToCouple.WEDDING_PLANNER, name: 'Wedding Planner' },
                                ]}
                            />
                            <Field
                                component={CheckboxInput}
                                name={'accessToConfirmedWedding'}
                                label={'Permitir que o contacto convidado aceda ao casamento após confirmação?'}
                                positiveLabel='Sim'
                            />
                            <BaseButton
                                type="primary"
                                icon="save"
                                text="Gravar alterações"
                                onClick={handleSubmit(this.onSubmit)}
                                style={{ float: 'none', marginTop: 10, marginBottom: 15 }}
                            />

                            <CodeP>Ao permitir o acesso do convidado à sua informação, este terá permissão para gerir o vosso casamento, as vossas reservas e datas em lista de espera.</CodeP>
                            <Popconfirm
                                title={blockGuestAccess ? UnblockAccess : BlockAccess}
                                onConfirm={() => this.confirmMngGuestAccess()}
                                okText={blockGuestAccess ? 'Desbloquear' : 'Bloquear'}
                                okType={blockGuestAccess ? 'primary' : 'danger'}
                                cancelText="Cancelar">
                                <BaseButton
                                    align="initial"
                                    htmlType="button"
                                    type={blockGuestAccess ? 'default' : 'danger'}
                                    style={{ marginBottom: 15 }}
                                    loading={mngAccess}
                                    text={`${blockGuestAccess ? 'Desbloquear' : 'Bloquear'} Acesso do Convidado`}
                                />
                            </Popconfirm>

                            {!blockGuestAccess &&
                                <CodeInput>
                                    <Input value={weddingAccessCode} disabled={true} />
                                    <Popconfirm
                                        title={GenerateCode}
                                        onConfirm={this.confirmGenerateCode}
                                        okText="Criar"
                                        cancelText="Cancelar">
                                        <BaseButton
                                            type='default'
                                            icon={generatingCode ? 'loading' : 'reload'}
                                            style={{ borderRadius: 0 }}
                                        />
                                    </Popconfirm>
                                    <Tooltip title="Copiar código">
                                        <BaseButton
                                            type='default'
                                            icon='copy'
                                            style={{ borderRadius: 0 }}
                                            onClick={() => this.copyCode()}
                                        />
                                    </Tooltip>
                                    <Popconfirm
                                        title={NotifyGuest}
                                        onConfirm={this.notifyGuestCode}
                                        okText="Notificar"
                                        cancelText="Cancelar">
                                        <BaseButton
                                            type='default'
                                            icon={sendingEmail ? 'loading' : 'mail'}
                                            style={{ borderRadius: '0 4px 4px 0' }}
                                        />
                                    </Popconfirm>
                                </CodeInput>}
                        </BaseForm >}
            </Modal >
        );
    }
};

AccessGuestModal = reduxForm({
    form: 'guest_form',
    // enableReinitialize: true,
    validate: validations,
})(AccessGuestModal);

const selector = formValueSelector('guest_form');

const mapStateToProps = state => ({
    guest: selector(state, 'guest'),
    guestexisted: selector(state, 'guestexisted'),
    guestname: selector(state, 'guestname'),
    guestemail: selector(state, 'guestemail'),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ change }, dispatch);
}

AccessGuestModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessGuestModal)