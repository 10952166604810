export const getCountryOptions = () => {
    return [
        { value: 'AF' },
        { value: 'AX' },
        { value: 'AL' },
        { value: 'DZ' },
        { value: 'AS' },
        { value: 'AD' },
        { value: 'AO' },
        { value: 'AI' },
        { value: 'AQ' },
        { value: 'AG' },
        { value: 'AR' },
        { value: 'AM' },
        { value: 'AW' },
        { value: 'AU' },
        { value: 'AT' },
        { value: 'AZ' },
        { value: 'BS' },
        { value: 'BH' },
        { value: 'BD' },
        { value: 'BB' },
        { value: 'BY' },
        { value: 'BE' },
        { value: 'BZ' },
        { value: 'BJ' },
        { value: 'BM' },
        { value: 'BT' },
        { value: 'BO' },
        { value: 'BA' },
        { value: 'BW' },
        { value: 'BV' },
        { value: 'BR' },
        { value: 'IO' },
        { value: 'BN' },
        { value: 'BG' },
        { value: 'BF' },
        { value: 'BI' },
        { value: 'KH' },
        { value: 'CM' },
        { value: 'CA' },
        { value: 'CV' },
        { value: 'KY' },
        { value: 'CF' },
        { value: 'TD' },
        { value: 'CL' },
        { value: 'CN' },
        { value: 'CX' },
        { value: 'CC' },
        { value: 'CO' },
        { value: 'KM' },
        { value: 'CG' },
        { value: 'CD' },
        { value: 'CK' },
        { value: 'CR' },
        { value: 'CI' },
        { value: 'HR' },
        { value: 'CU' },
        { value: 'CY' },
        { value: 'CZ' },
        { value: 'DK' },
        { value: 'DJ' },
        { value: 'DM' },
        { value: 'DO' },
        { value: 'EC' },
        { value: 'EG' },
        { value: 'SV' },
        { value: 'GQ' },
        { value: 'ER' },
        { value: 'EE' },
        { value: 'ET' },
        { value: 'FK' },
        { value: 'FO' },
        { value: 'FJ' },
        { value: 'FI' },
        { value: 'FR' },
        { value: 'GF' },
        { value: 'PF' },
        { value: 'TF' },
        { value: 'GA' },
        { value: 'GM' },
        { value: 'GE' },
        { value: 'DE' },
        { value: 'GH' },
        { value: 'GI' },
        { value: 'GR' },
        { value: 'GL' },
        { value: 'GD' },
        { value: 'GP' },
        { value: 'GU' },
        { value: 'GT' },
        { value: 'GG' },
        { value: 'GN' },
        { value: 'GW' },
        { value: 'GY' },
        { value: 'HT' },
        { value: 'HM' },
        { value: 'VA' },
        { value: 'HN' },
        { value: 'HK' },
        { value: 'HU' },
        { value: 'IS' },
        { value: 'IN' },
        { value: 'ID' },
        { value: 'IR' },
        { value: 'IQ' },
        { value: 'IE' },
        { value: 'IM' },
        { value: 'IL' },
        { value: 'IT' },
        { value: 'JM' },
        { value: 'JP' },
        { value: 'JE' },
        { value: 'JO' },
        { value: 'KZ' },
        { value: 'KE' },
        { value: 'KI' },
        { value: 'KP' },
        { value: 'KR' },
        { value: 'KW' },
        { value: 'KG' },
        { value: 'LA' },
        { value: 'LV' },
        { value: 'LB' },
        { value: 'LS' },
        { value: 'LR' },
        { value: 'LY' },
        { value: 'LI' },
        { value: 'LT' },
        { value: 'LU' },
        { value: 'MO' },
        { value: 'MK' },
        { value: 'MG' },
        { value: 'MW' },
        { value: 'MY' },
        { value: 'MV' },
        { value: 'ML' },
        { value: 'MT' },
        { value: 'MH' },
        { value: 'MQ' },
        { value: 'MR' },
        { value: 'MU' },
        { value: 'YT' },
        { value: 'MX' },
        { value: 'FM' },
        { value: 'MD' },
        { value: 'MC' },
        { value: 'MN' },
        { value: 'MS' },
        { value: 'MA' },
        { value: 'MZ' },
        { value: 'MM' },
        { value: 'NA' },
        { value: 'NR' },
        { value: 'NP' },
        { value: 'NL' },
        { value: 'AN' },
        { value: 'NC' },
        { value: 'NZ' },
        { value: 'NI' },
        { value: 'NE' },
        { value: 'NG' },
        { value: 'NU' },
        { value: 'NF' },
        { value: 'MP' },
        { value: 'NO' },
        { value: 'OM' },
        { value: 'PK' },
        { value: 'PW' },
        { value: 'PS' },
        { value: 'PA' },
        { value: 'PG' },
        { value: 'PY' },
        { value: 'PE' },
        { value: 'PH' },
        { value: 'PN' },
        { value: 'PL' },
        { value: 'PT' },
        { value: 'PR' },
        { value: 'QA' },
        { value: 'RE' },
        { value: 'RO' },
        { value: 'RU' },
        { value: 'RW' },
        { value: 'SH' },
        { value: 'KN' },
        { value: 'LC' },
        { value: 'PM' },
        { value: 'VC' },
        { value: 'WS' },
        { value: 'SM' },
        { value: 'ST' },
        { value: 'SA' },
        { value: 'SN' },
        { value: 'CS' },
        { value: 'SC' },
        { value: 'SL' },
        { value: 'SG' },
        { value: 'SK' },
        { value: 'SI' },
        { value: 'SB' },
        { value: 'SO' },
        { value: 'ZA' },
        { value: 'GS' },
        { value: 'ES' },
        { value: 'LK' },
        { value: 'SD' },
        { value: 'SR' },
        { value: 'SJ' },
        { value: 'SZ' },
        { value: 'SE' },
        { value: 'CH' },
        { value: 'SY' },
        { value: 'TW' },
        { value: 'TJ' },
        { value: 'TZ' },
        { value: 'TH' },
        { value: 'TL' },
        { value: 'TG' },
        { value: 'TK' },
        { value: 'TO' },
        { value: 'TT' },
        { value: 'TN' },
        { value: 'TR' },
        { value: 'TM' },
        { value: 'TC' },
        { value: 'TV' },
        { value: 'UG' },
        { value: 'UA' },
        { value: 'AE' },
        { value: 'GB' },
        { value: 'US' },
        { value: 'UM' },
        { value: 'UY' },
        { value: 'UZ' },
        { value: 'VU' },
        { value: 'VE' },
        { value: 'VN' },
        { value: 'VG' },
        { value: 'VI' },
        { value: 'WF' },
        { value: 'EH' },
        { value: 'YE' },
        { value: 'ZM' },
        { value: 'ZW' }
    ];
}

export const getCountryTranslation = (country) => {
    const translateCountry = new Intl.DisplayNames(['pt'], { type: 'region' });
    return translateCountry.of(country?.value);
}
