import React from 'react';
import { scroller } from 'react-scroll';
import { Tree, Icon } from 'antd';
import { SectionMenuStyle } from '../ChoicesPage/SectionStyles';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { InfoColor, PrimaryColour, SuccessColor } from '../../styles/Colours';

const { TreeNode } = Tree;

const defineIcon = question => {
  if (question.type === 9) {
    return <Icon style={{ color: InfoColor }} type="info-circle" />;
  }
  if (question?.answer?.response) {
    return <Icon style={{ color: SuccessColor }} type="check-circle" />;
  }
  return <Icon style={{ color: PrimaryColour }} type="close-circle" />;
};

const AboutMenu = ({ printed, section = { chapters: [] }, wedding }) => {
  const showGuest = wedding?.alternativeGuest && wedding?.alternativeGuest?.email && wedding?.alternativeGuest?.email !== '' ? true : false;

  const scrollTo = selected => {
    if (selected && selected[0]) {
      return scroller.scrollTo(selected[0], {
        duration: 500,
        smooth: true,
        containerId: 'section-container'
      });
    }
  };

  return (
    <SectionMenuStyle printed={printed}>
      <Tree
        showLine={false}
        defaultExpandAll
        blockNode
        showIcon
        autoExpandParent
        onSelect={scrollTo}>
        <TreeNode
          title="Sobre nós"
          key="about_us"
          icon={item => <Icon type="folder-open" />}></TreeNode>
        <TreeNode
          className={!showGuest ? 'hide-guest' : ''}
          title="Acesso do Convidado"
          key="guestAccess"
          icon={item => <Icon type="folder-open" />}></TreeNode>
        {/* <TreeNode
          title="Listagem Dados Faturação"
          key="billing"
          icon={item => <Icon type="folder-open" />}></TreeNode> */}
        {section.chapters.map((chapter, cIndex) => (
          <TreeNode
            title={chapter._id === '5e735eeb29196b814fb983b6' ? 'Informação de pagamentos' : TranslateValue(chapter.name)}
            key={`anchor-${cIndex}`}
            icon={item => (
              <Icon type={item.expanded ? 'folder-open' : 'folder'} />
            )}>
            {chapter.questions.map((question, qIndex) => (
              <TreeNode
                icon={defineIcon(question)}
                title={TranslateValue(question.title)}
                key={`anchor-${cIndex}-${qIndex}`}
              />
            ))}
          </TreeNode>
        ))}
        <TreeNode
          title="Documentos"
          key="attachments"
          icon={item => <Icon type="folder-open" />}></TreeNode>
      </Tree>
    </SectionMenuStyle>
  );
};

export default AboutMenu;
