import React, {useState, useEffect, useRef} from 'react';
import {
  BaseForm,
  HeaderButtonsContainer,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  SpinLoading,
  FormContainer
} from '../../styles/BasicStyles';
import {ConfirmReserve, GetReserve, GetReserveInfo} from '../../infra/requests/ReserveRequests';
import moment from 'moment';
import Alerts from '../../components/alert/Alert';
import {Title} from '../reservesAttending/ReserveInfoModalStyles';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import {connect} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import {Field, FormSection, formValueSelector, initialize, reduxForm} from 'redux-form';
import SelectLanguageInput from '../../components/inputs/SelectLanguageInput';
import {ReserveInfo, ReserveText} from './ReserveStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import {isValidPhoneNumber} from 'react-phone-number-input';
import BaseButton from '../../components/buttons/BaseButton';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import FilesInput from '../../components/inputs/FilesInput';
import {Col, Row} from 'antd';
import {device} from '../../styles/Responsive';
import styled from 'styled-components';
import SelectInput from '../../components/inputs/SelectInput';
import {
  weddingContractBrideFields,
  weddingContractGroomFields
} from './WeddingContractPageFormFields';
import { AddCoupleIdFiles, RemoveCoupleIdFiles } from '../../infra/requests/WeddingRequests';
import { WeddingStatus } from './ReserveAgenda';

export const WeddingContractTypesEnum = {
  EMAIL_BANK: 'EMAIL_BANK',
  PRESENCIAL_MONEY: 'PRESENCIAL_MONEY'
};

export const weddingContractTypes = [
  {
    _id: WeddingContractTypesEnum.EMAIL_BANK,
    name: 'Contrato por email com pagamento por transferência bancária'
  },
  {
    _id: WeddingContractTypesEnum.PRESENCIAL_MONEY,
    name: 'Contrato presencial com pagamento em numerário ou cheque'
  }
];

export const SectionSubtitle = styled.div`
  text-align: left;
  padding-top: 15px;
  white-space: pre-line;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  margin-top: 40px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

const validations = values => {
  let errors = {};

  errors.bride = FormValidator.make({
    fullName: 'required',
    nationality: 'required',
    streetDoorNumber: 'required',
    zipCode: 'required',
    street: 'required',
    country: 'required',
    personalEmail: 'required|email',
    personalPhone: 'required'
  })(values?.bride);

  errors.groom = FormValidator.make({
    fullName: 'required',
    nationality: 'required',
    streetDoorNumber: 'required',
    zipCode: 'required',
    street: 'required',
    country: 'required',
    personalEmail: 'required|email',
    personalPhone: 'required'
  })(values?.groom);

  if (
    values?.bride?.personalPhone &&
    !isValidPhoneNumber(values?.bride?.personalPhone)
  ) {
    errors.bride.personalPhone = 'INVALID_PHONE_NUMBER_CONTRACT';
  }

  if (
    values?.groom?.personalPhone &&
    !isValidPhoneNumber(values?.groom?.personalPhone)
  ) {
    errors.groom.personalPhone = 'INVALID_PHONE_NUMBER_CONTRACT';
  }

  const bridePersonalEmail = String(values?.bride?.personalEmail)
    ?.toLowerCase()
    ?.trim();
  const groomPersonalEmail = String(values?.groom?.personalEmail)
    ?.toLowerCase()
    ?.trim();
  if (
    values?.bride?.personalEmail &&
    values?.groom?.personalEmail &&
    bridePersonalEmail === groomPersonalEmail
  ) {
    errors.bride.personalEmail = 'SAME_EMAIL_CONTRACT';
    errors.groom.personalEmail = 'SAME_EMAIL_CONTRACT';
  }

  const bridePersonalPhone = String(values?.bride?.personalPhone)
    ?.toLowerCase()
    ?.trim();
  const groomPersonalPhone = String(values?.groom?.personalPhone)
    ?.toLowerCase()
    ?.trim();
  if (
    values?.bride?.personalPhone &&
    values?.groom?.personalPhone &&
    bridePersonalPhone === groomPersonalPhone
  ) {
    errors.bride.personalPhone = 'SAME_PHONE_NUMBER_CONTRACT';
    errors.groom.personalPhone = 'SAME_PHONE_NUMBER_CONTRACT';
  }

  const brideIdentificationNumber = String(values?.bride?.identificationNumber)
  ?.toLowerCase()
  ?.trim();
  const groomIdentificationNumber = String(values?.groom?.identificationNumber)
  ?.toLowerCase()
  ?.trim();
  if (
    values?.bride?.identificationNumber &&
    values?.groom?.identificationNumber &&
    brideIdentificationNumber === groomIdentificationNumber
  ) {
    errors.bride.identificationNumber = 'SAME_IDENTIFICATION_NUMBER_CONTRACT';
    errors.groom.identificationNumber = 'SAME_IDENTIFICATION_NUMBER_CONTRACT';
  }

  const brideIdentification = String(values?.bride?.identification)
  ?.toLowerCase()
  ?.trim();
  const groomIdentification = String(values?.groom?.identification)
  ?.toLowerCase()
  ?.trim();
  if (
    values?.bride?.identification &&
    values?.groom?.identification &&
    brideIdentification === groomIdentification
  ) {
    errors.bride.identification = 'SAME_IDENTIFICATION_CONTRACT';
    errors.groom.identification = 'SAME_IDENTIFICATION_CONTRACT';
  }


  const errorsGeneral = FormValidator.make({
    contractLanguage: 'required',
    contractType: 'required',
    trueData: 'required',
    coupleIdFiles: 'required|max:2|min:1'
  })(values);

  if (values?.coupleIdFiles?.length === 0) {
    errorsGeneral['coupleIdFiles'] = 'INPUT_ERROR_MISSING_COUPLES_FILES';
  }

  errors = {...errors, ...errorsGeneral};

  return errors;
};

class WeddingContractPage extends React.Component {
  state = {
    wedding: {},
    ready: false,
    loading: false,
    reserve: null,
    savingButton: false
  };

  componentDidMount() {
    const {
      initialValues,
      history,
      match: {params}
    } = this.props;

    if (!params?.id) {
      return history.goBack();
    }

    document.body.style.overflowY = 'auto';
    window.scroll({top: 0, left: 0, behavior: 'smooth'});

    this.getReserve();
  }

  onSubmit = async values => {
    const {history} = this.props;
    const {reserve} = this.state;
    const result = await ConfirmReserve(reserve?._id, values);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Reserva Confirmada'
      });
      return history.push('/reserves');
    } else {
      if (
        result?.data === 'ERROR_DATE_OCCUPIED' ||
        result?.data === 'ERROR_CANT_CONFIRM' ||
        result?.data === 'ERROR_DATE_UNAVAILABLE'
      ) {
        const message =
          result?.data === 'ERROR_DATE_OCCUPIED'
            ? 'Não é possivel realizar a operação pois já existe uma data ocupada ou em sinalização.'
            : result?.data === 'ERROR_CANT_CONFIRM'
            ? 'Função indisponível, reserva eliminada/inativa ou não se encontra em 1º lugar.'
            : result?.data === 'ERROR_DATE_UNAVAILABLE'
            ? 'Função indisponível, data indisponível para reserva.'
            : '';
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
      } else {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: result?.data
        });
      }
    }
  };

  getReserve = async () => {
    const {
      match: {params},
      history, dispatch
    } = this.props;

    const result = await GetReserveInfo(params.id);

    if (result?.success) {
      const now = moment.utc();
      const reserveIsActive = now.isSameOrBefore(
        moment.utc(result?.data.endActivationDate)
      );

      if (reserveIsActive && result.data.wedding.status == WeddingStatus.RESERVE) {
        dispatch(
          initialize('wedding_contract_form', {
            ...result?.data?.wedding,
            validateThirdEmail: result?.data?.wedding?.validateThirdEmail || false,
            trueData: false
          })
        );
        this.setState({
          reserve: result?.data,
          ready: true
        });
      } else {
        return history.goBack();
      }
    } else {
      return history.goBack();
    }
  };

  translateContractTypes = contractType => {
    const {translate} = this.props;
    return contractType.name;
  };

  handleFileAdd = async file => {
    const payload = new FormData();
    payload.append('image', file);
    const {reserve} = this.state;
    return await AddCoupleIdFiles(reserve.wedding._id, payload);
  };

  handleFileDelete = async fileId => {
    const {reserve} = this.state;
    return await RemoveCoupleIdFiles(reserve.wedding._id, fileId);
  };

  getPax = () => {
    const {reserve} = this.state;
    const now = moment.utc();
    const reserveIsActive = now.isSameOrBefore(
      moment.utc(reserve.endActivationDate)
    );
    return reserveIsActive ? reserve.priceObj.minPax : reserve.price.minPax;
  };

  getPrice = () => {
    const {reserve} = this.state;
    const now = moment.utc();
    const reserveIsActive = now.isSameOrBefore(
      moment.utc(reserve.endActivationDate)
    );
    return reserveIsActive ? reserve.priceObj.minPrice : reserve.price.minPrice;
  };

  getTotal = () => {
    const {reserve} = this.state;
    const now = moment.utc();
    const reserveIsActive = now.isSameOrBefore(
      moment.utc(reserve.endActivationDate)
    );
    const price = reserveIsActive
      ? reserve.priceObj?.minPrice
      : reserve.price?.minPrice;
    const pax = reserveIsActive
      ? reserve.priceObj?.minPax
      : reserve.price?.minPax;

    return Number((price * pax).toFixed(2));
  };

  render() {
    const {translate, activeLanguage} = this.props;
    const {handleSubmit, submitFailed, invalid, dirty, submitting} = this.props;
    const {loading, ready} = this.state;
    const {wedding, trueData, initialValues} = this.props;
    const {reserve} = this.state;
    const {savingButton} = this.state;
     if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Reserva a confirmar</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              disabled={!trueData}
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
              loading={savingButton}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Title style={{marginBottom: 10}}>Informações da reserva</Title>
            <ReserveInfo>
              <ReserveText>
                <b>Data:</b>
                {moment.utc(reserve?.date).format('DD/MM/YYYY')}
              </ReserveText>
              <ReserveText>
                <b>Local:</b>
                {reserve?.weddingPlace == 2
                  ? 'Quinta Lago dos Cisnes'
                  : 'Solar da Levada'}
              </ReserveText>
              <ReserveText>
                <b>PAX:</b>
                {this.getPax()}
              </ReserveText>
              <ReserveText>
                <b>Preço:</b>
                <CurrencyComponent value={this.getPrice()} />
              </ReserveText>
              <ReserveText>
                <b>Preço mínimo global:</b>
                <CurrencyComponent value={this.getTotal()} />
              </ReserveText>
            </ReserveInfo>

            <Field
              component={SelectLanguageInput}
              name={'contractLanguage'}
              label="Idioma de redação do contrato *"
              placeholder="Idioma de redação do contrato *"
            />

            <Field
              component={SelectInput}
              name={'contractType'}
              data={weddingContractTypes}
              type={'select'}
              costumeLabel={this.translateContractTypes}
              label="Tipo de contrato *"
              placeholder="Tipo de contrato *"
            />

            <Row gutter={[12, 12]}>
              <FormSection name="bride">
                <SectionSubtitle>
                  Dados para contrato noivo/noiva
                </SectionSubtitle>
                {weddingContractBrideFields.map((field, key) => (
                  <Col
                    key={key}
                    xs={field?.xs || 24}
                    sm={field?.sm || 24}
                    md={field?.md || 24}
                    lg={field?.lg || 24}
                    xl={field?.xl || 24}>
                    <Field
                      key={field.name}
                      component={field.component}
                      name={field.name}
                      type={field.type}
                      data={field?.dataOptions}
                      dataKey={field?.dataKey}
                      costumeLabel={country =>
                        field?.costumeLabel(activeLanguage, country)
                      }
                      label={field.asterisc ? `${field.label} *` : field.label}
                    />
                  </Col>
                ))}
              </FormSection>
            </Row>

            <Row gutter={[12, 12]}>
              <FormSection name="groom">
                <SectionSubtitle>
                  Dados para contrato noivo/noiva
                </SectionSubtitle>
                {weddingContractGroomFields.map((field, key) => (
                  <Col
                    key={key}
                    xs={field?.xs || 24}
                    sm={field?.sm || 24}
                    md={field?.md || 24}
                    lg={field?.lg || 24}
                    xl={field?.xl || 24}>
                    <Field
                      key={field.name}
                      component={field.component}
                      name={field.name}
                      type={field.type}
                      data={field?.dataOptions}
                      dataKey={field?.dataKey}
                      costumeLabel={country =>
                        field?.costumeLabel(activeLanguage, country)
                      }
                      label={field.asterisc ? `${field.label} *` : field.label}
                    />
                  </Col>
                ))}
              </FormSection>
            </Row>

            <Field
              component={FilesInput}
              name={'coupleIdFiles'}
              label="Cartão de cidadão ou passaporte *"
              handleDelete={this.handleFileDelete}
              handleAdd={this.handleFileAdd}
              maxFiles={2}
              accept='application/pdf'
            />

            {reserve?.wedding?.alternativeGuest &&
              reserve?.wedding?.alternativeGuest?.email?.trim() !== '' && (
                <Field
                  component={CheckboxInput}
                  name={'validateThirdEmail'}
                  label="Adicionar 3º email ao contrato"
                />
              )}

            <Field
              component={CheckboxInput}
              name={'trueData'}
              label="Confirmo o fornecimento dos dados verídicos e atualizados"
            />
            {/* {trueData && (
              <SaveButton
                htmlType="submit"
                error={submitFailed && invalid}
                text={translate('SAVE')}
                textMobile={translate('SAVE')}
                // onClick={() => this.setState({ goToNextPage: true })}
                loading={loading}
              />
            )} */}
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('wedding_contract_form');

WeddingContractPage = reduxForm({
  form: 'wedding_contract_form',
  validate: validations
})(WeddingContractPage);


const mapStateToProps = state => ({
  trueData: selector(state, 'trueData')
});

export default withLocalize(
  connect(mapStateToProps)(WeddingContractPage)
);
