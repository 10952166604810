import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import {
  getCountryOptions,
  getCountryTranslation
} from '../../infra/services/utils/Countries';

export const weddingContractBrideFields = [
  {
    name: 'fullName',
    type: 'text',
    label: 'Nome completo',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'nationality',
    type: 'text',
    label: 'Nacionalidade',
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: 'identification',
    type: 'text',
    label: 'Número do cartão de cidadão',
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: 'identificationNumber',
    type: 'text',
    label: 'Número de identificação fiscal - NIF',
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: 'streetDoorNumber',
    type: 'text',
    label: 'Rua e número da porta - de residência',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'zipCode',
    type: 'text',
    label: 'Código Postal - de residência',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'street',
    type: 'text',
    label: 'Freguesia e Concelho - de residência',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'country',
    type: 'text',
    label: 'País de residência',
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: 'personalEmail',
    type: 'email',
    label: 'Endereço de email pessoal',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'personalPhone',
    type: 'tel',
    label: 'Contacto de telefone pessoal',
    component: TextInput,
    disabled: false,
    asterisc: true
  }
];

export const weddingContractGroomFields = [
  {
    name: 'fullName',
    type: 'text',
    label: 'Nome completo',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'nationality',
    type: 'text',
    label: 'Nacionalidade',
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: 'identification',
    type: 'text',
    label: 'Número do cartão de cidadão',
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: 'identificationNumber',
    type: 'text',
    label: 'Número de identificação fiscal - NIF',
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: 'streetDoorNumber',
    type: 'text',
    label: 'Rua e número da porta - de residência',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'zipCode',
    type: 'text',
    label: 'Código Postal - de residência',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'street',
    type: 'text',
    label: 'Freguesia e Concelho - de residência',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'country',
    type: 'text',
    label: 'País de residência',
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: 'personalEmail',
    type: 'email',
    label: 'Endereço de email pessoal',
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: 'personalPhone',
    type: 'tel',
    label: 'Contacto de telefone pessoal',
    component: TextInput,
    disabled: false,
    asterisc: true
  }
];
