import React, { Component } from "react";
import moment from "moment";
import Table from "../../components/table/Table";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from "../../styles/BasicStyles";
import { GetAllAppointments, GetBookingForChart, GetBookingForLineChart, GetBookingStats } from "../../infra/requests/AppointmentsRequests";
import BaseGraph from "../../components/graphs/BaseGraph";
import RadioGroup from "antd/lib/radio/group";
import { Col, Icon, Radio, Row, Statistic } from "antd";
import styledComponents from 'styled-components';
import LineGraph from "../../components/graphs/LineGraph";
import styled, { createGlobalStyle } from 'styled-components';
import { getLocalColor } from "../../infra/services/wedding/weddingUtils";
import { LocalLabel } from "../../weddingday/weddings/Styles";
import { GetTextColor } from "../../infra/services/text/textUtils";
import { GetColorSystemByType } from "../../infra/requests/ColorSystemRequests";
import { PrimaryColour } from "../../styles/Colours";

export const ChartTypes = {
  'YEAR': 'YEAR',
  'LANGUAGE': 'LANGUAGE',
  'WEBSITE': 'WEBSITE'
}

const ChartTypesOptions = [
  { value: ChartTypes.YEAR, name: 'Contactos por Ano', chart: 1 },
  { value: ChartTypes.LANGUAGE, name: 'Contactos por Idioma', chart: 3 },
  { value: ChartTypes.WEBSITE, name: 'Contactos por Website', chart: 3 },
]

export const GraphsContainer = styledComponents.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const RowRadio = styledComponents.div`
  display: flex;
  flex-direction: row;
`;

const ChartContent = styledComponents.div`
  display: flex;
  flex-direction: column;
`;

const ChartFilter = styledComponents.div`
  font-weight: bold;
  text-align: start;
  margin-bottom: 5px;
`;

const Stats = styledComponents.div`
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-radius: 4px;
  padding: 5px;

  .anticon {
    color: ${({ theme }) => theme.primaryColor};
    margin-right: 5px;
    font-size: 20px;
  }

  .title {
    font-size: 15px;
    color: ${({ theme }) => theme.secondaryColor};
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }

  .text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-end;
    justify-content: center;
  }
`;

const StatsLabel = styledComponents.div`
  font-size: 15px;
  font-weight: 700;
  margin-right: 5px;
`;

const StatsValue = styledComponents.div`
  font-size: ${({ hasLabel }) => hasLabel ? '12px' : '15px'};
  ${({ hasLabel }) => hasLabel ? '' : 'font-weight: 700;'}

  ::before {
    content: ${({ hasLabel }) => hasLabel ? '"("' : ''};
  }

  ::after {
    content: ${({ hasLabel }) => hasLabel ? '")"' : ''};
  }
`;

class AppointmentsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    columns: [
      {
        title: "Name",
        dataIndex: "name"
      },
      {
        title: "Email",
        dataIndex: "email"
      },
      {
        title: "Contacto",
        dataIndex: "mobile_phone"
      },
      {
        title: "Idioma Comunicação",
        dataIndex: "language",
        render: value => value === 'en' ? 'Inglês' : value === 'pt' ? 'Português' : ''
      },
      // {
      //   title: "Local",
      //   dataIndex: "local"
      // },
      // {
      //   title: "Horário",
      //   dataIndex: "book_time"
      // },
      // {
      //   title: "Ano",
      //   dataIndex: "year",
      //   render: value => value
      // },
      {
        title: "Observações",
        dataIndex: "observations"
      },
      {
        title: "Dados Antigos",
        render: value => {
          const local = value?.local || null;
          const bookTime = value?.book_time || null;
          const weddingYear = value?.year || null;
          let text = ``;
          if (local) text = `${text}<span>Pretende marcar visita para ${local?.toLowerCase()}</span>`;
          if (bookTime) text = `${text}<span>Podem marcar ${bookTime?.toLowerCase()}</span>`;
          if (weddingYear) text = `${text}<span>Ano do casamento ${weddingYear}</span>`;
          return <div
            style={{ display: 'flex', flexDirection: 'column' }}
            dangerouslySetInnerHTML={{
              __html: text
            }}></div>
        }
      },
      {
        title: "Website",
        width: '60px',
        render: value => {
          const local = getLocalColor(value, this.state.colors, value?.website);
          return local
            ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
            : null
        }
      },
      {
        title: "requested at",
        dataIndex: "createdAt",
        render: value => moment(value).format("HH:mm, DD/MM/YYYY")
      }
    ],
    rows: [],
    total: 0,

    colors: {
      colorSys1: null,
      colorSys2: null
    },

    stats: [],

    loadingCharts: false,
    chart1: {
      loading: false,
      type: ChartTypes.YEAR,
      title: '',
      data: [],
      total: 0
    },
    chart2: {
      loading: false,
      type: ChartTypes.WEBSITE,
      title: '',
      data: [],
      total: 0,
      colors: [],
      legend: [],
    },
  };

  componentDidMount = async () => {
    await this.updateDataTable();
    await this.getLocalColors();
    await this.loadStats();
    await this.loadCharts();
  };

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;
      const result = await GetAllAppointments(currentPage, pageSize);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  loadStats = async () => {
    const result = await GetBookingStats();
    // console.warn('Res', result);

    this.setState({ stats: result.success && result.data ? result.data : [] });
  }

  loadCharts = async () => {
    this.setState({ loadingCharts: true });

    try {
      await this.loadChart1();
      await this.loadChart2();
      this.setState({ loadingCharts: false });
    } catch (e) {
      this.setState({ loadingCharts: false });
      console.error(e);
    }
  }

  loadChart1 = async () => {
    const { chart1 } = this.state;
    this.setState({ chart1: { ...chart1, loading: true } });

    try {
      const resultChart1 = await GetBookingForChart({ type: chart1.type });
      // console.warn('resultChart1', resultChart1);
      const dataChart1 = resultChart1.success && resultChart1.data ? resultChart1.data : [];
      const title = chart1.type === ChartTypes.YEAR
        ? 'Contactos por Ano'
        : chart1.type === ChartTypes.LANGUAGE
          ? 'Contactos por Idioma'
          : chart1.type === ChartTypes.WEBSITE
            ? 'Contactos por Website'
            : '';

      this.setState({
        chart1: {
          ...chart1,
          loading: false,
          title,
          data: dataChart1,
          total: dataChart1.length > 0 ? dataChart1.map(m => m.value).reduce((a, b) => a + b, 0) : 0
        },
      });
    } catch (e) {
      this.setState({ chart1: { ...chart1, loading: false } });
      console.error(e);
    }
  }

  loadChart2 = async () => {
    const { chart2 } = this.state;
    this.setState({ chart2: { ...chart2, loading: true } });

    try {
      const resultChart2 = await GetBookingForLineChart({ type: chart2.type });
      // console.warn('resultChart2', resultChart2);
      const dataChart2 = resultChart2?.success && resultChart2.data?.chart ? resultChart2.data.chart : [];
      const title = chart2.type === ChartTypes.YEAR
        ? 'Contactos por Ano'
        : chart2.type === ChartTypes.LANGUAGE
          ? 'Contactos por Idioma'
          : chart2.type === ChartTypes.WEBSITE
            ? 'Contactos por Website'
            : '';

      this.setState({
        chart2: {
          ...chart2,
          loading: false,
          title,
          data: dataChart2,
          total: dataChart2.length > 0 ? dataChart2.map(m => m.value).reduce((a, b) => a + b, 0) : 0,
          colors: resultChart2?.success && resultChart2.data?.colors ? resultChart2.data.colors : [],
          legend: resultChart2?.success && resultChart2.data?.legend ? resultChart2.data.legend : []
        },
      });
    } catch (e) {
      this.setState({ chart2: { ...chart2, loading: false } });
      console.error(e);
    }
  }

  changeFilterChart1 = (e) => {
    const { chart1 } = this.state;
    this.setState({ chart1: { ...chart1, type: e.target.value } },
      () => this.loadChart1());
  }

  changeFilterChart2 = (e) => {
    const { chart2 } = this.state;
    this.setState({ chart2: { ...chart2, type: e.target.value } },
      () => this.loadChart2());
  }

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { loadingCharts } = this.state;
    const { chart1, chart2 } = this.state;
    const { stats } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Schedule Requests</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <Row gutter={[12, 12]}>
            {stats.length > 0 && stats.map(m => (
              <Col span={24 / stats.length}>
                <Stats>
                  <div className="title">{m.title}</div>
                  <div className="content">
                    <Icon type={m.icon} />
                    <div className="text">
                      <StatsLabel>{m.label}</StatsLabel>
                      <StatsValue hasLabel={m.label !== '' ? true : false}>{m.value}</StatsValue>
                    </div>
                  </div>
                </Stats>
              </Col>
            ))}
          </Row>

          <Row gutter={[12, 12]} style={{ marginTop: 20 }}>
            <Col span={10}>
              <ChartContent>
                <RadioGroup
                  onChange={this.changeFilterChart1}
                  value={chart1.type}
                  style={{ display: 'block' }}
                >
                  <ChartFilter>Filtros:</ChartFilter>
                  <RowRadio>
                    {
                      ChartTypesOptions
                        .filter(f => f.chart === 1 || f.chart === 3)
                        .map((obj, index) => <Radio key={index} value={obj.value}>{obj.name}</Radio>)
                    }
                  </RowRadio>
                </RadioGroup>

                <BaseGraph
                  loading={loadingCharts || chart1.loading}
                  title={chart1.title}
                  data={chart1.data}
                  addedLabels={[
                    {
                      name: 'Total',
                      value: chart1.total,
                      color: PrimaryColour
                    }
                  ]}
                />
              </ChartContent>
            </Col>

            <Col span={14}>
              <ChartContent>
                <RadioGroup
                  onChange={this.changeFilterChart2}
                  value={chart2.type}
                  style={{ display: 'block' }}
                >
                  <ChartFilter>Filtros:</ChartFilter>
                  <RowRadio>
                    {
                      ChartTypesOptions
                        .filter(f => f.chart === 2 || f.chart === 3)
                        .map((obj, index) => <Radio key={index} value={obj.value}>{obj.name}</Radio>)
                    }
                  </RowRadio>
                </RadioGroup>

                <LineGraph
                  loading={loadingCharts || chart2.loading}
                  title={chart2.title}
                  data={chart2.data}
                  colors={chart2.colors}
                  type={chart2.type}
                  legend={chart2.legend}
                  addedLabels={[
                    {
                      name: 'Total',
                      value: chart2.total,
                      color: PrimaryColour
                    }
                  ]}
                />
              </ChartContent>
            </Col>
          </Row>

          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={"There are not schedule requests"}
            total={total}
            rowKey={"_id"}
            hasPagination={true}
            // onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default AppointmentsPage;
